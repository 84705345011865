import { Autocomplete, Box, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { JOB_TYPES } from "constants/constants";
import {
  CategorizedEstimate,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

interface CategoryProps {
  categorizedEstimate: CategorizedEstimate;
}
export const Category = ({ categorizedEstimate }: CategoryProps): JSX.Element => {
  const { deleteCategory, setCategoryName, existsOwnerFixedByCategoryId } = useCategorizedEstimatesContext();
  const categoryId = categorizedEstimate.categoryId;

  return (
    <Box className="mb-4 flex justify-between">
      <Box>
        <p className="font-bold">カテゴリー</p>
        <Autocomplete
          value={categorizedEstimate.categoryName || ""}
          size="small"
          freeSolo
          onChange={(_, newValue) => {
            setCategoryName(newValue || "", categoryId);
          }}
          options={JOB_TYPES}
          renderInput={(params) => (
            <TextField
              placeholder="クロス張替工事"
              onChange={(e) => setCategoryName(e.target.value, categoryId)}
              {...params}
            />
          )}
          className="w-72"
        />
      </Box>

      {!existsOwnerFixedByCategoryId(categoryId) && (
        <Box>
          <IconButton
            onClick={() => {
              deleteCategory(categoryId);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
