import type { TaxType, Status } from "../../@shared/types/entities/owner/Project";
import { type BizProjectStatus } from "../constants";

//---フロントでのみ利用---//
const statusMap = new Map<Status, BizProjectStatus | undefined>([
  ["", undefined],
  ["下書き中", undefined],
  ["オファー中", undefined],
  ["点検中", undefined],
  ["オファー可能", "提案中"],
  ["受付中", "受付中"],
  ["見積中", "見積中"],
  ["工事中", "工事中"],
  ["検収待ち", "検収待ち"],
  ["完了", "完了"],
  ["取下げ", "取下げ"],
  ["期限切れ", "期限切れ"],
]);

function getOwnerStatus(bizStatus: BizProjectStatus): Status {
  for (const [key, value] of statusMap.entries()) {
    if (value === bizStatus) {
      return key;
    }
  }
  throw new Error("Status not found");
}

export function getOwnerStatusesByBizStatuses(bizStatuses: BizProjectStatus[]): Status[] {
  let ownerProjectStatus: Status[] = [];

  bizStatuses.forEach((bizStatus) => {
    const ownerStatus = getOwnerStatus(bizStatus);
    if (ownerStatus) {
      ownerProjectStatus = [...ownerProjectStatus, ownerStatus];
    }
  });

  return ownerProjectStatus;
}

/**消費税表示 */
export function displayTaxType(bizTaxType: TaxType): string {
  if (bizTaxType === "roundDown") {
    return "消費税10%(切捨て)";
  }

  if (bizTaxType === "roundUp") {
    return "消費税10%(切上げ)";
  }

  return "消費税0%";
}

//---フロントでのみ利用---//

/**契約書関連 */
export const Contract = {
  /**契約書関連のディレクトリ */
  contractDirPathInStorage: (companyId: string, projectId: string) =>
    `biz/companies/${companyId}/projects/${projectId}/contract`,
  savePathInStorage: {
    /**発注書・発注請書(YYYYMMDD_HH:mm:ss).pdf */
    getOrderPath: (companyId: string, projectId: string, fileName: string): string =>
      `${Contract.contractDirPathInStorage(companyId, projectId)}/${fileName}`,
    /**見積書.xlsx */
    getEstimatePath: (companyId: string, projectId: string): string =>
      `${Contract.contractDirPathInStorage(companyId, projectId)}/見積書.xlsx`,
    /**見積書_利益・負担割合管理.xlsx */
    getProfitManagementEstimatePath: (companyId: string, projectId: string): string =>
      `${Contract.contractDirPathInStorage(companyId, projectId)}/見積書_利益・負担割合管理.xlsx`,
    /**請求書.xlsx */
    getInvoicePath: (companyId: string, projectId: string): string =>
      `${Contract.contractDirPathInStorage(companyId, projectId)}/請求書.xlsx`,
  },
  canDownload: {
    /**発注書 */
    order: (status: Status) => ["工事中", "検収待ち", "完了"].includes(status),
    /**見積書 */
    estimate: (status: Status) => ["見積中", "オファー可能", "工事中", "検収待ち", "完了"].includes(status),
    /**請求書 */
    invoice: (status: Status) => ["検収待ち", "完了"].includes(status),
  },
};

/**電子印鑑画像 */
export const DigitalStamp = {
  /**storageパス */
  getPath: (bizContractorId: string, fileName: string): string => {
    return `biz/bizContractors/${bizContractorId}/digitalStamp/${fileName}`;
  },
};

/**料金表(PDF/画像) */
export const PriceListProcess = {
  /**storageパス */
  getPath: (bizContractorId: string, fileName: string): string => {
    return `biz/bizContractors/${bizContractorId}/priceList/${fileName}`;
  },
};
