import Big from "big.js";

import { TaxType, BizEstimateValidityPeriod } from "../../@shared/types/entities/owner/Project";
import { BizEstimate } from "../../@shared/types/entities/owner/BizEstimate";
import { TAX_RATE } from "../constants";

/**見積有効期限 */
export function getValidityPeriod(bizEstimateValidityPeriod?: BizEstimateValidityPeriod): string | Date {
  if (bizEstimateValidityPeriod?.calendar) {
    return bizEstimateValidityPeriod.calendar.toDate().toLocaleDateString();
  }

  if (bizEstimateValidityPeriod?.days) {
    return `見積後${bizEstimateValidityPeriod.days.toString()}日`;
  }

  return "";
}

/**小計 */
export function getSubtotal(bizEstimates: BizEstimate[]): number {
  const subtotal = bizEstimates.reduce((acc, cur) => {
    return acc + cur.amount;
  }, 0);

  return subtotal;
}

/**消費税 */
export function getTax(bizEstimates: BizEstimate[], taxType: TaxType): number {
  if (taxType === "zero") {
    return 0;
  }

  const subtotal = getSubtotal(bizEstimates);
  const tax = Big(subtotal).times(TAX_RATE);

  const roundingMode = taxType === "roundUp" ? Big.roundUp : Big.roundDown;

  return tax.round(0, roundingMode).toNumber();
}

/**合計 */
export function getTotal(bizEstimates: BizEstimate[], taxType: TaxType): number {
  return getSubtotal(bizEstimates) + getTax(bizEstimates, taxType);
}

/**賃貸人 負担合計 */
export function getLandlordTotal(bizEstimates: BizEstimate[], taxType: TaxType): number {
  // 小計
  const landlordSubtotal = bizEstimates.reduce((acc, cur) => {
    return acc + getLandlordAmount(cur);
  }, 0);

  // 消費税
  if (taxType === "zero") {
    return landlordSubtotal;
  }

  // 端数の消費税が出た場合に賃貸人側が多く負担するように調整する(基本的に端数が出た場合に賃貸人側が負担するため)
  const landlordTax = Big(landlordSubtotal).times(TAX_RATE).round(0, Big.roundUp).toNumber();
  const tax = getTax(bizEstimates, taxType);

  // 賃貸人の小計 = 全体の小計の場合、賃貸人側の消費税を強制的に切り上げにする為、全体の消費税を上回る可能性がある。
  // その場合、全体の消費税を返す
  if (landlordTax > tax) {
    return landlordSubtotal + tax;
  }

  return landlordSubtotal + landlordTax;
}

/**賃貸人 負担金額(見積1行) */
export function getLandlordAmount(bizEstimate: BizEstimate): number {
  const landlordShareRatio = Big(bizEstimate.shareRate.landlord).div(100);

  // 端数が出た場合に賃貸人側が多く負担するように調整する
  // 通常の見積行の場合 → 切上げ、お値引き行の場合 → 切捨て
  const roundingMode = bizEstimate.categoryNo !== 9999 ? Big.roundUp : Big.roundDown;
  return Big(bizEstimate.amount).times(landlordShareRatio).round(0, roundingMode).toNumber();
}

/**賃借人 負担合計 */
export function getTenantTotal(bizEstimates: BizEstimate[], taxType: TaxType): number {
  return getTotal(bizEstimates, taxType) - getLandlordTotal(bizEstimates, taxType);
}

/**賃借人 負担金額(見積1行) */
export function getTenantAmount(bizEstimate: BizEstimate): number {
  return bizEstimate.amount - getLandlordAmount(bizEstimate);
}

/**紹介手数料合計 */
export function getReferralFeeTotal(bizEstimates: BizEstimate[]): number {
  const referralSubtotal = bizEstimates.reduce((acc, cur) => {
    const referralFeeAmount = getReferralFee(cur);
    return acc + referralFeeAmount;
  }, 0);

  return referralSubtotal;
}

/**紹介手数料 */
export function getReferralFee(bizEstimate: BizEstimate): number {
  const referralFeeRatio = Big(bizEstimate.referralFeeRate).div(100);
  return Big(bizEstimate.amount).times(referralFeeRatio).round(0, Big.roundDown).toNumber();
}
