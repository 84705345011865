import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { IsProfitManagementAddOnEnabled } from "@shared/types/functionParams";

interface ResponseParams extends IsProfitManagementAddOnEnabled.ResponseParams {}

export async function isProfitManagementAddOnEnabled(companyId: string): Promise<ResponseParams> {
  const requestParams: IsProfitManagementAddOnEnabled.RequestParams = {
    companyId,
  };
  const func = httpsCallable(bizFunctions, "onCallIsProfitManagementAddOnEnabled");
  const result = await func(requestParams);

  return result.data as ResponseParams;
}
