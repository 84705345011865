import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RegisterForm } from "../components/registerForm";

export const RegisterPage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const expiredAt = new URLSearchParams(location.search).get("expiredAt") || "";

  useEffect(() => {
    if (isExpired(expiredAt)) {
      navigate("/invite-expired");
    }
  }, [expiredAt, navigate]);

  return <RegisterForm />;
};

function isExpired(expiredAt: string): boolean {
  if (expiredAt === "") {
    return false;
  }

  const date = new Date(expiredAt);

  // 期限日のフォーマット不正は期限切れと見なす
  if (isNaN(date.getTime())) {
    return true;
  }

  const now = new Date();
  return date < now;
}
