/**都道府県 */
export namespace Prefs {
  export const HOKKAIDO = ["北海道"];
  export const TOHOKU = [
    "青森県",
    "岩手県",
    "秋田県",
    "宮城県",
    "山形県",
    "福島県",
  ];
  export const KANTO = [
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
  ];
  export const CHUBU = [
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
  ];
  export const KINKI = [
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
  ];
  export const CHUGOKU = ["鳥取県", "島根県", "岡山県", "広島県", "山口県"];
  export const SHIKOKU = ["徳島県", "香川県", "愛媛県", "高知県"];
  export const KYUSHUOKINAWA = [
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];
  export const PREFECTURES = [
    ...HOKKAIDO,
    ...TOHOKU,
    ...KANTO,
    ...CHUBU,
    ...KINKI,
    ...CHUGOKU,
    ...SHIKOKU,
    ...KYUSHUOKINAWA,
  ];
}

export const UNITS = ["m", "本", "㎡", "式", "箇所", "枚", "個"];

export type JobType = (typeof JOB_TYPES)[number];
export const JOB_TYPES = [
  "内装工事全般",
  "大工",
  "清掃",
  "クロス・床",
  "解体",
  "消毒・防虫",
  "電気設備",
  "シート・フィルム",
  "消防設備",
  "ガラス",
  "鍵",
  "塗装",
  "リペア",
  "屋根・防水",
  "たたみ・襖・障子",
  "タイル・ブロック",
  "不用品回収・収集運搬",
  "水道設備",
  "サッシ",
  "ガス設備",
] as const;
