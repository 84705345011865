import { type AlertColor } from "@mui/material/Alert";

import { GlobalSnackbar } from "components/Snackbar/Snackbar";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export type SnackbarContextType = {
  message: string;
  severity: AlertColor;
  showSnackbar: (message: string, severity: AlertColor) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  message: "",
  severity: "error",
  showSnackbar: (_message: string, _severity: AlertColor) => {},
});

export const useSnackbar = (): SnackbarContextType => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const context: SnackbarContextType = useContext(SnackbarContext);
  const [message, setMessage] = useState<string>(context.message);
  const [severity, setSeverity] = useState<AlertColor>(context.severity);

  const newContext: SnackbarContextType = useMemo(
    () => ({
      message,
      severity,
      showSnackbar: (message: string, severity: AlertColor) => {
        setMessage(message);
        setSeverity(severity);
      },
    }),
    [message, severity, setMessage, setSeverity]
  );

  const handleClose = useCallback(() => {
    setMessage("");
  }, [setMessage]);

  return (
    <SnackbarContext.Provider value={newContext}>
      {children}
      <GlobalSnackbar
        open={newContext.message !== ""}
        message={newContext.message}
        severity={newContext.severity}
        onClose={handleClose}
      />
    </SnackbarContext.Provider>
  );
};
