import { Company } from "@shared/types/entities/owner/Company";
import { doc, collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { ownerDB } from "lib/firebase";
import { toEntityFromDoc } from "utils/firebaseUtil";

export const getCompanyById = async (companyId: string): Promise<Company> => {
  const docRef = doc(ownerDB, `companies/${companyId}`);
  const docSnapshot = await getDoc(docRef);
  return toEntityFromDoc<Company>(docSnapshot);
};

/**工事業者と紐づいている施主を取得 */
export async function fetchCompaniesByBizContractorId(bizContractorId: string): Promise<Company[]> {
  const collectionQuery = collection(ownerDB, `companies`);

  const q = query(collectionQuery, where("bizContractorIds", "array-contains", bizContractorId));
  const querySnapshot = await getDocs(q);

  const companies = querySnapshot.docs.map((queryDocSnapshot) => {
    return toEntityFromDoc<Company>(queryDocSnapshot);
  });

  return companies;
}
