import { useState } from "react";
import { Box, Button, IconButton, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  CategorizedEstimate,
  useCategorizedEstimatesContext,
} from "../../context/categorizedEstimatesContext";
import { EstimateRows } from "./EstimateRows";
import { ChangeCategoryNameDialog } from "./dialogs/ChangeCategoryNameDialog";

type CategoryHeaderProps = {
  categoryId: string;
  categoryName: string;
};
const CategoryHeader = ({
  categoryId,
  categoryName,
}: CategoryHeaderProps): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { deleteCategory, existsOwnerFixedByCategoryId } =
    useCategorizedEstimatesContext();

  return (
    <>
      <Box className="flex items-center justify-between bg-winterGreen">
        <Box className="flex items-center">
          <p className="p-2 font-bold text-remodela-green">{categoryName}</p>
          <IconButton
            className="p-0"
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>

        {!existsOwnerFixedByCategoryId(categoryId) && (
          <IconButton
            onClick={() => {
              deleteCategory(categoryId);
            }}
          >
            <DeleteIcon className="text-remodela-error" />
          </IconButton>
        )}
      </Box>

      <ChangeCategoryNameDialog
        open={isDialogOpen}
        handleDialogClose={() => {
          setIsDialogOpen(false);
        }}
        categoryId={categoryId}
        categoryName={categoryName}
      />
    </>
  );
};

type AddNewRowProps = {
  categoryId: string;
};
const AddNewRow = ({ categoryId }: AddNewRowProps): JSX.Element => {
  const { existsOwnerFixedByCategoryId, addRow } =
    useCategorizedEstimatesContext();

  // 一つでも施主fixしていたら表示させない
  if (existsOwnerFixedByCategoryId(categoryId)) {
    return <></>;
  }

  return (
    <Box className="flex justify-center py-4">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => addRow(categoryId)}
      >
        <Button variant="outlined" className="text-sm">
          <AddIcon />
          見積を追加
        </Button>
      </div>
    </Box>
  );
};

type EstimatesByCategoryProps = {
  categorizedEstimate: CategorizedEstimate;
};
export const EstimatesByCategory = ({
  categorizedEstimate,
}: EstimatesByCategoryProps) => {
  const categoryId = categorizedEstimate.categoryId;
  const categoryName = categorizedEstimate.categoryName;

  return (
    <>
      <Paper elevation={12} className="mb-4 border-b-remodela-green border-b-4">
        <CategoryHeader categoryId={categoryId} categoryName={categoryName} />

        <Box className="p-2">
          <EstimateRows categorizedEstimate={categorizedEstimate} />
          <AddNewRow categoryId={categoryId} />
        </Box>
      </Paper>
    </>
  );
};
