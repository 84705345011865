import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { type AlertColor } from "@mui/material/Alert";

type Props = {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose?: () => void;
};

export const GlobalSnackbar = ({
  open,
  message,
  severity = "info",
  onClose,
}: Props) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <MuiAlert severity={severity}>
        {message.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </MuiAlert>
    </Snackbar>
  );
};
