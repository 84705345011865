import { useEffect, useState } from "react";
import { BizEstimate } from "@shared/types/entities/owner/BizEstimate";
import { getBizEstimateByCompanyIdProjectId } from "repositories/owner/BizEstimate";

export function useEstimates(companyId: string, projectId: string): BizEstimate[] {
  const [bizEstimates, setBizEstimates] = useState<BizEstimate[]>([]);

  useEffect(() => {
    const fetchEstimates = async (): Promise<void> => {
      const estimates = await getBizEstimateByCompanyIdProjectId(companyId, projectId);
      setBizEstimates(estimates);
    };

    fetchEstimates();
  }, [companyId, projectId]);

  return bizEstimates;
}
