import { useEffect, useState } from "react";
import { listAll, ref } from "firebase/storage";
import { Box, Button, Dialog } from "@mui/material";
import { Contract } from "@shared/utils";
import { useSnackbar } from "context/snackbarContext";
import {
  downloadFile,
  fetchEstimateDownloadURL,
  fetchProfitManagementEstimateDownloadURL,
} from "../../useCases/storage/contractFile";
import { Project } from "repositories/owner/Project";
import { isProfitManagementAddOnEnabled } from "api/isProfitManagementAddOnEnabled";
import { ownerStorage } from "lib/firebase";

interface DownloadEstimateExcelProps {
  project: Project;
}

/**見積書エクセルダウンロード */
export const DownloadEstimateExcel = ({ project }: DownloadEstimateExcelProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isAddOnEnabled, setIsAddOnEnabled] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { showSnackbar } = useSnackbar();
  const canDownload = Contract.canDownload.estimate(project.status);

  useEffect(() => {
    const fetchProfitManagementAddOnEnabled = async (): Promise<void> => {
      const result = await isProfitManagementAddOnEnabled(project.companyId);
      setIsAddOnEnabled(result.enabled);
      setLoading(false);
    };

    fetchProfitManagementAddOnEnabled();
  }, [project]);

  const handleDownloadButtonClick = async (): Promise<void> => {
    if (isAddOnEnabled) {
      setIsDialogOpen(true);
      return;
    }

    const estimateURL = await fetchEstimateDownloadURL(project.companyId, project.id!);
    if (!estimateURL) {
      showSnackbar("見積書がダウンロードできませんでした。", "error");
      return;
    }

    downloadFile(estimateURL, `${project.projectName}_見積書.xlsx`);
  };

  return (
    <>
      <Button
        variant="contained"
        className={`font-bold text-xs rounded-lg ${canDownload && "bg-remodela-green"}`}
        disabled={!canDownload || loading}
        onClick={handleDownloadButtonClick}
      >
        ダウンロード
      </Button>

      <EstimateExcelDownloadDialog open={isDialogOpen} project={project} handleClose={() => setIsDialogOpen(false)} />
    </>
  );
};

interface EstimateExcelDownloadDialogProps {
  open: boolean;
  project: Project;
  handleClose: () => void;
}
/**見積書の選択ダイアログ */
const EstimateExcelDownloadDialog = ({ open, project, handleClose }: EstimateExcelDownloadDialogProps): JSX.Element => {
  const [hasProfitManagementEstimateExcel, setHasProfitManagementEstimateExcel] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const fileExists = async (): Promise<void> => {
      const storageRef = ref(ownerStorage, Contract.contractDirPathInStorage(project.companyId, project.id!));
      const listResult = await listAll(storageRef);

      const fileExists = listResult.items.some((item) => item.name === "見積書_利益・負担割合管理.xlsx");
      setHasProfitManagementEstimateExcel(fileExists);
    };

    fileExists();
  }, [project]);

  const handleDownloadEstimate = async (): Promise<void> => {
    const estimateURL = await fetchEstimateDownloadURL(project.companyId, project.id!);
    if (!estimateURL) {
      showSnackbar("見積書がダウンロードできませんでした。", "error");
      return;
    }

    downloadFile(estimateURL, `${project.projectName}_見積書.xlsx`);
  };

  const handleDownloadProfitManagementEstimate = async (): Promise<void> => {
    const profitManagementEstimateURL = await fetchProfitManagementEstimateDownloadURL(project.companyId, project.id!);
    if (!profitManagementEstimateURL) {
      showSnackbar("見積書(利益・負担割合管理)がダウンロードできませんでした。", "error");
      return;
    }

    downloadFile(profitManagementEstimateURL, `${project.projectName}_見積書(利益・負担割合管理).xlsx`);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <Box className="flex flex-col items-center p-3 space-y-4">
        <Button
          variant="outlined"
          className="text-remodela-green border-remodela-green font-bold rounded-lg min-w-[12rem]"
          onClick={handleDownloadEstimate}
        >
          見積書
        </Button>
        {hasProfitManagementEstimateExcel && (
          <Button
            variant="outlined"
            className="text-remodela-green border-remodela-green font-bold rounded-lg min-w-[12rem]"
            onClick={handleDownloadProfitManagementEstimate}
          >
            見積書(利益・負担割合管理)
          </Button>
        )}
      </Box>
    </Dialog>
  );
};
