import { Box } from "@mui/material";
import { useStore } from "@nanostores/react";
import { FirebaseError } from "firebase/app";

import { CreateAPIOwner } from "@shared/types/functionParams";
import { MainLayout } from "components/Layout/MainLayout";
import { useSnackbar } from "context/snackbarContext";
import { useBackdrop } from "context/backdropContext";
import { BizConstractorStore, BizUserStore } from "store/nanostores/contractorInfo";
import { createSpotWorkerApiOwnerId } from "../api/createSpotWorkerApiOwnerId";
import { PointSection } from "../components/PointSection";
import { TextSection } from "../components/TextSection";
import backgroundImage from "../../../assets/images/background.jpg";

export const SpotWorker = (): JSX.Element => {
  const bizUser = useStore(BizUserStore.bizUser);
  const { bizContractorId, userId } = useStore(BizConstractorStore.IDMap);
  const { showSnackbar } = useSnackbar();
  const { setBackdrop } = useBackdrop();

  const handleOpenNewTab = async () => {
    setBackdrop(true);

    if (bizUser.workerMatchingApiOwnerId) {
      openSpotWorkerInNewTab(bizUser.workerMatchingApiOwnerId);
      setBackdrop(false);
      return;
    }

    try {
      const req: CreateAPIOwner.RequestParams = {
        bizContractorId,
        bizUserId: userId,
      };
      const res = await createSpotWorkerApiOwnerId(req);

      openSpotWorkerInNewTab(res.workerMatchingApiOwnerId);
    } catch (e) {
      if (e instanceof FirebaseError) {
        showSnackbar(`${e.code}: ${e.message}`, "error");
      } else {
        showSnackbar("登録に失敗しました", "error");
      }
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <MainLayout title="職人マッチング">
      <Box className="relative w-screen h-[calc(100vh-64px)] overflow-hidden">
        <Box
          component="img"
          src={backgroundImage}
          alt="背景画像"
          className="w-full h-full object-cover absolute top-0 left-0"
        />
        <Box className="absolute top-0 left-0 w-full h-full bg-[#10692A] opacity-30 pointer-events-none" />
        <Box className="absolute top-0 sm:top-5 md:top-10 w-full flex flex-col items-center justify-center p-5">
          <TextSection handleOpenNewTab={handleOpenNewTab} />
          <PointSection />
        </Box>
      </Box>
    </MainLayout>
  );
};

function openSpotWorkerInNewTab(apiOwnerId: string): void {
  const url = process.env.REACT_APP_SPOT_WORKER_BASE_URL + apiOwnerId;
  window.open(url, "_blank");
}
