import { type Project as _Project } from "@shared/types/entities/owner/Project";
import { collectionGroup, getDocs, query, where, getDoc, doc } from "firebase/firestore";
import { ownerDB } from "lib/firebase";
import { FireTimestamp, toEntityFromDoc } from "utils/firebaseUtil";

export type Project = _Project<FireTimestamp>;

export async function fetchProjectsByBizContractorId(bizContractorId: string): Promise<Project[]> {
  const collectionGroupQuery = collectionGroup(ownerDB, "projects");
  const q = query(collectionGroupQuery, where("bizContractorId", "==", bizContractorId));
  const querySnapshot = await getDocs(q);
  const projects = querySnapshot.docs.map((queryDocSnapshot) => {
    return toEntityFromDoc<Project>(queryDocSnapshot);
  });

  return projects;
}

export async function getProjectByCompanyIdProjectId(companyId: string, projectId: string): Promise<Project> {
  const docRef = doc(ownerDB, `companies/${companyId}/projects/${projectId}`);
  const docSnapshot = await getDoc(docRef);

  return toEntityFromDoc<Project>(docSnapshot);
}
