import { Box } from "@mui/material";
import { DnDTable } from "./DnDTable";
import { CategorizedEstimate } from "features/estimates/context/categorizedEstimatesContext";

interface EstimateBreakdownProps {
  categorizedEstimate: CategorizedEstimate;
}
export const EstimateBreakdown = ({ categorizedEstimate }: EstimateBreakdownProps): JSX.Element => {
  return (
    <Box className="w-full overflow-auto">
      <p className="font-bold">内訳</p>
      <DnDTable categorizedEstimate={categorizedEstimate} />
    </Box>
  );
};
