import { useParams } from "react-router-dom";
import { CommentThread } from "components/CommentThread/CommentThread";
import { SubHeader } from "./SubHeader";
import { Header } from "./Header";
import { MainContent } from "./MainContent";

export const EstimateEditorPC = (): JSX.Element => {
  const { companyId, projectId } = useParams();

  return (
    <>
      <Header />
      <SubHeader />
      <MainContent />
      <CommentThread companyId={companyId!} projectId={projectId!} />
    </>
  );
};
