import { useState } from "react";
import { Autocomplete, Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { UNITS } from "constants/constants";
import { GentyoFileUploadDialog } from "./GentyoFileUploadDialog";
import { OwnerCommentTooltip } from "../OwnerCommentTooltip/OwnerCommentTooltip";
import { BizEstimateRow, CategorizedEstimate, useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";
import { CustomTableHeaderRow, tableColumn } from "../CustomTableHeaderRow/CustomTableHeaderRow";
import { toHalfWidthNumbersOnly } from "utils";

interface DnDTableProps {
  categorizedEstimate: CategorizedEstimate;
}
export const DnDTable = ({ categorizedEstimate }: DnDTableProps) => {
  const { changeRow } = useCategorizedEstimatesContext();
  const categoryId = categorizedEstimate.categoryId;

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (active.id === over.id) {
      return;
    }

    const bizEstimateRows = categorizedEstimate.bizEstimateRows;

    const oldIndex = bizEstimateRows.findIndex((row) => row.id === active.id);
    const newIndex = bizEstimateRows.findIndex((row) => row.id === over.id);

    const changedBizEstimateRows = arrayMove(bizEstimateRows, oldIndex, newIndex);

    changeRow(categoryId, changedBizEstimateRows);
  };

  return (
    <>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={categorizedEstimate.bizEstimateRows} strategy={verticalListSortingStrategy}>
          <TableContainer component={Paper} className="min-w-[1400px]">
            <Table>
              <TableHead>
                <CustomTableHeaderRow />
              </TableHead>
              <TableBody>
                {categorizedEstimate.bizEstimateRows.map((bizEstimateRow) => (
                  <SortableTableRow key={bizEstimateRow.id} row={bizEstimateRow} categoryId={categoryId} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </SortableContext>
      </DndContext>
    </>
  );
};

/**内訳のデータ行*/
interface SortableTableRowProps {
  row: BizEstimateRow;
  categoryId: string;
}
const SortableTableRow = ({ row, categoryId }: SortableTableRowProps): JSX.Element => {
  const [gentyoFileDialogOpen, setGentyoFileDialogOpen] = useState<boolean>(false);

  const {
    isAddOnEnabled,
    changeLandlordShareRate,
    getLandlordAmount,
    getTenantAmount,
    changeReferralFeeRate,
    getReferralFeeAmount,

    deleteRow,
    changeItemName,
    changeQuantity,
    changeUnit,
    changeUnitPrice,
    changeNote,
    getFirstGentyoImageURL,
    getGentyoImageCount,
  } = useCategorizedEstimatesContext();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.id });

  const handleGentyoFileDialogOpen = (): void => setGentyoFileDialogOpen(true);
  const handleGentyoFileDialogClose = (): void => setGentyoFileDialogOpen(false);

  const firstGentyoImageURL = getFirstGentyoImageURL(row);
  const gentyoImageCount = getGentyoImageCount(row);

  const getTableRowClassName = (): string => {
    if (row.ownerComments && row.ownerComments.length > 0) {
      return "bg-remodela-warmOrange bg-opacity-10 border-b-2 border-b-remodela-warmOrange";
    }

    return "";
  };

  return (
    <TableRow
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      key={row.id}
      ref={setNodeRef}
      className={getTableRowClassName()}
    >
      {/* drag handle */}
      <TableCell
        padding="none"
        {...attributes}
        {...listeners}
        className={`text-center text-sm h-16 min-w-[${tableColumn.dragHandler.width}px] w-[${tableColumn.dragHandler.width}px]`}
      >
        {!row.ownerFixed && <DragHandleIcon />}
      </TableCell>

      {/* 現調写真 */}
      <TableCell
        padding="none"
        className={`relative text-center text-sm h-16 min-w-[${tableColumn.gentyoImage.width}px] w-[${tableColumn.gentyoImage.width}px]`}
      >
        {firstGentyoImageURL ? (
          <>
            <img
              src={firstGentyoImageURL}
              alt="noImage"
              className="h-full w-full object-contain cursor-pointer"
              onClick={handleGentyoFileDialogOpen}
            />
            {gentyoImageCount >= 2 && (
              <p className="absolute bottom-[5px] right-[5px] bg-pink text-white font-bold px-1 rounded">{`+${gentyoImageCount - 1}`}</p>
            )}
          </>
        ) : (
          <Box className="cursor-pointer" onClick={handleGentyoFileDialogOpen}>
            <UploadFileIcon className="bg-lightGray rounded-md" />
          </Box>
        )}

        {gentyoFileDialogOpen && (
          <GentyoFileUploadDialog
            open={gentyoFileDialogOpen}
            bizEstimateRow={row}
            categoryId={categoryId}
            handleDialogClose={handleGentyoFileDialogClose}
          />
        )}
      </TableCell>

      {/* 項目 */}
      <TableCell
        padding="none"
        className={`text-center text-sm h-16 px-1 min-w-[${tableColumn.itemName.width}px] w-[${tableColumn.itemName.width}px]`}
      >
        {!row.ownerFixed ? (
          <TextField
            placeholder="洋室 / 壁クロス"
            value={row.itemName || ""}
            onChange={(e) => changeItemName(categoryId, row.rowNo, e.target.value)}
            variant="outlined"
            size="small"
            className="w-full"
            InputProps={{
              sx: {
                backgroundColor: "white",
                fontSize: "12px",
              },
            }}
          />
        ) : (
          <p>{row.itemName}</p>
        )}
      </TableCell>

      {/* 数量 */}
      <TableCell padding="none" className={`text-center px-1 h-16 min-w-[${tableColumn.quantity.width}px] w-[${tableColumn.quantity.width}px]`}>
        {!row.ownerFixed ? (
          <TextField
            placeholder="26.1"
            defaultValue={row.quantity || ""}
            onChange={(e) => {
              const value = toHalfWidthNumbersOnly(e.target.value);
              e.target.value = value;
              changeQuantity(categoryId, row.rowNo, value);
            }}
            variant="outlined"
            size="small"
            InputProps={{
              sx: {
                "& .MuiInputBase-input": {
                  textAlign: "right",
                },
                backgroundColor: "white",
                fontSize: "12px",
              },
            }}
          />
        ) : (
          <Box className="text-sm">{row.quantity}</Box>
        )}
      </TableCell>

      {/* 単位 */}
      <TableCell padding="none" className={`text-center px-1 h-16 min-w-[${tableColumn.unit.width}px] w-[${tableColumn.unit.width}px]`}>
        {!row.ownerFixed ? (
          <Autocomplete
            value={row.unit || ""}
            size="small"
            freeSolo
            onChange={(_, newValue) => {
              changeUnit(categoryId, row.rowNo, newValue || "");
            }}
            sx={{
              backgroundColor: "white",
            }}
            options={UNITS}
            disableClearable
            renderInput={(params) => (
              <TextField
                placeholder="m"
                onChange={(e) => changeUnit(categoryId, row.rowNo, e.target.value)}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    fontSize: "12px",
                  },
                }}
              />
            )}
          />
        ) : (
          <Box className="text-sm">{row.unit}</Box>
        )}
      </TableCell>

      {/* 単価 */}
      <TableCell padding="none" className={`text-center h-16 px-1 min-w-[${tableColumn.unitPrice.width}px] w-[${tableColumn.unitPrice.width}px]`}>
        {!row.ownerFixed ? (
          <TextField
            placeholder="1000"
            defaultValue={row.unitPrice || ""}
            onChange={(e) => {
              const value = toHalfWidthNumbersOnly(e.target.value);
              e.target.value = value;
              changeUnitPrice(categoryId, row.rowNo, value);
            }}
            variant="outlined"
            size="small"
            InputProps={{
              sx: {
                "& .MuiInputBase-input": {
                  textAlign: "right",
                },
                backgroundColor: "white",
                fontSize: "12px",
              },
            }}
          />
        ) : (
          <Box className="text-sm">{`¥${row.unitPrice?.toLocaleString()}`}</Box>
        )}
      </TableCell>

      {/* 金額 */}
      <TableCell padding="none" className={`text-center h-16 px-1 min-w-[${tableColumn.amount.width}px] w-[${tableColumn.amount.width}px]`}>
        <Box className="text-sm break-all">{`¥${row.amount?.toLocaleString() || "0"} `}</Box>
      </TableCell>

      {/* 賃貸人負担 */}
      {isAddOnEnabled && (
        <TableCell
          padding="none"
          className={`text-center h-16 px-1 min-w-[${tableColumn.landlordShareRate.width}px] w-[${tableColumn.landlordShareRate.width}px]`}
        >
          <Box className="flex items-center justify-center">
            <TextField
              defaultValue={row.shareRate.landlord}
              variant="outlined"
              onChange={(e) => {
                const value = toHalfWidthNumbersOnly(e.target.value);
                e.target.value = value;
                changeLandlordShareRate(categoryId, row.rowNo, value);
              }}
              size="small"
              className="w-[40px] h-7"
              InputProps={{
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "right",
                  },
                  backgroundColor: "white",
                  fontSize: "12px",
                },
              }}
            />
            <Box>%</Box>
          </Box>
          <Box className="text-sm break-all">{`(¥${getLandlordAmount(categoryId, row.rowNo).toLocaleString()})`}</Box>
        </TableCell>
      )}

      {/* 賃借人負担 */}
      {isAddOnEnabled && (
        <TableCell
          padding="none"
          className={`text-center h-16 px-1 min-w-[${tableColumn.tenantShareRate.width}px] w-[${tableColumn.tenantShareRate.width}px]`}
        >
          <Box className="text-sm break-all h-7 flex items-center justify-center">{`${row.shareRate.tenant}%`}</Box>
          <Box className="text-sm break-all">{`(¥${getTenantAmount(categoryId, row.rowNo).toLocaleString()})`}</Box>
        </TableCell>
      )}

      {/* 紹介手数料 */}
      {isAddOnEnabled && (
        <TableCell
          padding="none"
          className={`text-center h-16 px-1 min-w-[${tableColumn.referralFeeRate.width}px] w-[${tableColumn.referralFeeRate.width}px]`}
        >
          <Box className="flex items-center justify-center">
            <TextField
              defaultValue={row.referralFeeRate}
              variant="outlined"
              onChange={(e) => {
                const value = toHalfWidthNumbersOnly(e.target.value);
                e.target.value = value;
                changeReferralFeeRate(categoryId, row.rowNo, value);
              }}
              size="small"
              className="w-[40px]"
              InputProps={{
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "5px",
                    textAlign: "right",
                  },
                  backgroundColor: "white",
                  fontSize: "12px",
                },
              }}
            />
            <Box>%</Box>
          </Box>
          <Box className="text-sm break-all">{`(¥${getReferralFeeAmount(categoryId, row.rowNo).toLocaleString()})`}</Box>
        </TableCell>
      )}

      {/* 備考 */}
      <TableCell padding="none" className={`text-center h-16 p-1 min-w-[${tableColumn.note.width}px] w-[${tableColumn.note.width}px]`}>
        <TextField
          value={row.note || ""}
          fullWidth
          multiline
          size="small"
          onChange={(e) => changeNote(categoryId, row.rowNo, e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: "white",
              fontSize: "12px",
            },
          }}
        />
      </TableCell>

      {/* 施主コメント */}
      <TableCell padding="none" className={`text-center text-sm h-16 px-1 min-w-[${tableColumn.comment.width}px] w-[${tableColumn.comment.width}px]`}>
        {row.ownerComments && row.ownerComments.length > 0 && <OwnerCommentTooltip ownerComments={row.ownerComments} />}
      </TableCell>

      {/* 削除ボタン */}
      <TableCell padding="none" className={`text-center text-sm h-16 px-1 min-w-[${tableColumn.delete.width}px] w-[${tableColumn.delete.width}px]`}>
        {!row.ownerFixed && (
          <IconButton size="small" className="text-remodela-error" onClick={() => deleteRow(categoryId, row.rowNo)}>
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};
