import { memo } from "react";
import { Backdrop, BackdropProps, Box, Dialog, DialogTitle } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

export const ProjectRejectedConfirmationDialog = (): JSX.Element => {
  return (
    <Dialog
      open
      slots={{
        backdrop: MemoizedBackdrop,
      }}
    >
      <DialogTitle className="flex items-center">
        <WarningIcon className="text-5xl text-remodela-error" />
        <Box className="mx-4">この案件は見積作成をお断り済みです。</Box>
      </DialogTitle>
    </Dialog>
  );
};

const MemoizedBackdrop = memo((props: BackdropProps) => (
  <Backdrop {...props} className="fixed inset-0 backdrop-blur-md bg-black/30" />
));
