import { Box, Card } from "@mui/material";
import { CategorizedEstimate } from "features/estimates/context/categorizedEstimatesContext";
import { Category } from "./CategoryCard/Category";
import { EstimateBreakdown } from "./CategoryCard/EstimateBreakdown";
import { AddNewRow } from "./CategoryCard/AddNewRow";

interface CategoryCardProps {
  categorizedEstimate: CategorizedEstimate;
}
export const CategoryCard = ({ categorizedEstimate }: CategoryCardProps) => {
  // TODO: この網掛けの方法を変えたい。
  // 検証ツールで修正できてしまうので
  if (categorizedEstimate.fixed) {
    return (
      <Card elevation={3} className="p-3 mb-4 pointer-events-none relative">
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "black",
            opacity: 0.4,
            zIndex: 1,
          }}
        />
        <Category categorizedEstimate={categorizedEstimate} />
        <EstimateBreakdown categorizedEstimate={categorizedEstimate} />
        <AddNewRow categoryId={categorizedEstimate.categoryId} />
      </Card>
    );
  }

  return (
    <Card elevation={3} className="p-3 mb-4">
      <Category categorizedEstimate={categorizedEstimate} />
      <EstimateBreakdown categorizedEstimate={categorizedEstimate} />
      <AddNewRow categoryId={categorizedEstimate.categoryId} />
    </Card>
  );
};
