import { Box, Button, Typography } from "@mui/material";

interface TextSectionProps {
  handleOpenNewTab: () => void;
}

export const TextSection = ({ handleOpenNewTab }: TextSectionProps): JSX.Element => {
  return (
    <Box className="text-white font-bold min-w-[200px] p-4 sm:p-5 md:p-8 flex flex-col items-center justify-center">
      <Typography variant="h3" className="mb-2 text-center font-bold text-3xl sm:text-4xl md:text-5xl">
        全国の内装工事職人が手軽に見つかる！
      </Typography>
      <Typography variant="h3" className="mb-2 text-center font-bold text-3xl sm:text-4xl md:text-5xl">
        <span className="text-red-500 font-bold">単発工事</span>の手配をもっと簡単に
      </Typography>
      <Box className="mt-4 md:mt-8 mb-4 md:mb-8 w-full flex justify-center items-center">
        <Button
          onClick={handleOpenNewTab}
          variant="contained"
          className="bg-remodela-green font-bold rounded-lg text-3xl md:text-4xl px-8 py-4 w-11/12 md:w-3/5 max-w-[500px]"
        >
          職人を探す
        </Button>
      </Box>
    </Box>
  );
};
