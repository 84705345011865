import { useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDropzone } from "react-dropzone";
import {
  BizEstimateRow,
  PendingGentyoImage,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

type FileDropZoneProps = { onDrop: (acceptedFiles: File[]) => void };
const FileDropZone = ({ onDrop }: FileDropZoneProps): JSX.Element => {
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, open } = useDropzone({
    onDrop: handleDrop,
    accept: { "image/*": [] },
    noClick: true,
  });

  return (
    <>
      <Box
        {...getRootProps()}
        className="flex flex-col items-center w-[40rem] border-2 border-dashed border-mediumGray"
      >
        <AttachFileIcon className="text-mediumGray text-5xl my-7" />

        <Box className="text-sm">
          <p>
            ファイルをドラッグ＆ドロップするか、<b>ファイルを選択</b>から
          </p>
          <p>画像を貼り付けてください（複数アップロードもできます。）</p>
        </Box>

        <Button
          variant="outlined"
          className="text-remodela-green border-remodela-green rounded-xl font-bold my-7"
          onClick={open}
        >
          ファイルを選択
        </Button>
      </Box>
    </>
  );
};

type DisplayGentyoImagesProps = {
  row: BizEstimateRow;
  handleDeleteGentyoImage: (URL: string) => void;
};
const DisplayGentyoImages = ({
  row,
  handleDeleteGentyoImage,
}: DisplayGentyoImagesProps): JSX.Element => {
  const { getGentyoImages } = useCategorizedEstimatesContext();

  const displayTempPendingGentyoImages = getGentyoImages(row);
  if (!displayTempPendingGentyoImages) {
    return <></>;
  }

  return (
    <>
      <Box className="flex w-[40rem] overflow-x-auto mb-4 space-x-4 pt-5">
        {displayTempPendingGentyoImages.map(
          (displayTempPendingGentyoImage, index) => {
            return (
              <div key={index} className="relative">
                <div className="h-20 w-36 min-w-[9rem] border-[1px] border-mediumGray">
                  <img
                    src={displayTempPendingGentyoImage.URL}
                    alt="gentyoImage"
                    className="h-full w-full object-contain"
                  />
                </div>
                <IconButton
                  className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                  onClick={() =>
                    handleDeleteGentyoImage(displayTempPendingGentyoImage.URL)
                  }
                >
                  <DeleteIcon className="w-4 h-4" />
                </IconButton>
              </div>
            );
          }
        )}
      </Box>
    </>
  );
};

type GentyoFileUploadDialogProps = {
  open: boolean;
  bizEstimateRow: BizEstimateRow;
  categoryId: string;
  handleDialogClose: () => void;
};
export const GentyoFileUploadDialog = ({
  open,
  bizEstimateRow,
  categoryId,
  handleDialogClose,
}: GentyoFileUploadDialogProps) => {
  const { addGentyoImages, deleteGentyoImage } =
    useCategorizedEstimatesContext();

  const handleDrop = async (acceptedFiles: File[]): Promise<void> => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const pendingGentyoImages = await Promise.all(
      acceptedFiles.map(async (file) => {
        const reader = new FileReader();
        const URL = await new Promise<string>((resolve) => {
          reader.onload = () => resolve(reader.result as string);
          reader.readAsDataURL(file);
        });

        const pendingGentyoImage: PendingGentyoImage = {
          file,
          fileName: `${crypto.randomUUID()}_${file.name}`,
          URL,
          isRegistered: false,
        };

        return pendingGentyoImage;
      })
    );

    addGentyoImages(categoryId, bizEstimateRow.rowNo, pendingGentyoImages);
  };

  const handleDeleteGentyoImage = (URL: string) => {
    deleteGentyoImage(categoryId, bizEstimateRow.rowNo, URL);
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      open={open}
      maxWidth="xl"
      PaperComponent={Paper}
      PaperProps={{ className: "rounded-2xl" }}
    >
      <DialogTitle className="flex justify-between items-center">
        <div className="font-bold">現調写真アップロード</div>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DisplayGentyoImages
          row={bizEstimateRow}
          handleDeleteGentyoImage={handleDeleteGentyoImage}
        />
        <FileDropZone onDrop={handleDrop} />
        <div className="text-sm">※対応ファイル形式：jpg, png</div>
      </DialogContent>
    </Dialog>
  );
};
