import { useState } from "react";
import { Button } from "@mui/material";

import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";
import { DiscountDialog } from "./dialogs/DiscountDialog";

/**「お値引きを登録」ボタン */
export const AddDiscountButton = (): JSX.Element => {
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState<boolean>(false);
  const { discountEstimate, getTotal, getSubTotal } = useCategorizedEstimatesContext();

  if (discountEstimate?.ownerFixed) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="contained"
        className="w-full my-2 bg-remodela-error"
        onClick={() => {
          setIsDiscountDialogOpen(true);
        }}
      >
        お値引きを登録
      </Button>

      {isDiscountDialogOpen && (
        <DiscountDialog
          open={isDiscountDialogOpen}
          handleDialogClose={() => {
            setIsDiscountDialogOpen(false);
          }}
          total={getTotal()}
          subTotal={getSubTotal()}
        />
      )}
    </>
  );
};
