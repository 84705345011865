import * as React from "react";
import { HeadTag } from "components/HeadTag/HeadTag";
import { Box, Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import LinkIcon from "@mui/icons-material/Link";

type Props = {
  children: React.ReactNode;
  title: string;
};

const Footer = (): JSX.Element => {
  return (
    <footer>
      <Box className="p-8 bg-remodela-green text-white">
        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={4}>
            <Box className="my-2">サービスに関するお問い合わせ</Box>
            <Box className="text-sm">
              <div className="py-1">
                <a href="tel:06-6123-8213">06-6123-8213</a>
                （大阪オフィス）
              </div>
              <div className="py-1">
                <a href="tel:03-4446-6457">03-4446-6457</a>
                （東京オフィス）
              </div>
              <div className="py-1">10:00 - 18:00（土日祝を除く）</div>
            </Box>
          </Grid2>
          <Grid2 xs={12} sm={4}>
            <Box className="my-2">REMODELAについて</Box>
            <Box className="text-sm">
              <div className="py-1">
                <a
                  href="https://about.remodela.jp/company/about.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  会社概要
                </a>
              </div>
            </Box>
          </Grid2>
          <Grid2 xs={12} sm={4}>
            <Box className="my-2">プライバシー</Box>
            <Box className="text-sm">
              <div className="py-1">
                <a
                  href="https://about.remodela.jp/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  個人情報保護方針
                  <LinkIcon />
                </a>
              </div>
            </Box>
            <Box className="text-sm">
              <div className="py-1">
                <a
                  href="https://www.remodela.jp/tos"
                  target="_blank"
                  rel="noreferrer"
                >
                  利用規約
                  <LinkIcon />
                </a>
              </div>
            </Box>
          </Grid2>
        </Grid2>
        <Box className="flex justify-center items-center my-2">
          <div>&copy;REMODELA,Inc.</div>
        </Box>
      </Box>
    </footer>
  );
};

export const PublicLayout = ({ children, title }: Props) => {
  return (
    <>
      <HeadTag title={title} />

      <Container className="p-0 m-0 max-w-full bg-offWhite min-h-screen">
        {children}
        <Footer />
      </Container>
    </>
  );
};
