import { Box, Button, Dialog } from "@mui/material";
import { Project } from "@shared/types/entities/owner/Project";
import { useState } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { useStore } from "@nanostores/react";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { useSnackbar } from "context/snackbarContext";
import { UpdateProjectByStatus } from "@shared/types/functionParams";
import { updateProjectByStatus } from "../api/updateProjectByStatus";

interface Props {
  project: Project;
}
export const ProjectDrop = ({ project }: Props): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { userId } = useStore(BizConstractorStore.IDMap);
  const { showSnackbar } = useSnackbar();

  if (project.status !== "オファー可能") {
    return <></>;
  }

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDrop = async (): Promise<void> => {
    const updateProjectByStatusRequestParams: UpdateProjectByStatus.RequestParams = {
      status: "取下げ",
      companyId: project.companyId,
      projectId: project.id!,
      bizUserId: userId,
    };
    await updateProjectByStatus(updateProjectByStatusRequestParams);

    setDialogOpen(false);

    showSnackbar("提案を中止しました。", "success");
  };

  return (
    <Box className="py-4 flex justify-center">
      <Button size="large" variant="contained" className="bg-remodela-error rounded-xl font-bold" onClick={handleClick}>
        提案を中止する
      </Button>

      <Dialog onClose={handleClose} open={dialogOpen}>
        <Box className="flex flex-col items-center p-3">
          <WarningIcon className="text-5xl text-remodela-error" />
          <div className="font-bold text-2xl">確認</div>

          <div className="flex flex-col items-center text-sm my-4">
            <div>見積の提案を中止しますか？</div>
            <div>一度中止した場合元に戻すことはできません。</div>
          </div>

          <div className="flex justify-around w-full">
            <Button variant="outlined" className="font-bold bg-white text-darkBlueGray border-2 border-mediumGray w-28" onClick={handleClose}>
              キャンセル
            </Button>
            <Button variant="contained" className="font-bold bg-remodela-error border-[1px] border-remodela-error w-28" onClick={handleDrop}>
              中止する
            </Button>
          </div>
        </Box>
      </Dialog>
    </Box>
  );
};
