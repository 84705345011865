import { Box } from "@mui/material";

import { TotalAmountHeader } from "./TotalAmountHeader";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";
import { EstimatesByCategory } from "./EstimatesByCategory";
import { AddCategoryButton } from "./AddCategoryButton";
import { AddDiscountButton } from "./AddDiscountButton";
import { EstimateByDiscount } from "./EstimateByDiscount";
import { SaveButton } from "./SaveButton";

export const EstimateEditorSP = (): JSX.Element => {
  const { categorizedEstimates } = useCategorizedEstimatesContext();

  return (
    <>
      <TotalAmountHeader />

      <Box className="m-2">
        {categorizedEstimates.map((categorizedEstimate) => (
          <EstimatesByCategory
            key={categorizedEstimate.categoryId}
            categorizedEstimate={categorizedEstimate}
          />
        ))}

        <EstimateByDiscount />

        <AddCategoryButton />
        <AddDiscountButton />

        <SaveButton />
      </Box>
    </>
  );
};
