import { doc, DocumentSnapshot, onSnapshot, Unsubscribe } from "firebase/firestore";
import { ownerDB } from "lib/firebase";

export function getOnSnapshotProject(
  companyId: string,
  projectId: string,
  callback: (docSnapshot: DocumentSnapshot) => void
): Unsubscribe {
  return onSnapshot(doc(ownerDB, `companies/${companyId}/projects/${projectId}`), (docSnapshot) => {
    callback(docSnapshot);
  });
}
