import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { isSameDay } from "date-fns";
import { Avatar, Box, Drawer, Fab, IconButton, InputAdornment, TextField, Badge } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import ChatIcon from "@mui/icons-material/Chat";
import { Project } from "repositories/owner/Project";
import { getFormattedDateAtTime, getFormattedDateWithDay } from "utils";
import { addBizEstimateComment } from "../../api/addBizEstimateComment";
import { useStore } from "@nanostores/react";
import { BizUserStore } from "store/nanostores/contractorInfo";
import { useSnackbar } from "context/snackbarContext";
import { getOnSnapshotProject } from "features/projectDetail/useCases/onSnapshot/project";
import { toEntityFromDoc } from "utils/firebaseUtil";
import { markOwnerCommentAsRead } from "api/markOwnerCommentAsRead";

interface Props {
  companyId: string;
  projectId: string;
}

export const CommentThread = ({ companyId, projectId }: Props): JSX.Element => {
  const [project, setProject] = useState<Project>();
  const [comment, setComment] = useState<string>("");
  const commentThreadEndRef = useRef<HTMLDivElement | null>(null);
  const [isCommentThreadOpen, setIsCommentThreadOpen] = useState<boolean>(false);

  const { lastName, firstName } = useStore(BizUserStore.bizUser);
  const { showSnackbar } = useSnackbar();

  const canSendComment = comment.length > 0;
  const unreadComments = project?.bizEstimateComments?.filter((comment) => comment.type === "owner" && !comment.isRead);

  useEffect(() => {
    const projectUnsubscribe = getOnSnapshotProject(companyId!, projectId!, (docSnapshot) => {
      setProject(toEntityFromDoc<Project>(docSnapshot));
    });

    return projectUnsubscribe;
  }, [companyId, projectId]);

  useEffect(() => {
    if (isCommentThreadOpen && project) {
      if (!project.bizEstimateComments) {
        return;
      }

      const hasUnread = project.bizEstimateComments.some((comment) => comment.type === "owner" && !comment.isRead);
      if (hasUnread) {
        markOwnerCommentAsRead({ companyId: project.companyId!, projectId: project.id! });
      }
    }

    scrollToBottom();
  }, [project, isCommentThreadOpen]);

  const scrollToBottom = (): void => {
    if (commentThreadEndRef.current) {
      commentThreadEndRef.current.scrollIntoView();
    }
  };

  const isDisplayCreatedAt = (estimateCommentIndex: number): boolean => {
    if (estimateCommentIndex === 0) {
      return true;
    }

    const bizEstimateComments = project?.bizEstimateComments;
    if (!bizEstimateComments) {
      return true;
    }

    const prevCreatedAt = bizEstimateComments[estimateCommentIndex - 1].createdAt.toDate();
    const createdAt = bizEstimateComments[estimateCommentIndex].createdAt.toDate();

    return !isSameDay(prevCreatedAt, createdAt);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.shiftKey && event.key === "Enter") {
      event.preventDefault();
      handleSaveComment();
    }
  };

  const handleSaveComment = async (): Promise<void> => {
    if (!comment || !project) {
      return;
    }

    try {
      await addBizEstimateComment({
        companyId,
        projectId,
        comment,
        pic: `${lastName} ${firstName}`,
      });

      setComment("");
    } catch (e) {
      showSnackbar("チャットの送信に失敗しました。", "error");
    }
  };

  return (
    <>
      <Fab
        variant="extended"
        className="fixed bottom-8 right-8 bg-remodela-green text-white"
        onClick={() => setIsCommentThreadOpen((isOpen) => !isOpen)}
      >
        担当者とチャット
        <Badge
          badgeContent={unreadComments?.length}
          color="error"
          invisible={unreadComments === undefined || unreadComments.length === 0}
        >
          <ChatIcon className="ml-1" />
        </Badge>
      </Fab>

      <Drawer
        anchor="right"
        open={isCommentThreadOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            maxWidth: "500px",
            width: "100%",
          },
        }}
      >
        <Box className="flex flex-col h-full">
          <Box className="flex min-h-[3rem] items-center justify-center border-b border-lightGray">
            <span>担当者チャット</span>
            <IconButton size="large" className="absolute top-0 right-0" onClick={() => setIsCommentThreadOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box className="flex flex-col overflow-auto grow">
            {project?.bizEstimateComments?.map((comment, index) => (
              <Box className="p-4" key={index}>
                {isDisplayCreatedAt(index) && (
                  <Box className="flex items-center justify-center m-auto text-sm text-white bg-mediumGray rounded-xl max-w-[120px]">
                    {getFormattedDateWithDay(comment.createdAt)}
                  </Box>
                )}

                {/* 業者コメント */}
                {comment.type === "contractor" && (
                  <Box className="flex justify-end">
                    <Box className="mr-4">
                      <Box className="text-sm text-right">{comment.pic}</Box>

                      <Box className="flex">
                        <Box className="flex items-end text-xs px-1">{getFormattedDateAtTime(comment.createdAt)}</Box>
                        <Box className="whitespace-pre-wrap text-sm m-0 relative p-2 rounded-2xl bg-[#8de055] after:content-[''] after:absolute after:top-[8px] after:right-[-8px] after:m-auto after:align-middle after:border-0 after:border-transparent after:border-b-[7px] after:border-b-[#8de055] after:rounded-br-[10px] after:w-[10px] after:h-[10px]">
                          {comment.comment}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}

                {/* 施主コメント */}
                {comment.type === "owner" && (
                  <Box className="flex">
                    <Avatar className="bg-brown mt-2">{comment.pic.charAt(0)}</Avatar>

                    <Box className="ml-4">
                      <Box className="text-sm">{comment.pic}</Box>

                      <Box className="flex">
                        <Box className="whitespace-pre-wrap text-sm m-0 relative p-2 rounded-2xl bg-lightGray after:content-[''] after:absolute after:top-[8px] after:left-[-8px] after:m-auto after:align-middle after:border-0 after:border-transparent after:border-t-[7px] after:border-t-lightGray after:rounded-tr-[10px] after:w-[10px] after:h-[10px] after:rotate-180">
                          {comment.comment}
                        </Box>
                        <Box className="flex items-end text-xs px-1">{getFormattedDateAtTime(comment.createdAt)}</Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
            <Box ref={commentThreadEndRef} />
          </Box>

          <Box className="flex items-center p-4">
            <TextField
              value={comment}
              fullWidth
              multiline
              maxRows={8}
              InputProps={{
                classes: {
                  root: "rounded-3xl",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" disabled={!canSendComment} onClick={() => handleSaveComment()}>
                      <SendIcon className={`${canSendComment && "text-[#0084FF]"}`} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
