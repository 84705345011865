import { ChangeEvent, forwardRef } from "react";
import {
  BizEstimateRow,
  PendingGentyoImage,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

type GentyoFileInputProps = {
  row: BizEstimateRow;
  categoryId: string;
};

export const GentyoFileInput = forwardRef<
  HTMLInputElement,
  GentyoFileInputProps
>(({ row, categoryId }, ref) => {
  const { addGentyoImages } = useCategorizedEstimatesContext();

  /**現調写真をファイル選択ダイアログから選択 */
  const handleSelectedGentyoImages = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const fileList = e.target.files;
    if (!fileList || fileList.length === 0) {
      return;
    }
    const files = [...fileList];

    const pendingGentyoImages = await Promise.all(
      files.map(async (file) => {
        const reader = new FileReader();
        const URL = await new Promise<string>((resolve) => {
          reader.onload = () => resolve(reader.result as string);
          reader.readAsDataURL(file);
        });

        const pendingGentyoImage: PendingGentyoImage = {
          file,
          fileName: `${crypto.randomUUID()}_${file.name}`,
          URL,
          isRegistered: false,
        };

        return pendingGentyoImage;
      })
    );

    addGentyoImages(categoryId, row.rowNo, pendingGentyoImages);
  };

  return (
    <>
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        multiple
        ref={ref}
        onChange={handleSelectedGentyoImages}
        className="hidden"
      />
    </>
  );
});
