import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Timestamp } from "firebase/firestore";
import { Box, Button, Divider, Tooltip } from "@mui/material";

import { Project } from "repositories/owner/Project";
import { fetchBizContractorByContractorId } from "repositories/owner/BizContractor";
import { SwapFile } from "@shared/types/entities/owner/Project";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ownerStorage } from "lib/firebase";
import { UpdateProjectBySwapFiles } from "@shared/types/functionParams";
import { updateProjectBySwapFiles } from "../../api/updateProjectBySwapFiles";
import { fileShareNotification } from "../../api/fileShareNotification";
import { downloadFile, fetchBlobURL } from "../../useCases/storage/contractFile";

interface Props {
  project: Project;
}

export const FileShare = ({ project }: Props): JSX.Element => {
  const [bizContractorName, setBizContractorName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const initBizContractorName = async (): Promise<void> => {
      const bizContractor = await fetchBizContractorByContractorId(project.bizContractorId!);
      setBizContractorName(bizContractor.name);
    };

    initBizContractorName();
  }, [project.bizContractorId]);

  const handleDownloadSwapFile = async (swapOwnerFile: SwapFile): Promise<void> => {
    const blobURL = await fetchBlobURL(swapOwnerFile.url);
    downloadFile(blobURL, swapOwnerFile.name);
  };

  const uploadFile = {
    /**アップロードボタンクリック → inputクリックしたことにする */
    handleFileSelectButtonClick: (): void => {
      fileInputRef.current?.click();
    },
    /**ファイル選択 → storageに保存+firestoreに保存 */
    handleFileChange: async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (!event.target.files) {
        return;
      }

      const files: File[] = Array.from(event.target.files);
      if (files.length === 0) {
        return;
      }

      setBackdrop(true);
      try {
        await uploadFile.saveFiles(files);

        // リモデラにファイル連携のメール通知
        await fileShareNotification({
          companyId: project.companyId,
          projectId: project.id!,
          projectName: project.projectName,
          bizContractorName,
          fileNames: files.map((file) => file.name),
        });
      } catch (error) {
        showSnackbar("アップロードに失敗しました", "error");
      }

      // ファイル選択をリセット
      event.target.value = "";
      setBackdrop(false);
    },
    /**storageに保存 + firestoreに保存 */
    saveFiles: async (files: File[]): Promise<void> => {
      let swapFiles: SwapFile[] = [];

      const dirPath = `companies/${project.companyId}/projects/${project.id!}/swap_files/biz`;
      const now = new Date();

      for (const file of files) {
        const storageRef = ref(ownerStorage, `${dirPath}/${now.getTime()}_${file.name}`);
        await uploadBytes(storageRef, file);

        const downloadURL = await getDownloadURL(storageRef);

        const swapFile = {
          name: file.name,
          url: downloadURL,
          createdAt: Timestamp.fromDate(now),
        };

        swapFiles.push(swapFile);
      }

      const params: UpdateProjectBySwapFiles.RequestParams = {
        swapFiles,
        companyId: project.companyId,
        projectId: project.id!,
      };
      await updateProjectBySwapFiles(params);
    },
  };

  return (
    <>
      <Box>
        <Box className="font-bold">{project.companyName}</Box>
        {project.swapOwnerFiles?.map((swapOwnerFile, index) => {
          return (
            <Box key={index}>
              <Tooltip title="ダウンロード" placement="right">
                <span
                  className="text-remodela-green cursor-pointer underline decoration-[#1976d366] hover:decoration-remodela-green"
                  onClick={() => handleDownloadSwapFile(swapOwnerFile)}
                >
                  {swapOwnerFile.name}
                </span>
              </Tooltip>
            </Box>
          );
        })}
      </Box>

      <Divider className="my-2" />

      <Box>
        <Box className="font-bold">{bizContractorName}</Box>
        {project.swapBizFiles?.map((swapBizFile, index) => {
          return (
            <Box key={index}>
              <Tooltip title="ダウンロード" placement="right">
                <span
                  className="text-remodela-green cursor-pointer underline decoration-[#1976d366] hover:decoration-remodela-green"
                  onClick={() => handleDownloadSwapFile(swapBizFile)}
                >
                  {swapBizFile.name}
                </span>
              </Tooltip>
            </Box>
          );
        })}

        <Box className="mt-2">
          <Button
            type="submit"
            variant="contained"
            className="font-bold text-xs rounded-lg bg-remodela-green"
            onClick={uploadFile.handleFileSelectButtonClick}
          >
            アップロード（複数選択可能）
          </Button>
          <Box className="px-1 text-sm font-normal text-remodela-error">※見積書を添付しないでください</Box>
          <input type="file" multiple ref={fileInputRef} onChange={uploadFile.handleFileChange} className="hidden" />
        </Box>
      </Box>
    </>
  );
};
