import { createContext, useContext, useState } from "react";
import * as z from "zod";

export const schema = z.object({
  staffLastName: z.string().min(1, "入力してください"),
  staffFirstName: z.string().min(1, "入力してください"),
  email: z.string().min(1, "入力してください").email({ message: "有効なメールアドレスを入力してください" }),
  password: z
    .string()
    .min(1, { message: "必須入力です" })
    .min(6, { message: "6文字以上で入力してください" })
    .regex(/^[0-9a-zA-Z]*$/, { message: "半角英数字のみ使用できます" }),
  contractorName: z.string().min(1, "入力してください"),
  representativeLastName: z.string().min(1, "入力してください"),
  representativeFirstName: z.string().min(1, "入力してください"),
  prefecture: z.string().min(1, "選択してください"),
  city: z.string().min(1, "入力してください"),
  address: z.string().min(1, "入力してください"),
  tel: z
    .string()
    .min(1, "入力してください")
    .regex(/^0\d{9,10}$/, { message: "電話番号を入力してください" }),
  selectedJobTypes: z.array(z.string()),
  isFindingNewClients: z.boolean(),
  needFinalInspection: z.boolean(),
  priceList: z
    .object({
      base64: z.string(),
      fileName: z.string(),
      contentType: z.string(),
    })
    .nullable(),
  constructionLicenseId: z.string(),
  invoiceNumber: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .nullable()
    .refine((val) => val === null || /^[0-9]+$/.test(val), {
      message: "数字のみを含めてください",
    })
    .refine((val) => val === null || val.length === 13, {
      message: "13桁の文字数が必要です",
    }),
  digitalStamp: z
    .object({
      base64: z.string(),
      fileName: z.string(),
      contentType: z.string(),
    })
    .nullable(),
});

export type RegisterFormValues = z.infer<typeof schema>;

export const defaultValues: RegisterFormValues = {
  staffLastName: "",
  staffFirstName: "",
  email: "",
  password: "",
  contractorName: "",
  representativeLastName: "",
  representativeFirstName: "",
  prefecture: "",
  city: "",
  address: "",
  tel: "",
  selectedJobTypes: [],
  isFindingNewClients: false,
  needFinalInspection: false,
  priceList: null,
  constructionLicenseId: "",
  invoiceNumber: null,
  digitalStamp: null,
};

interface RegisterFormContextType {
  formData: RegisterFormValues;
  updateFormData: (formData: RegisterFormValues) => void;
}

const RegisterFormContext = createContext<RegisterFormContextType>({} as RegisterFormContextType);

export const useRegisterFormContext = () => useContext(RegisterFormContext);

export const RegisterFormProvider = ({ children }: { children: React.ReactNode }) => {
  const [formData, setFormData] = useState<RegisterFormValues>(defaultValues);

  const updateFormData = (data: RegisterFormValues): void => {
    setFormData(data);
  };

  return <RegisterFormContext.Provider value={{ formData, updateFormData }}>{children}</RegisterFormContext.Provider>;
};
