import { useState, useEffect } from "react";
import { isProfitManagementAddOnEnabled } from "api/isProfitManagementAddOnEnabled";

interface UseProfitManagementAddOnProps {
  isAddOnEnabled: boolean;
}

export const useProfitManagementAddOn = (companyId: string): UseProfitManagementAddOnProps => {
  const [isAddOnEnabled, setIsAddOnEnabled] = useState(false);

  useEffect(() => {
    const fetchProfitManagementAddOnEnabled = async (): Promise<void> => {
      const result = await isProfitManagementAddOnEnabled(companyId);
      setIsAddOnEnabled(result.enabled);
    };

    fetchProfitManagementAddOnEnabled();
  }, [companyId]);

  return { isAddOnEnabled };
};
