import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { UpdateBizEstimatesNotification } from "@shared/types/functionParams";

export async function updateBizEstimatesNotification(companyId: string, projectId: string): Promise<void> {
  const params: UpdateBizEstimatesNotification.RequestParams = {
    companyId,
    projectId,
  };

  const func = httpsCallable(bizFunctions, "onCallUpdateBizEstimatesNotification");
  await func(params);
}
