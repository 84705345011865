import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";
import Big, { RoundingMode } from "big.js";

export const AddDiscount = (): JSX.Element => {
  const { discountEstimate, getTotal, getSubTotal } = useCategorizedEstimatesContext();
  const [discountDialogOpen, setDiscountDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Box className={`flex justify-end mt-2 ${discountEstimate && "hidden"}`}>
        <Box
          className="flex items-center cursor-pointer text-remodela-error"
          onClick={() => setDiscountDialogOpen(true)}
        >
          <AddIcon className="text-3xl" />
          <Box className="font-bold text-sm">　お値引きを追加する</Box>
        </Box>
      </Box>

      {discountDialogOpen && (
        <DiscountDialog
          open={discountDialogOpen}
          handleDialogClose={() => setDiscountDialogOpen(false)}
          total={getTotal()}
          subTotal={getSubTotal()}
        />
      )}
    </>
  );
};

interface DiscountDialogProps {
  open: boolean;
  handleDialogClose: () => void;
  total: number;
  subTotal: number;
}
/**値引き設定ダイアログ */
const DiscountDialog = ({ open, handleDialogClose, total, subTotal }: DiscountDialogProps): JSX.Element => {
  // 値引き後合計
  const [discountedTotal, setDiscountedTotal] = useState<number>(total);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const { taxType, editDiscountCategory } = useCategorizedEstimatesContext();

  // 値引き行追加
  const handleAddDiscountCategory = () => {
    editDiscountCategory(discountAmount);
    handleDialogClose();
  };

  // 値引額反映
  const handleInputChange = (value: string): void => {
    const discountedTotal = Number(value);
    if (isNaN(discountedTotal)) {
      return;
    }

    // 値引き後合計
    setDiscountedTotal(discountedTotal);

    if (taxType === "zero") {
      // 値引額 = 値引き後合計 - 値引き前小計
      const discountAmount = discountedTotal - subTotal;
      setDiscountAmount(discountAmount);
      return;
    }

    // 値引き後の小計に対して消費税の[切上げ/切捨て]が発生するので、
    // 値引き金額は消費税とは逆の[切上げ/切捨て]を行う必要がある
    let roundingMode: RoundingMode = 0;
    if (taxType === "roundDown") {
      roundingMode = Big.roundUp;
    } else if (taxType === "roundUp") {
      roundingMode = Big.roundDown;
    }

    const discountedSubTotal = Big(discountedTotal).div(1.1).round(0, roundingMode).toNumber();

    // 値引額 = 値引き後小計 - 値引き前小計
    const discountAmount = discountedSubTotal - subTotal;
    setDiscountAmount(discountAmount);
  };

  return (
    <Dialog open={open} maxWidth="xl" PaperComponent={Paper} PaperProps={{ className: "rounded-2xl" }}>
      <DialogTitle className="flex justify-between items-center">
        <Box className="font-bold">値引き設定</Box>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="w-96">
        <Box className="flex items-center h-10">
          <Box className="w-28">値引き前合計</Box>
          <Box>{`¥${total.toLocaleString()}`}</Box>
        </Box>
        <Box className="flex items-center h-10">
          <Box className="w-28">値引き額</Box>
          <Box className="text-remodela-error">{`¥${discountAmount.toLocaleString()}`}</Box>
        </Box>
        <Box className="flex items-center h-10">
          <Box className="w-28">値引き後合計</Box>
          <TextField
            value={discountedTotal || ""}
            onChange={(e) => handleInputChange(e.target.value)}
            type="number"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: <InputAdornment position="start">¥</InputAdornment>,
            }}
            className="w-36"
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          className="font-bold bg-white text-darkBlueGray border-2 border-mediumGray w-28"
          onClick={handleDialogClose}
        >
          キャンセル
        </Button>
        <Button variant="contained" className="bg-remodela-green font-bold" onClick={handleAddDiscountCategory}>
          値引き行を追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};
