import { collection, getDocs, orderBy, query } from "firebase/firestore";

import { Review } from "@shared/types/entities/owner/Review";
import { ownerDB } from "lib/firebase";
import { toEntityFromDoc } from "utils/firebaseUtil";

/**点検結果一覧を取得 */
export const getReviews = async (companyId: string, projectId: string): Promise<Review[]> => {
  const q = query(
    collection(ownerDB, `companies/${companyId}/projects/${projectId}/reviews`),
    orderBy("createdAt", "asc")
  );

  const querySnapshot = await getDocs(q);

  const reviews = querySnapshot.docs.map((queryDocSnapshot) => {
    return toEntityFromDoc<Review>(queryDocSnapshot);
  });

  return reviews;
};
