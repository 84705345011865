import { Project } from "@shared/types/entities/owner/Project";
import { useEffect, useState } from "react";
import { getProjectByCompanyIdProjectId } from "repositories/owner/Project";

export function useProject(companyId: string, projectId: string): Project | undefined {
  const [project, setProject] = useState<Project>();

  useEffect(() => {
    const fetchProject = async (): Promise<void> => {
      const project = await getProjectByCompanyIdProjectId(companyId, projectId);
      setProject(project);
    };

    fetchProject();
  }, [companyId, projectId]);

  return project;
}
