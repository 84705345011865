import { doc, getDoc } from "firebase/firestore";
import { BizUser } from "@shared/types/entities/owner/BizUser";
import { ownerDB } from "lib/firebase";
import { toEntityFromDoc } from "utils/firebaseUtil";

export async function fetchBizUserByBizContractorIdBizUserId(
  bizContractorId: string,
  bizUserId: string
): Promise<BizUser> {
  const docRef = doc(ownerDB, `bizContractors/${bizContractorId}/bizUsers/${bizUserId}`);
  const docSnapshot = await getDoc(docRef);

  return toEntityFromDoc<BizUser>(docSnapshot);
}
