import { Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useCategorizedEstimatesContext } from "../../context/categorizedEstimatesContext";
import { displayTaxType } from "@shared/utils";
import { useNavigate, useParams } from "react-router-dom";

export const TotalAmountHeader = (): JSX.Element => {
  const { companyId, projectId } = useParams();
  const navigate = useNavigate();

  const { getTotal, getSubTotal, getTax, taxType } =
    useCategorizedEstimatesContext();

  return (
    <>
      <Box className="flex items-center sticky top-0 z-[1299] bg-white py-2 px-4 border-b border-lightGray">
        <Box>
          <IconButton
            onClick={() => {
              navigate(`/companies/${companyId}/projects/${projectId}`);
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
        </Box>
        <Box className="flex flex-col ml-4">
          <Box className="flex items-baseline">
            <p className="text-sm">合計</p>
            <p className="text-lg text-pink font-bold min-w-24 pl-2 text-right">
              {`¥${getTotal().toLocaleString()}`}
            </p>
          </Box>

          <Box className="flex">
            <Box className="flex items-baseline mr-4">
              <p className="text-xs">小計</p>
              <p className="text-sm font-bold min-w-24 pl-2 text-right">
                {`¥${getSubTotal().toLocaleString()}`}
              </p>
            </Box>

            <Box className="flex items-baseline">
              <p className="text-xs">{`${displayTaxType(taxType)}`}</p>
              <p className="text-sm font-bold min-w-24 pl-2 text-right">
                {`¥${getTax().toLocaleString()}`}
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
