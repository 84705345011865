import { useState } from "react";
import { useLocation } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { Button, Box, Paper, Checkbox } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { bizAuth } from "lib/firebase";
import { useRegisterFormContext } from "features/auth/context/RegisterFormContext";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { CreateBizContractorAndUser, SetBizAuthCustomClaims } from "@shared/types/functionParams";
import { createBizContractorAndUser } from "features/auth/api/createBizContractorAndUser";
import { setBizAuthCustomClaims } from "features/auth/api/setBizAuthCustomClaims";

interface Step2Props {
  className?: string;
  handleBackStep: () => void;
  handleNextStep: () => void;
}

export const Step2 = (props: Step2Props): JSX.Element => {
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const location = useLocation();
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  const { formData } = useRegisterFormContext();

  const saveFormData = async (): Promise<void> => {
    if (!formData) {
      return;
    }

    try {
      setBackdrop(true);

      const createBizContractorAndUserRequestParams: CreateBizContractorAndUser.RequestParams = {
        /**担当者情報 */
        staffLastName: formData.staffLastName,
        staffFirstName: formData.staffFirstName,
        email: formData.email,

        /**企業情報(必須) */
        contractorName: formData.contractorName,
        representativeLastName: formData.representativeLastName,
        representativeFirstName: formData.representativeFirstName,
        prefecture: formData.prefecture,
        city: formData.city,
        address: formData.address,
        tel: formData.tel,
        isFindingNewClients: formData.isFindingNewClients,
        jobTypes: formData.selectedJobTypes,
        needFinalInspection: formData.needFinalInspection,

        /**企業情報(任意) */
        priceListPreview: formData.priceList,
        constructionLicenseId: formData.constructionLicenseId,
        invoiceId: formData.invoiceNumber ? `T${formData.invoiceNumber}` : "",
        digitalStampPreview: formData.digitalStamp,

        /**原状回復から招待されている場合 */
        inviteId: new URLSearchParams(location.search).get("inviteId") || "",
        encryptedCompanyId: new URLSearchParams(location.search).get("companyId") || "",
        expiredAt: new URLSearchParams(location.search).get("expiredAt") || "",
        hmac: new URLSearchParams(location.search).get("hmac") || "",
      };
      const { bizContractorId, userId } = await createBizContractorAndUser(createBizContractorAndUserRequestParams);

      const { user } = await createUserWithEmailAndPassword(bizAuth, formData.email, formData.password);
      await sendEmailVerification(user);

      const setBizAuthCustomClaimsRequestParams: SetBizAuthCustomClaims.RequestParams = {
        uid: user.uid, // biz/Auth/ユーザー
        userId, // owner/Firestore/userId
        bizContractorId,
      };
      await setBizAuthCustomClaims(setBizAuthCustomClaimsRequestParams);

      props.handleNextStep();
    } catch (e) {
      if (!(e instanceof FirebaseError)) {
        showSnackbar("登録に失敗しました。", "error");
        return;
      }

      if (e.code === "auth/email-already-in-use") {
        showSnackbar("このメールアドレスは使用されています", "error");
      } else {
        showSnackbar(e.message, "error");
      }
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Box className={props.className}>
      <p className="text-sm">
        ご入力いただいた登録内容を確認していただき、問題がなければ
        <b>登録</b>をクリックしてください。
      </p>

      <Box>
        <Box className="flex py-5 items-center">
          <CircleIcon fontSize="medium" className="text-remodela-green" />
          <Box className="font-bold text-xl">パスワード設定</Box>
        </Box>

        <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
          <Box className="mb-4">
            <Box className="font-bold text-sm">担当者名</Box>
            <Box className="text-sm">{`${formData?.staffLastName} ${formData?.staffFirstName}`}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">メールアドレス</Box>
            <Box className="text-sm">{formData?.email}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">パスワード</Box>
            <Box className="text-sm">{"*".repeat(formData?.password?.length || 1)}</Box>
          </Box>
        </Paper>
      </Box>

      <Box>
        <Box className="flex py-5 items-center">
          <CircleIcon fontSize="medium" className="text-remodela-green" />
          <Box className="font-bold text-xl">企業情報登録</Box>
        </Box>

        <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
          <Box className="mb-4">
            <Box className="font-bold text-sm">企業名（屋号）</Box>
            <Box className="text-sm">{formData?.contractorName}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">代表者名</Box>
            <Box className="text-sm">{`${formData?.representativeLastName} ${formData?.representativeFirstName}`}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">会社所在地</Box>
            <Box className="text-sm">{`${formData?.prefecture} ${formData?.city} ${formData?.address}`}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">電話番号</Box>
            <Box className="text-sm">{formData?.tel}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">職種</Box>
            <Box className="text-sm">{formData.selectedJobTypes.join(" / ")}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">新規取引先</Box>
            <Box className="text-sm">
              {formData?.isFindingNewClients ? "新規取引先を探したい" : "新規取引先を探さない"}
            </Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">退去立会</Box>
            <Box className="text-sm">
              {formData?.needFinalInspection ? "退去立会も対応できる" : "退去立会は対応できない"}
            </Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">料金表</Box>
            {formData.priceList && (
              <Box className="relative h-60 w-60 mr-3">
                {formData.priceList.contentType.includes("image") ? (
                  <img src={formData.priceList.base64} alt="プレビュー" className="h-full w-full object-contain" />
                ) : (
                  <embed
                    type="application/pdf"
                    src={formData.priceList.base64}
                    className="h-full w-full object-contain"
                  />
                )}
              </Box>
            )}
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">建設業許可番号</Box>
            <Box className="text-sm">{formData?.constructionLicenseId || "-"}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">登録番号</Box>
            <Box className="text-sm">{formData?.invoiceNumber ? `T${formData?.invoiceNumber}` : "-"}</Box>
          </Box>
          <Box className="mb-4">
            <Box className="font-bold text-sm">電子印鑑</Box>
            {formData.digitalStamp && (
              <Box className="relative h-20 w-20 mr-3">
                <img src={formData.digitalStamp.base64} alt="プレビュー" className="h-full w-full object-contain" />
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      <Box>
        <Checkbox
          size="small"
          className="text-remodela-green"
          onChange={() => {
            setIsAgree((current) => !current);
          }}
        />
        <span>
          <a
            href="https://www.remodela.jp/tos"
            target="_blank"
            rel="noreferrer"
            className="underline underline-offset-2"
          >
            利用規約
          </a>
          <span className="mx-2">および</span>
          <a
            href="https://about.remodela.jp/privacy.html"
            target="_blank"
            rel="noreferrer"
            className="underline underline-offset-2"
          >
            個人情報保護方針
          </a>
          <span className="mx-2">に同意する</span>
        </span>
      </Box>

      <Box className="flex justify-center mt-10">
        <Button
          variant="outlined"
          className="font-bold bg-white text-darkBlueGray rounded-lg border-2 border-mediumGray w-40 h-12 mx-3"
          onClick={props.handleBackStep}
        >
          前の画面に戻る
        </Button>
        <Button
          variant="contained"
          className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12 mx-3"
          onClick={saveFormData}
          disabled={!isAgree}
        >
          登録
        </Button>
      </Box>
    </Box>
  );
};
