import { useEffect, useState } from "react";
import { BizEstimate } from "@shared/types/entities/owner/BizEstimate";

type CategorizedEstimates = {
  categoryName: string;
  bizEstimates: BizEstimate[];
};

export type UseCategorizedBizEstimatesProps = {
  categorizedEstimatesList: CategorizedEstimates[];
};
export const useCategorizedBizEstimates = (bizEstimates: BizEstimate[]): UseCategorizedBizEstimatesProps => {
  const [categorizedEstimatesList, setCategorizedEstimatesList] = useState<CategorizedEstimates[]>([]);

  useEffect(() => {
    let categorizedEstimatesList: CategorizedEstimates[] = [];

    for (let categoryNo = 1; ; categoryNo++) {
      const filteredBizEstimates = bizEstimates.filter((bizEstimate) => bizEstimate.categoryNo === categoryNo);

      if (filteredBizEstimates.length === 0) {
        break;
      }

      categorizedEstimatesList = [
        ...categorizedEstimatesList,
        {
          categoryName: filteredBizEstimates[0].categoryName || "",
          bizEstimates: filteredBizEstimates,
        },
      ];
    }

    const discountEstimate = bizEstimates.find((bizEstimate) => bizEstimate.categoryNo === 9999);
    if (discountEstimate) {
      categorizedEstimatesList = [
        ...categorizedEstimatesList,
        {
          categoryName: discountEstimate.categoryName,
          bizEstimates: [discountEstimate],
        },
      ];
    }

    setCategorizedEstimatesList(categorizedEstimatesList);
  }, [bizEstimates]);

  return {
    categorizedEstimatesList,
  };
};

export default useCategorizedBizEstimates;
