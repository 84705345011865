import {
  MouseEvent,
  ReactElement,
  Ref,
  forwardRef,
  useRef,
  useState,
} from "react";

import {
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Slide,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import NotesIcon from "@mui/icons-material/Notes";

import { TransitionProps } from "@mui/material/transitions";

import {
  BizEstimateRow,
  CategorizedEstimate,
  useCategorizedEstimatesContext,
} from "../../context/categorizedEstimatesContext";
import { UNITS } from "constants/constants";
import { GentyoFileInput } from "./GentyoFileInput";

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>
  ): JSX.Element => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

type GentyoImageDialogProps = {
  open: boolean;
  row: BizEstimateRow;
  categoryId: string;
  handleDialogClose: () => void;
};
const GentyoImageDialog = ({
  open,
  row,
  categoryId,
  handleDialogClose,
}: GentyoImageDialogProps): JSX.Element => {
  const gentyoImageInputRef = useRef<HTMLInputElement>(null);
  const { getGentyoImages, deleteGentyoImage } =
    useCategorizedEstimatesContext();
  const pendingGentyoImages = getGentyoImages(row);

  /**「現調写真を添付する」をクリック */
  const handleAddGentyoImagesClick = (): void => {
    gentyoImageInputRef?.current?.click();
  };

  return (
    <>
      <Dialog
        fullScreen
        onClose={handleDialogClose}
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle className="fixed top-0 left-0 z-10 w-full bg-white flex items-center justify-between border-b border-lightGray">
          <p className="text-lg">現地調査写真</p>
          <IconButton onClick={handleDialogClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Grid container className="mt-20">
          {pendingGentyoImages.map((gentyoImage, i) => {
            return (
              <Grid xs={6} className="relative p-2" key={i}>
                <img
                  src={gentyoImage.URL}
                  alt="noImage"
                  className="h-full w-full object-contain"
                />
                <IconButton
                  className="absolute top-2 right-2"
                  onClick={() => {
                    deleteGentyoImage(categoryId, row.rowNo, gentyoImage.URL);
                  }}
                >
                  <DeleteIcon className="text-remodela-error" />
                </IconButton>
              </Grid>
            );
          })}
        </Grid>

        <Fab
          className="fixed bottom-4 right-4 bg-remodela-green text-white"
          onClick={handleAddGentyoImagesClick}
        >
          <AddIcon />
        </Fab>
      </Dialog>

      <GentyoFileInput
        ref={gentyoImageInputRef}
        row={row}
        categoryId={categoryId}
      />
    </>
  );
};

type EstimateRowProps = {
  row: BizEstimateRow;
  categoryId: string;
};
const EstimateRow = ({ row, categoryId }: EstimateRowProps): JSX.Element => {
  const gentyoImageInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [isGentyoImageDialogOpen, setIsGentyoImageDialogOpen] =
    useState<boolean>(false);

  const {
    changeItemName,
    changeQuantity,
    changeUnit,
    changeUnitPrice,
    changeNote,
    deleteRow,
    getFirstGentyoImageURL,
    getGentyoImageCount,
  } = useCategorizedEstimatesContext();

  const hasNote = row.note !== undefined; // 備考あり
  const hasAmount = row.amount !== undefined; // 金額あり
  const firstGentyoImageURL = getFirstGentyoImageURL(row);
  const gentyoImageCount = getGentyoImageCount(row);

  const isMenuOpen = Boolean(anchorEl);

  const closeMenu = (): void => {
    setAnchorEl(undefined);
  };

  const handleOpenMenuClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**「備考を追加する」をクリック */
  const handleAddNoteMenuClick = (): void => {
    closeMenu();
    changeNote(categoryId, row.rowNo, "");
  };

  /**「現調写真を添付する」をクリック */
  const handleAddGentyoImagesMenuClick = (): void => {
    closeMenu();
    gentyoImageInputRef?.current?.click();
  };

  return (
    <Paper variant="outlined" className="p-2 mb-2">
      {/* 項目、メニュー、削除 */}
      <Box className="flex justify-between items-center">
        <TextField
          value={row.itemName || ""}
          onChange={(e) =>
            changeItemName(categoryId, row.rowNo, e.target.value)
          }
          label="項目名"
          placeholder="洋室 / 壁クロス"
          size="small"
          margin="dense"
          className="flex-grow mr-4"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box>
          <IconButton onClick={handleOpenMenuClick}>
            <MenuIcon />
          </IconButton>

          <IconButton onClick={() => deleteRow(categoryId, row.rowNo)}>
            <DeleteIcon className="text-remodela-error" />
          </IconButton>
        </Box>
        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={closeMenu}>
          <MenuItem onClick={handleAddGentyoImagesMenuClick}>
            <ListItemIcon>
              <CameraAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>現調写真を添付する</ListItemText>
          </MenuItem>

          <MenuItem disabled={hasNote} onClick={handleAddNoteMenuClick}>
            <ListItemIcon>
              <NotesIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>備考を追加する</ListItemText>
          </MenuItem>
        </Menu>

        <GentyoFileInput
          ref={gentyoImageInputRef}
          row={row}
          categoryId={categoryId}
        />
      </Box>

      {/* 数量、単位、単価 */}
      <Box className="flex">
        <TextField
          value={row.quantity || ""}
          onChange={(e) =>
            changeQuantity(categoryId, row.rowNo, e.target.value)
          }
          label="数量"
          placeholder="26.1"
          type="number"
          size="small"
          margin="dense"
          className="w-1/3"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Autocomplete
          value={row.unit || ""}
          onChange={(_, newValue) => {
            changeUnit(categoryId, row.rowNo, newValue || "");
          }}
          options={UNITS}
          freeSolo
          size="small"
          className="w-1/3"
          renderInput={(params) => (
            <TextField
              margin="dense"
              label="単位"
              placeholder="m"
              onChange={(e) =>
                changeUnit(categoryId, row.rowNo, e.target.value)
              }
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        <TextField
          value={row.unitPrice || ""}
          onChange={(e) =>
            changeUnitPrice(categoryId, row.rowNo, e.target.value)
          }
          label="単価"
          placeholder="1000"
          type="number"
          size="small"
          margin="dense"
          className="w-1/3"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">¥</InputAdornment>,
          }}
        />
      </Box>

      {/* 金額 */}
      <Box>
        {hasAmount && (
          <p className="text-base text-right font-bold mr-2">
            ¥{row.amount?.toLocaleString()}
          </p>
        )}
      </Box>

      {/* 現調写真、　備考 */}
      <Box className="flex items-center">
        {firstGentyoImageURL && (
          <Box
            className="relative flex h-16 w-12 mr-2"
            onClick={() => {
              setIsGentyoImageDialogOpen(true);
            }}
          >
            <img
              src={firstGentyoImageURL}
              alt="noImage"
              className="h-full w-full object-contain"
            />
            {gentyoImageCount > 1 && (
              <div className="absolute bottom-0 right-0 bg-pink text-white text-sm px-1 rounded">
                {`+${gentyoImageCount - 1}`}
              </div>
            )}
          </Box>
        )}

        {hasNote && (
          <TextField
            value={row.note}
            onChange={(e) => changeNote(categoryId, row.rowNo, e.target.value)}
            label="備考"
            multiline
            margin="dense"
            maxRows={2}
            className="grow"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </Box>

      <GentyoImageDialog
        open={isGentyoImageDialogOpen}
        row={row}
        categoryId={categoryId}
        handleDialogClose={() => {
          setIsGentyoImageDialogOpen(false);
        }}
      />
    </Paper>
  );
};

type FixedEstimateRowProps = {
  row: BizEstimateRow;
  categoryId: string;
};
const FixedEstimateRow = ({
  row,
  categoryId,
}: FixedEstimateRowProps): JSX.Element => {
  const [isGentyoImageDialogOpen, setIsGentyoImageDialogOpen] =
    useState<boolean>(false);

  const { getFirstGentyoImageURL, getGentyoImageCount } =
    useCategorizedEstimatesContext();
  const firstGentyoImageURL = getFirstGentyoImageURL(row);
  const gentyoImageCount = getGentyoImageCount(row);

  return (
    <Paper variant="outlined" className="flex items-center p-2 mb-2">
      <Box className="grow">
        <Box className="flex justify-between items-center">{row.itemName}</Box>

        <Box className="flex items-center">
          <p>{`¥${row.amount?.toLocaleString()}`}</p>
          <p>{`(${row.quantity} ${row.unit} ×
             ¥${row.unitPrice?.toLocaleString()})`}</p>
        </Box>
      </Box>

      <Box>
        {firstGentyoImageURL && (
          <Box
            className="relative flex h-12 w-9"
            onClick={() => {
              setIsGentyoImageDialogOpen(true);
            }}
          >
            <img
              src={firstGentyoImageURL}
              alt="noImage"
              className="h-full w-full object-contain"
            />
            {gentyoImageCount > 1 && (
              <div className="absolute bottom-0 right-0 bg-pink text-white text-sm px-1 rounded">
                {`+${gentyoImageCount - 1}`}
              </div>
            )}
          </Box>
        )}
        <GentyoImageDialog
          open={isGentyoImageDialogOpen}
          row={row}
          categoryId={categoryId}
          handleDialogClose={() => {
            setIsGentyoImageDialogOpen(false);
          }}
        />
      </Box>
    </Paper>
  );
};

type EstimateRowsProps = {
  categorizedEstimate: CategorizedEstimate;
};
export const EstimateRows = ({
  categorizedEstimate,
}: EstimateRowsProps): JSX.Element => {
  const categoryId = categorizedEstimate.categoryId;

  return (
    <>
      {categorizedEstimate.bizEstimateRows.map((bizEstimateRow) => {
        return (
          <Box key={bizEstimateRow.id}>
            {bizEstimateRow.ownerFixed ? (
              <FixedEstimateRow row={bizEstimateRow} categoryId={categoryId} />
            ) : (
              <EstimateRow row={bizEstimateRow} categoryId={categoryId} />
            )}
          </Box>
        );
      })}
    </>
  );
};
