import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { JOB_TYPES } from "constants/constants";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

type ChangeCategoryNameDialogProps = {
  open: boolean;
  handleDialogClose: () => void;
  categoryId: string;
  categoryName: string;
};
export const ChangeCategoryNameDialog = ({
  open,
  handleDialogClose,
  categoryId,
  categoryName,
}: ChangeCategoryNameDialogProps) => {
  const [editedCategoryName, setEditedCategoryName] = useState<string>("");
  const { setCategoryName } = useCategorizedEstimatesContext();

  useEffect(() => {
    if (open) {
      setEditedCategoryName(categoryName);
    }
  }, [open, categoryName]);

  const handleButtonClick = (): void => {
    setCategoryName(editedCategoryName, categoryId);
    handleDialogClose();
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle className="flex items-center justify-between">
        <p className="text-lg">大項目名の変更</p>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          value={editedCategoryName}
          freeSolo
          options={JOB_TYPES}
          onChange={(_, newValue) => {
            setEditedCategoryName(newValue || "");
          }}
          renderInput={(params) => (
            <TextField
              value={editedCategoryName}
              placeholder="クロス張替工事"
              onChange={(e) => setEditedCategoryName(e.target.value)}
              {...params}
            />
          )}
        />
      </DialogContent>

      <DialogActions className="justify-center">
        <Button
          variant="contained"
          className="w-1/2 my-2 bg-remodela-green"
          onClick={handleButtonClick}
        >
          変更
        </Button>
      </DialogActions>
    </Dialog>
  );
};
