import { useCallback, useEffect, useMemo, useState } from "react";
import { BizConstractorStore } from "../../../store/nanostores/contractorInfo";
import { useStore } from "@nanostores/react";
import { BuildingInfo, fetchBuildingInfoList } from "../useCases/fetchBuildingInfoList";
import { CheckedStatuses } from "../components/FilterMenu";
import { BizProjectStatus } from "@shared/constants";
import { getOwnerStatusesByBizStatuses } from "@shared/utils";

interface UseBuildingsProps {
  loading: boolean;
  filteredBuildingInfoList: BuildingInfo[];
  buildingNames: string[];
  filteredProjectCount: number;
  filterBuilding: (
    buildingName: string,
    checkedStatuses: CheckedStatuses,
    checkedFinalInspectionRequest: boolean
  ) => void;
}

export const useBuildingInfoList = (): UseBuildingsProps => {
  const [loading, setLoading] = useState<boolean>(true);
  const [buildingInfoList, setBuildingInfoList] = useState<BuildingInfo[]>([]);
  const [filteredBuildingInfoList, setFilteredBuildingInfoList] = useState<BuildingInfo[]>([]);

  const { bizContractorId } = useStore(BizConstractorStore.IDMap);

  /** 物件名一覧(フィルター用)*/
  const buildingNames: string[] = useMemo((): string[] => {
    const buildingNamesSet = new Set<string>([""]);
    for (const buildingInfo of buildingInfoList) {
      buildingNamesSet.add(buildingInfo.buildingName);
    }

    return Array.from(buildingNamesSet);
  }, [buildingInfoList]);

  /** フィルタ後の案件数*/
  const filteredProjectCount: number = useMemo((): number => {
    let count = 0;
    filteredBuildingInfoList.forEach((filteredBuildingInfo: BuildingInfo) => {
      count += filteredBuildingInfo.projectWithUsers.length;
    });
    return count;
  }, [filteredBuildingInfoList]);

  /** 選択されたステータスでフィルタリング */
  const filterBuilding = useCallback(
    (buildingName: string, checkedStatuses: CheckedStatuses, checkedFinalInspectionRequest: boolean): void => {
      let filteredBuildingInfoList: BuildingInfo[] = buildingInfoList;

      //　建物でフィルタ
      if (buildingName !== "") {
        filteredBuildingInfoList = buildingInfoList.filter(
          (buildingInfo) => buildingInfo.buildingName === buildingName
        );
      }

      // 退去立会代行依頼がある案件でフィルタ
      if (checkedFinalInspectionRequest) {
        filteredBuildingInfoList = filteredBuildingInfoList.map((filteredBuildingInfo) => {
          return {
            ...filteredBuildingInfo,
            projectWithUsers: filteredBuildingInfo.projectWithUsers.filter(
              (projectWithUser) => projectWithUser.needFinalInspection
            ),
          };
        });

        // 表示できるprojectが無い建物情報は削除
        filteredBuildingInfoList = filteredBuildingInfoList.filter(
          (filteredBuildingInfo) => filteredBuildingInfo.projectWithUsers.length !== 0
        );
      }

      // ステータスでフィルタ(全てoffならフィルタしない)
      const checkedStatusNames: BizProjectStatus[] = Object.entries(checkedStatuses)
        .filter(([key, value]) => value === true)
        .map(([key, value]) => key as BizProjectStatus);

      if (checkedStatusNames.length !== 0) {
        const ownerCheckedStatusNames = getOwnerStatusesByBizStatuses(checkedStatusNames);

        // ステータスの状態でprojectをフィルタ
        filteredBuildingInfoList = filteredBuildingInfoList.map((filteredBuildingInfo) => {
          return {
            ...filteredBuildingInfo,
            projectWithUsers: filteredBuildingInfo.projectWithUsers.filter((projectWithUser) =>
              ownerCheckedStatusNames.includes(projectWithUser.status)
            ),
          };
        });

        // 表示できるprojectが無い建物情報は削除
        filteredBuildingInfoList = filteredBuildingInfoList.filter(
          (filteredBuildingInfo) => filteredBuildingInfo.projectWithUsers.length !== 0
        );
      }

      setFilteredBuildingInfoList(filteredBuildingInfoList);
    },
    [buildingInfoList]
  );

  /** bizContractorIdがnanostoresにセット後、建物情報を取得*/
  useEffect(() => {
    const fetchAndSetBuildingInfoList = async (): Promise<void> => {
      if (!bizContractorId) {
        return;
      }

      const buildingInfoList = await fetchBuildingInfoList(bizContractorId);
      setBuildingInfoList(buildingInfoList);
      setFilteredBuildingInfoList(buildingInfoList);

      setLoading(false);
    };

    fetchAndSetBuildingInfoList();
  }, [bizContractorId]);

  return {
    loading,
    filteredBuildingInfoList,
    buildingNames,
    filteredProjectCount,
    filterBuilding,
  };
};
