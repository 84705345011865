import { useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface Step3Props {
  className?: string;
}

export const Step3 = (props: Step3Props): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box className={props.className}>
      <Box className="flex flex-col items-center sm:w-1/2 m-auto">
        <CheckCircleOutlineIcon className="text-remodela-green text-7xl" />
        <Box className="font-bold text-2xl tracking-widest py-3">会員登録完了</Box>
        <Box className="text-sm">
          会員登録していただきありがとうございます。ご本人様確認のためメールアドレス認証を実施しています｡お手数ではありますが登録時に記載いただいたメールアドレスをご確認ください。
        </Box>
        <Button
          variant="contained"
          className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12 my-3"
          onClick={() => navigate("/login")}
        >
          ログイン画面へ
        </Button>
      </Box>
    </Box>
  );
};
