import { Box, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from "@mui/material";
import { ja } from "date-fns/locale";
import { Timestamp } from "firebase/firestore";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  ValidityPeriodType,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

/**見積有効期限設定 */
export const EstimateValidityPeriod = (): JSX.Element => {
  const { validityPeriod, validityPeriodChangeRadio, validityPeriodSetCalendar, validityPeriodSetDays } =
    useCategorizedEstimatesContext();

  const validityPeriodType = validityPeriod.hasOwnProperty("days") ? "days" : "calendar";

  /**ラジオボタン変更 */
  const handleChangeRadio = (value: ValidityPeriodType): void => {
    validityPeriodChangeRadio(value);
  };

  /**カレンダー日付変更 */
  const handleChangeDatePicker = (newValue: Date | null): void => {
    if (!newValue) {
      return;
    }

    const isInvalidDate = Number.isNaN(newValue.getTime());
    if (!isInvalidDate) {
      validityPeriodSetCalendar(Timestamp.fromDate(newValue));
    }
  };

  /**日数入力 */
  const handleChangeDays = (value: string): void => {
    const days = Number(value);
    if (isNaN(days)) {
      return;
    }

    validityPeriodSetDays(days);
  };

  return (
    <Box className="flex items-center">
      <Box className="flex items-center mx-4">
        <p>見積有効期限設定：</p>
        <RadioGroup
          row
          value={validityPeriodType}
          onChange={(e) => handleChangeRadio(e.target.value as ValidityPeriodType)}
        >
          <FormControlLabel
            value="calendar"
            control={<Radio className="text-remodela-green" />}
            label="日付選択"
            labelPlacement="start"
          />
          <FormControlLabel
            value="days"
            control={<Radio className="text-remodela-green" />}
            label="日数を入力"
            labelPlacement="start"
          />
        </RadioGroup>
      </Box>

      {validityPeriodType === "calendar" && (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <DatePicker
            value={validityPeriod.calendar?.toDate()}
            onChange={handleChangeDatePicker}
            slotProps={{
              textField: {
                size: "small",
                className: "w-44",
              },
            }}
          />
        </LocalizationProvider>
      )}
      {validityPeriodType === "days" && (
        <TextField
          value={validityPeriod.days || ""}
          size="small"
          className="w-44"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">日</InputAdornment>,
          }}
          onChange={(e) => handleChangeDays(e.target.value)}
        />
      )}
    </Box>
  );
};
