import { useState } from "react";
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import KeyIcon from "@mui/icons-material/Key";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { MainLayout } from "components/Layout/MainLayout";
import { ContractorInfo } from "../components/ContractorInfo";
import { PasswordChange } from "../components/PasswordChange";
import { UserAdd } from "../components/UserAdd";

const menus = [
  {
    code: "contractorInfo",
    displayName: "企業情報",
    icon: <BusinessIcon />,
    component: <ContractorInfo />,
  },
  {
    code: "addUser",
    displayName: "ユーザー追加",
    icon: <PersonAddIcon />,
    component: <UserAdd />,
  },
  {
    code: "changePassword",
    displayName: "パスワード変更",
    icon: <KeyIcon />,
    component: <PasswordChange />,
  },
] as const;

export const Settings = (): JSX.Element => {
  const [selectedMenuCode, setSelectedMenuCode] = useState<string>("contractorInfo");

  const Component = menus.find((menu) => menu.code === selectedMenuCode)!.component;

  return (
    <MainLayout title="企業情報">
      <Box className="flex flex-col sm:flex-row items-start gap-5 p-4 sm:px-60 m-auto">
        <Paper className="w-full sm:w-80 sm:flex-shrink-0">
          <List component="nav">
            {menus.map((menu) => {
              return (
                <ListItemButton
                  key={menu.code}
                  selected={selectedMenuCode === menu.code}
                  onClick={() => setSelectedMenuCode(menu.code)}
                >
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.displayName} />
                </ListItemButton>
              );
            })}
          </List>
        </Paper>

        {Component}
      </Box>
    </MainLayout>
  );
};
