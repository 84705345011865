import { useEffect, useState } from "react";

import { Box, Button, Dialog, IconButton, Paper, Tab, Tabs, Tooltip } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";

import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { ImgType, Project } from "@shared/types/entities/owner/Project";
import { UpdateProjectByDeleteImageURL } from "@shared/types/functionParams";
import { FileUploadDialog } from "./FileUploadDialog";
import { updateProjectByDeleteImageURL } from "../../api/updateProjectByDeleteImageURL";
import { useStore } from "@nanostores/react";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { useBackdrop } from "context/backdropContext";

SwiperCore.use([Navigation]);

/**間取り図 */
type FloorPlanProps = {
  floorPlanImgURL: string | undefined | null;
  floorPlanImgType?: ImgType | null;
};
const FloorPlan = ({ floorPlanImgURL, floorPlanImgType }: FloorPlanProps): JSX.Element => {
  if (!floorPlanImgType) {
    return (
      <Box className="w-full h-full flex flex-col items-center justify-center bg-offWhite text-xs sm:text-base">
        <ApartmentIcon className="text-mediumGray text-7xl" />
        <Box>間取り図は設定されていません。</Box>
        <Box>
          <b>画像アップロード</b>より間取り図をアップロードしてください。
        </Box>
      </Box>
    );
  } else if (floorPlanImgType === "img") {
    return <img src={floorPlanImgURL!} alt="noImage" className="h-full w-full object-contain" />;
  } else {
    return <iframe src={floorPlanImgURL!} className="h-full w-full object-contain" title="floorPlan" />;
  }
};

/**工事写真のズーム*/
type ZoomImageProps = {
  zoomImageURL: string | undefined;
  deleteImage: (imageURL: string) => Promise<void>;
};
const ZoomImage = ({ zoomImageURL, deleteImage }: ZoomImageProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { setBackdrop } = useBackdrop();

  const deleteConfirmDialog = {
    isOpen: dialogOpen,
    open: () => {
      setDialogOpen(true);
    },
    close: () => {
      setDialogOpen(false);
    },
  };

  const handleDeleteImage = async (): Promise<void> => {
    setBackdrop(true);
    try {
      if (zoomImageURL) {
        await deleteImage(zoomImageURL);
      }
    } catch (e) {
      console.log(e);
    }
    setBackdrop(false);
    deleteConfirmDialog.close();
  };

  if (zoomImageURL) {
    return (
      <>
        <img src={zoomImageURL} alt="noImage" className="h-full w-full object-contain" />
        <Tooltip title="画像削除" placement="left-end">
          <IconButton className="absolute top-[10px] right-[10px] bg-mediumGray text-white w-5 h-5" onClick={deleteConfirmDialog.open}>
            <DeleteIcon className="w-4 h-4" />
          </IconButton>
        </Tooltip>

        <Dialog onClose={deleteConfirmDialog.close} open={deleteConfirmDialog.isOpen}>
          <Box className="flex flex-col items-center p-3">
            <WarningIcon className="text-5xl text-remodela-error" />
            <Box className="font-bold text-2xl">確認</Box>

            <Box className="flex flex-col items-center text-sm my-4">
              <Box>画像を削除します。よろしいですか？</Box>
              <Box>一度削除した場合元に戻すことはできません。</Box>
            </Box>

            <Box className="flex justify-around w-full">
              <Button
                variant="outlined"
                className="font-bold bg-white text-darkBlueGray border-2 border-mediumGray w-28"
                onClick={deleteConfirmDialog.close}
              >
                キャンセル
              </Button>
              <Button variant="contained" className="font-bold bg-remodela-error border-[1px] border-remodela-error w-28" onClick={handleDeleteImage}>
                削除する
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  }

  return (
    <Box className="w-full h-full flex flex-col items-center justify-center bg-offWhite text-xs sm:text-base">
      <ImageNotSupportedOutlinedIcon className="text-mediumGray text-7xl" />
      <Box>画像はありません。</Box>
      <Box>
        <b>画像アップロード</b>より工事写真をアップロードしてください。
      </Box>
    </Box>
  );
};

/**カルーセル*/
type SliderProps = {
  imageURLs?: string[];
  changeZoomImage: (zoomImageURL: string) => void;
};
const Slider = ({ imageURLs, changeZoomImage }: SliderProps) => {
  const handleClick = (imageURL: string): void => {
    changeZoomImage(imageURL);
  };

  if (!imageURLs) {
    return <></>;
  }

  return (
    <Box className="w-full h-12 sm:h-24">
      <Swiper
        slidesPerView={8}
        spaceBetween={5}
        navigation={{
          prevEl: "#button_prev",
          nextEl: "#button_next",
        }}
        className="h-full"
      >
        {imageURLs?.map((imageURL, index) => {
          return (
            <SwiperSlide key={index} className="h-10 sm:h-20 my-2">
              <Box
                className="cursor-pointer h-full w-full border-[1px] border-mediumGray mb-3"
                onClick={() => {
                  handleClick(imageURL);
                }}
              >
                <img src={imageURL} alt="" className="h-full w-full object-contain" />
              </Box>
            </SwiperSlide>
          );
        })}
        <Box id="button_prev" className="swiper-button-prev" />
        <Box id="button_next" className="swiper-button-next" />
      </Swiper>
    </Box>
  );
};

type ImageUploadAreaProps = { project: Project };
export const ImageUploadArea = ({ project }: ImageUploadAreaProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [zoomImageURL, setZoomImageURL] = useState<string>();
  const [imageURLs, setImageURLs] = useState<string[]>();
  const { userId } = useStore(BizConstractorStore.IDMap);

  useEffect(() => {
    if (tabIndex === 0) {
      setZoomImageURL(project.bizBeforeImageURLs?.[0]);
      setImageURLs(project.bizBeforeImageURLs);
    }
    if (tabIndex === 1) {
      setZoomImageURL(project.bizAfterImageURLs?.[0]);
      setImageURLs(project.bizAfterImageURLs);
    }
  }, [project, tabIndex]);

  const handleDialogOpen = (): void => {
    setDialogOpen(true);
  };
  const handleDialogClose = (): void => {
    setDialogOpen(false);
  };

  /**工事写真タブ切り替え*/
  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  /**ズーム画像の切り替え*/
  const changeZoomImage = (zoomImageURL: string) => {
    setZoomImageURL(zoomImageURL);
  };

  /**ズーム中の画像削除 */
  const deleteImage = async (imageURL: string): Promise<void> => {
    const params: UpdateProjectByDeleteImageURL.RequestParams = {
      deleteImageURL: imageURL,
      companyId: project.companyId,
      projectId: project.id!,
      bizUserId: userId,
      target: tabIndex === 0 ? "before" : "after",
    };

    await updateProjectByDeleteImageURL(params);
  };

  return (
    <Paper className="p-2">
      <Box className="flex justify-center">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: "#10692A",
            },
          }}
        >
          <Tab className="text-darkBlueGray sm:w-80" label="工事前写真" />
          <Tab className="text-darkBlueGray sm:w-80" label="工事後写真" />
        </Tabs>

        <Button variant="contained" className="bg-remodela-green font-bold text-xs sm:text-sm" onClick={handleDialogOpen}>
          画像アップロード
        </Button>

        <FileUploadDialog open={dialogOpen} companyId={project.companyId} projectId={project.id!} handleDialogClose={handleDialogClose} />
      </Box>

      <Box className="flex justify-center h-[15rem] sm:h-[30rem] py-2 sm:py-4">
        <Box className="w-1/2">
          <FloorPlan floorPlanImgURL={project.floorPlanImgUrl} floorPlanImgType={project.floorPlanImgType} />
        </Box>

        <Box className="w-1/2 relative">
          <ZoomImage zoomImageURL={zoomImageURL} deleteImage={deleteImage} />
        </Box>
      </Box>

      <Slider imageURLs={imageURLs} changeZoomImage={changeZoomImage} />
    </Paper>
  );
};
