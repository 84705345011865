import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { format } from "date-fns";
import { ownerStorage } from "lib/firebase";

type Result = {
  beforeImageURLs: string[];
  afterImageURLs: string[];
  floorPlanImgUrl: string;
};
export const saveBeforeAfterFloorPlanImages = async (
  companyId: string,
  projectId: string,
  beforeImages: File[],
  afterImages: File[],
  floorPlanImage?: File
): Promise<Result> => {
  const beforeImageURLs = await savebizImages(
    "beforeImages",
    companyId,
    projectId,
    beforeImages
  );

  const afterImageURLs = await savebizImages(
    "afterImages",
    companyId,
    projectId,
    afterImages
  );

  const floorPlanImgUrl = await saveFloorPlanImage(
    companyId,
    projectId,
    floorPlanImage
  );

  return { beforeImageURLs, afterImageURLs, floorPlanImgUrl };
};

/**beforeImagesまたはafterImagesをstorageに保存し、URLを返す*/
const savebizImages = async (
  imagesType: "beforeImages" | "afterImages",
  companyId: string,
  projectId: string,
  bizImages: File[]
): Promise<string[]> => {
  let bizImageURLs: string[] = [];

  if (bizImages.length === 0) {
    return bizImageURLs;
  }

  const dirPath = `biz/companies/${companyId}/projects/${projectId}/${imagesType}`;

  const now = format(new Date(), "yyyy-MM-dd_HH:mm:ss");

  for (const bizImage of bizImages) {
    const storageRef = ref(ownerStorage, `${dirPath}/${now}_${bizImage.name}`);

    await uploadBytes(storageRef, bizImage);

    const downloadURL = await getDownloadURL(storageRef);
    bizImageURLs = [...bizImageURLs, downloadURL];
  }

  return bizImageURLs;
};

/**FloorPlanImageをstorageに保存し、URLを返す*/
const saveFloorPlanImage = async (
  companyId: string,
  projectId: string,
  floorPlanImage?: File
): Promise<string> => {
  if (!floorPlanImage) {
    return "";
  }

  const storageRef = ref(
    ownerStorage,
    `companies/${companyId}/projects/${projectId}/floorPlanImg`
  );

  await uploadBytes(storageRef, floorPlanImage);

  return await getDownloadURL(storageRef);
};
