import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

export const AddCategory = (): JSX.Element => {
  const { addCategory } = useCategorizedEstimatesContext();

  return (
    <Box className="flex justify-end">
      <Box className="flex items-center cursor-pointer" onClick={() => addCategory("")}>
        <AddIcon className="text-3xl" />
        <Box className="font-bold text-sm">カテゴリーを追加する</Box>
      </Box>
    </Box>
  );
};
