import { useEffect, useState } from "react";
import { Box, Divider, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { getFormattedDate } from "utils";
import { FireTimestamp } from "utils/firebaseUtil";
import { User } from "@shared/types/entities/owner/User";
import { fetchUserByCompanyIdUserId } from "repositories/owner/User";
import { Project } from "repositories/owner/Project";
import { StatusCard } from "components/StatusCard/StatusCard";
import { ConstructionTypeCard } from "components/ConstructionTypeCard/ConstructionTypeCard";
import { FinalInspectionContent } from "./FinalInspectionContent";
import { DownloadEstimateExcel } from "./DownloadEstimateExcel";
import { DownloadOrderPDF } from "./DownloadOrderPDF";
import { DownloadInvoiceExcel } from "./DownloadInvoiceExcel";
import { FileShare } from "./FileShare";

interface Props {
  project: Project;
}
export const ProjectBasicInfo = ({ project }: Props): JSX.Element => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      const user = await fetchUserByCompanyIdUserId(project.companyId, project.ownerUid || "");
      setUser(user);
    };

    fetchUser();
  }, [project]);

  /**工事完了希望日 */
  const dueDate = (): FireTimestamp | undefined => {
    if (project.requestDueDate) {
      return project.requestDueDate;
    }

    if (project.dueDate) {
      return project.dueDate;
    }
  };

  return (
    <>
      <Paper>
        <Box className="p-2 font-bold">基本情報</Box>

        <Divider />

        <Box padding={1} className="text-sm">
          <Grid container alignItems="center" spacing={1.5}>
            <Grid xs={4}>ステータス</Grid>
            <Grid xs={8}>
              <Box className="max-w-[6rem]">
                <StatusCard status={project?.status!} />
              </Box>
            </Grid>

            <Grid xs={4}>工事タイプ</Grid>
            <Grid xs={8}>
              <ConstructionTypeCard constructionType={project.constructionType} />
            </Grid>

            <Grid xs={4}>案件番号</Grid>
            <Grid xs={8}>{project.no as unknown as number}</Grid>

            <Grid xs={4}>ご担当者</Grid>
            <Grid xs={8}>{project.pic}</Grid>

            <Grid xs={4}>電話番号</Grid>
            <Grid xs={8}>{user?.tel}</Grid>

            <Grid xs={4}>現地調査日</Grid>
            <Grid xs={8}>{getFormattedDate(project.gentyoDate)}</Grid>

            <Grid xs={4}>工事完了希望日</Grid>
            <Grid xs={8}>{getFormattedDate(dueDate())}</Grid>

            <Grid xs={4}>工事完了日</Grid>
            <Grid xs={8}>{getFormattedDate(project.constructionCompletedDate)}</Grid>

            <Grid xs={4}>エレベーターの有無</Grid>
            <Grid xs={8}>{project.existsElevator ? "あり" : "なし"}</Grid>

            <Grid xs={4}>オートロック</Grid>
            <Grid xs={8}>
              <Box>{project.isAutoLock ? "あり" : "なし"}</Box>
              {project.isAutoLock && <Box>{`解錠方法: ${project.autoLockNo}`}</Box>}
            </Grid>

            <Grid xs={4}>鍵の場所</Grid>
            <Grid xs={8}>{project.placeOfKey}</Grid>

            <Grid xs={4}>駐車場の場所</Grid>
            <Grid xs={8}>{project.placeOfParking}</Grid>

            <Grid xs={4}>退去立会</Grid>
            <Grid xs={8}>{project.needFinalInspection ? <FinalInspectionContent project={project} /> : <Box>依頼しない</Box>}</Grid>

            <Grid xs={4}>その他ご要望</Grid>
            <Grid xs={8}>
              <Box className="whitespace-pre-line">{project.request}</Box>
            </Grid>

            <Grid xs={4}>見積書</Grid>
            <Grid xs={8}>
              <DownloadEstimateExcel project={project} />
            </Grid>

            <Grid xs={4}>発注書</Grid>
            <Grid xs={8}>
              <DownloadOrderPDF project={project} />
            </Grid>

            <Grid xs={4}>請求書</Grid>
            <Grid xs={8}>
              <DownloadInvoiceExcel project={project} />
            </Grid>

            <Grid xs={4}>関連資料</Grid>
            <Grid xs={8}>
              <FileShare project={project} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};
