import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { UpdateBizContractorByAction } from "@shared/types/functionParams";

export async function updateBizContractorByAction(
  requestParams: UpdateBizContractorByAction.RequestParams
): Promise<void> {
  const func = httpsCallable(bizFunctions, "onCallUpdateBizContractorByAction");
  await func(requestParams);
}
