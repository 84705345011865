import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

import { PublicLayout } from "components/Layout/PublicLayout";
import { RegisterFormProvider } from "features/auth/context/RegisterFormContext";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";

export const RegisterForm = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();

  const showStep1 = activeStep === 0;
  const showStep2 = activeStep === 1;
  const showStep3 = activeStep === 2;

  const handleNextStep = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBackStep = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  return (
    <PublicLayout title="新規会員登録">
      <Box className="w-11/12 sm:w-2/3 m-auto pt-10">
        <Box className="flex justify-center w-2/3 m-auto">
          <Box className="w-[200px] h-8 relative cursor-pointer" onClick={() => navigate("/")}>
            <img
              src={`${process.env.PUBLIC_URL}/images/remodelaBiz.png`}
              alt="リモデラBiz"
              className="w-full h-full object-cover absolute top-0 left-0"
            />
          </Box>
        </Box>

        <Box className="w-2/3 m-auto">
          <Box className="text-2xl font-bold text-center py-8">新規会員登録</Box>
          <Stepper activeStep={activeStep} alternativeLabel className="mb-8">
            {["企業情報登録", "登録内容確認", "登録完了"].map((label) => {
              return (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      className: "text-remodela-green",
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        <Box className="pb-10">
          <RegisterFormProvider>
            <Step1 handleNextStep={handleNextStep} className={!showStep1 ? "hidden" : ""} />

            <Step2
              handleBackStep={handleBackStep}
              handleNextStep={handleNextStep}
              className={!showStep2 ? "hidden" : ""}
            />

            <Step3 className={!showStep3 ? "hidden" : ""} />
          </RegisterFormProvider>
        </Box>
      </Box>
    </PublicLayout>
  );
};
