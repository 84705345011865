import { useEffect, useState } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { BizProjectStatus, BIZ_STATUSES } from "@shared/constants";

export type CheckedStatuses = {
  [key in BizProjectStatus]: boolean;
};

const initCheckedStatuses: CheckedStatuses = {
  受付中: false,
  見積中: false,
  提案中: false,
  工事中: false,
  検収待ち: false,
  完了: false,
  取下げ: false,
  期限切れ: false,
};

interface FilterMenuProps {
  buildingNames: string[];
  filterBuilding: (
    buildingName: string,
    checkedStatuses: CheckedStatuses,
    checkedFinalInspectionRequest: boolean
  ) => void;
}
export const FilterMenu = ({ buildingNames, filterBuilding }: FilterMenuProps) => {
  const [selectedBuildingName, setSelectedBuildingName] = useState<string>("");
  const [checkedStatuses, setCheckedStatuses] = useState<CheckedStatuses>(initCheckedStatuses);
  const [checkedFinalInspectionRequest, setCheckedFinalInspectionRequest] = useState<boolean>(false);

  /** 建物orステータスの選択がされた時に実行*/
  useEffect(() => {
    // filterBuildingが定義されたときにも実行される。
    filterBuilding(selectedBuildingName, checkedStatuses, checkedFinalInspectionRequest);
  }, [selectedBuildingName, checkedStatuses, checkedFinalInspectionRequest, filterBuilding]);

  return (
    <>
      <Box className="mb-6">
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <BuildingNameSelector buildingNames={buildingNames} setSelectedBuildingName={setSelectedBuildingName} />
          </Grid>
          <Grid xs={12} md={6}>
            <StatusSelector checkedStatuses={checkedStatuses} setCheckedStatuses={setCheckedStatuses} />
          </Grid>
          <Grid xs={12} md={3}>
            <FinalInspectionSelector
              checkedFinalInspectionRequest={checkedFinalInspectionRequest}
              setCheckedFinalInspectionRequest={setCheckedFinalInspectionRequest}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

interface StatusSelectorProps {
  checkedStatuses: CheckedStatuses;
  setCheckedStatuses: (checkedStatuses: CheckedStatuses) => void;
}
const StatusSelector = ({ checkedStatuses, setCheckedStatuses }: StatusSelectorProps): JSX.Element => {
  /** ステータス選択*/
  const handleChangeCheckedStatus = (name: string, checked: boolean): void => {
    setCheckedStatuses({
      ...checkedStatuses,
      [name]: checked,
    });
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">ステータス</FormLabel>
      <FormGroup row>
        {BIZ_STATUSES.map((bizStatus) => {
          return (
            <FormControlLabel
              key={bizStatus}
              control={
                <Checkbox
                  className="text-remodela-green"
                  checked={checkedStatuses[bizStatus]}
                  onChange={(event) => handleChangeCheckedStatus(event.target.name, event.target.checked)}
                  name={bizStatus}
                />
              }
              label={bizStatus}
              sx={{
                minWidth: {
                  xs: "110px",
                  md: "unset",
                },
              }}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

interface BuildingNameSelectorProps {
  buildingNames: string[];
  setSelectedBuildingName: (buildingName: string) => void;
}
const BuildingNameSelector = ({ buildingNames, setSelectedBuildingName }: BuildingNameSelectorProps): JSX.Element => {
  return (
    <FormControl component="fieldset" className="w-full">
      <FormLabel component="legend">建物名</FormLabel>
      <FormGroup row>
        <TextField
          size="small"
          select
          defaultValue=""
          fullWidth
          onChange={(event) => {
            setSelectedBuildingName(event.target.value);
          }}
        >
          {buildingNames.map((buildingName) => (
            <MenuItem key={buildingName} value={buildingName}>
              {buildingName ? buildingName : <em>未選択</em>}
            </MenuItem>
          ))}
        </TextField>
      </FormGroup>
    </FormControl>
  );
};

interface FinalInspectionSelectorProps {
  checkedFinalInspectionRequest: boolean;
  setCheckedFinalInspectionRequest: (checkedFinalInspectionRequest: boolean) => void;
}
const FinalInspectionSelector = ({
  checkedFinalInspectionRequest,
  setCheckedFinalInspectionRequest,
}: FinalInspectionSelectorProps): JSX.Element => {
  return (
    <FormControl component="fieldset" className="w-full">
      <FormLabel component="legend">退去立会代行依頼</FormLabel>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              className="text-remodela-green"
              checked={checkedFinalInspectionRequest}
              onChange={(event) => setCheckedFinalInspectionRequest(event.target.checked)}
            />
          }
          label="有り"
          sx={{
            minWidth: {
              xs: "110px",
              md: "unset",
            },
          }}
        />
      </FormGroup>
    </FormControl>
  );
};
