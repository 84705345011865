import { Backdrop, CircularProgress } from "@mui/material";
import { createContext, ReactNode, useContext, useState } from "react";

interface BackdropContextType {
  setBackdrop: (open: boolean) => void;
}

const BackdropContext = createContext<BackdropContextType>({} as BackdropContextType);
export const useBackdrop = (): BackdropContextType => {
  return useContext(BackdropContext);
};

interface BackdropProviderProps {
  children: ReactNode;
}
export const BackdropProvider = ({ children }: BackdropProviderProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const setBackdrop = (open: boolean): void => {
    setOpen(open);
  };

  return (
    <BackdropContext.Provider value={{ setBackdrop }}>
      {open && (
        <Backdrop open={open} className="z-[9999]">
          <CircularProgress size="5rem" className="text-remodela-green" />
        </Backdrop>
      )}

      {children}
    </BackdropContext.Provider>
  );
};
