import { ChangeEvent, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  TextField,
  MenuItem,
  Button,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { JOB_TYPES, JobType, Prefs } from "constants/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "context/snackbarContext";
import {
  RegisterFormValues,
  schema,
  defaultValues,
  useRegisterFormContext,
} from "features/auth/context/RegisterFormContext";

// TODO: 料金表と電子印鑑のファイルサイズが大きくなるとファイルアップできなくなる問題がある。

interface Step1Props {
  className?: string;
  handleNextStep: () => void;
}
export const Step1 = (props: Step1Props): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const priceListFileInputRef = useRef<HTMLInputElement>(null);
  const digitalStampFileInputRef = useRef<HTMLInputElement>(null);

  const { updateFormData } = useRegisterFormContext();

  const { showSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<RegisterFormValues>({ resolver: zodResolver(schema), defaultValues });

  const selectedJobTypes = watch("selectedJobTypes");
  const priceList = watch("priceList");
  const digitalStamp = watch("digitalStamp");

  /**職種選択 */
  const handleClickSelectJobType = (jobType: JobType): void => {
    if (selectedJobTypes.includes(jobType)) {
      const filteredJobTypes = selectedJobTypes.filter((selectedJobType) => selectedJobType !== jobType);
      setValue("selectedJobTypes", filteredJobTypes);
      return;
    }

    if (jobType === "内装工事全般") {
      const updated = [jobType];
      setValue("selectedJobTypes", updated);
      return;
    }

    // [内装工事全般]以外が選ばれた場合は[内装工事全般]を除く
    const filteredJobTypes = selectedJobTypes.filter((selectedJobType) => selectedJobType !== "内装工事全般");
    const updated = [...filteredJobTypes, jobType];
    setValue("selectedJobTypes", updated);
  };

  /**料金表 */
  const handlePriceListFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const validTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!validTypes.includes(file.type)) {
      showSnackbar("不正なファイル形式です。", "error");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setValue("priceList", {
        base64: reader.result as string,
        fileName: file.name,
        contentType: file.type,
      });
    };
  };

  /**電子印鑑 */
  const handleDigitalStampFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setValue("digitalStamp", {
        base64: reader.result as string,
        fileName: file.name,
        contentType: file.type,
      });
    };
  };

  const onSubmit = async (data: RegisterFormValues): Promise<void> => {
    updateFormData(data);
    props.handleNextStep();
  };

  return (
    <Box className={props.className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="text-sm">以下のフォームに会員登録に必要な事項を入力してください。</p>
        <Box>
          <Box className="flex py-5 items-center">
            <CircleIcon fontSize="medium" className="text-remodela-green" />
            <Box className="font-bold text-xl">パスワード設定</Box>
          </Box>

          <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
            <Box className="mb-4">
              <ItemName required itemName="担当者名" />
              <Box className="flex">
                <TextField
                  {...register("staffLastName")}
                  size="small"
                  fullWidth
                  placeholder="田中"
                  error={!!errors.staffLastName}
                  helperText={errors.staffLastName ? errors.staffLastName.message : ""}
                />
                <TextField
                  {...register("staffFirstName")}
                  size="small"
                  fullWidth
                  placeholder="次郎"
                  error={!!errors.staffFirstName}
                  helperText={errors.staffFirstName ? errors.staffFirstName.message : ""}
                />
              </Box>
            </Box>
            <Box className="mb-4">
              <ItemName required itemName="メールアドレス" />
              <TextField
                {...register("email")}
                type="mail"
                fullWidth
                size="small"
                placeholder="test@remodela.jp"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            </Box>
            <Box className="mb-4">
              <ItemName
                required
                itemName="パスワード"
                description="パスワードは6文字以上、半角英数字で入力してください。"
              />
              <TextField
                {...register("password")}
                type={showPassword ? "text" : "password"}
                fullWidth
                size="small"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Paper>
        </Box>

        <Box>
          <Box className="flex py-5 items-center">
            <CircleIcon fontSize="medium" className="text-remodela-green" />
            <Box className="font-bold text-xl">企業情報登録</Box>
          </Box>

          <Paper elevation={0} className="w-full border-[1px] border-remodela-green py-8 px-2 sm:px-28">
            <Box className="mb-4">
              <ItemName required itemName="企業名（屋号）" description="企業名または屋号をご入力ください。" />
              <TextField
                {...register("contractorName")}
                size="small"
                fullWidth
                placeholder="リモデラ工務店"
                error={!!errors.contractorName}
                helperText={errors.contractorName ? errors.contractorName.message : ""}
                className="pl-4"
              />
            </Box>

            <Box className="mb-4">
              <ItemName required itemName="代表者名" />
              <Box className="flex pl-4">
                <TextField
                  {...register("representativeLastName")}
                  size="small"
                  fullWidth
                  placeholder="田中"
                  error={!!errors.representativeLastName}
                  helperText={errors.representativeLastName ? errors.representativeLastName.message : ""}
                />
                <TextField
                  {...register("representativeFirstName")}
                  size="small"
                  fullWidth
                  placeholder="太郎"
                  error={!!errors.representativeFirstName}
                  helperText={errors.representativeFirstName ? errors.representativeFirstName.message : ""}
                />
              </Box>
            </Box>

            <Box className="mb-4">
              <ItemName required itemName="会社所在地" />
              <Box className="mb-4 pl-4">
                <Box className="font-bold text-sm">都道府県</Box>

                <TextField
                  {...register("prefecture")}
                  size="small"
                  select
                  defaultValue=""
                  error={!!errors.prefecture}
                  helperText={errors.prefecture ? errors.prefecture.message : ""}
                  className="w-1/2"
                >
                  {Prefs.PREFECTURES.map((prefecture) => (
                    <MenuItem key={prefecture} value={prefecture}>
                      {prefecture}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box className="mb-4 pl-4">
                <Box className="font-bold text-sm">市区町村</Box>

                <TextField
                  {...register("city")}
                  size="small"
                  fullWidth
                  placeholder="千代田区"
                  error={!!errors.city}
                  helperText={errors.city ? errors.city.message : ""}
                />
              </Box>

              <Box className="mb-4 pl-4">
                <Box className="font-bold text-sm">番地・建物名</Box>
                <TextField
                  {...register("address")}
                  size="small"
                  fullWidth
                  placeholder="丸の内1-1 リモデラビル2F"
                  error={!!errors.address}
                  helperText={errors.address ? errors.address.message : ""}
                />
              </Box>
            </Box>

            <Box className="mb-4">
              <ItemName required itemName="電話番号" description="ハイフン無しで入力してください。" />
              <TextField
                {...register("tel")}
                size="small"
                placeholder="0667664210"
                error={!!errors.tel}
                helperText={errors.tel ? errors.tel.message : ""}
                className="w-1/2 pl-4"
              />
            </Box>

            <Box className="mb-4">
              <ItemName required itemName="職種" />
              <Box className="flex flex-wrap text-xs">
                {JOB_TYPES.map((jobType) => {
                  return (
                    <Box
                      key={jobType}
                      className={`px-2 py-1 mx-2 my-1 rounded-lg cursor-pointer border-[1px] border-remodela-green ${
                        selectedJobTypes.includes(jobType) ? "bg-remodela-green text-white" : "text-remodela-green"
                      }`}
                      onClick={() => handleClickSelectJobType(jobType)}
                    >
                      {jobType}
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box className="mb-4">
              <ItemName required itemName="新規取引先" />
              <Controller
                name="isFindingNewClients"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    className="pl-4"
                    control={<Checkbox size="small" className="text-remodela-green" {...field} />}
                    label="新規取引先を探したい"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              />
              <p className="pl-4 text-sm text-pink">
                ※チェックを入れると、不動産会社から取引開始のオファーが届くようになります
              </p>
            </Box>

            <Box className="mb-4">
              <ItemName required itemName="退去立会" />
              <Controller
                name="needFinalInspection"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    className="pl-4"
                    control={<Checkbox size="small" className="text-remodela-green" {...field} />}
                    label="退去立会も対応できる"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              />
            </Box>

            <Box className="mb-4">
              <ItemName itemName="料金表" />
              <Box className="pl-4">
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  ref={priceListFileInputRef}
                  onChange={handlePriceListFileChange}
                  className="hidden"
                />

                <Box className="flex items-center">
                  {priceList && (
                    <Box className="relative h-60 w-60 mr-3">
                      {priceList.contentType.includes("image") ? (
                        <img src={priceList.base64} alt="プレビュー" className="h-full w-full object-contain" />
                      ) : (
                        <embed type="application/pdf" src={priceList.base64} className="h-full w-full object-contain" />
                      )}

                      <IconButton
                        className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                        onClick={() => setValue("priceList", null)}
                      >
                        <DeleteIcon className="w-4 h-4" />
                      </IconButton>
                    </Box>
                  )}
                  <Button
                    variant="outlined"
                    className="text-remodela-green border-remodela-green rounded-xl font-bold h-8"
                    onClick={() => priceListFileInputRef.current?.click()}
                  >
                    ファイルを選択
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box className="mb-4">
              <ItemName itemName="建設業許可番号" />
              <TextField {...register("constructionLicenseId")} size="small" className="w-1/2 pl-4" />
            </Box>

            <Box className="mb-4">
              <ItemName itemName="登録番号" description="インボイス制度の適格請求書登録番号をご入力ください。" />
              <TextField
                {...register("invoiceNumber")}
                size="small"
                placeholder="1234567890123"
                InputProps={{
                  startAdornment: <InputAdornment position="start">T</InputAdornment>,
                }}
                className="w-1/2 pl-4"
                error={!!errors.invoiceNumber}
                helperText={errors.invoiceNumber ? errors.invoiceNumber.message : ""}
              />
            </Box>

            <Box className="mb-4">
              <ItemName itemName="電子印鑑" />
              <Box className="pl-4">
                <input
                  type="file"
                  accept="image/*"
                  ref={digitalStampFileInputRef}
                  onChange={handleDigitalStampFileChange}
                  className="hidden"
                />

                <Box className="flex items-center">
                  {digitalStamp && (
                    <Box className="relative h-20 w-20 mr-3">
                      <img src={digitalStamp.base64} alt="プレビュー" className="h-full w-full object-contain" />
                      <IconButton
                        className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                        onClick={() => setValue("digitalStamp", null)}
                      >
                        <DeleteIcon className="w-4 h-4" />
                      </IconButton>
                    </Box>
                  )}
                  <Button
                    variant="outlined"
                    className="text-remodela-green border-remodela-green rounded-xl font-bold h-8"
                    onClick={() => digitalStampFileInputRef.current?.click()}
                  >
                    ファイルを選択
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>

        <Box className="flex justify-center mt-10">
          <Button
            type="submit"
            variant="contained"
            className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12"
          >
            次に進む
          </Button>
        </Box>
      </form>
    </Box>
  );
};

interface ItemNameProps {
  itemName: string;
  required?: boolean;
  description?: string;
}
const ItemName = ({ itemName, required, description }: ItemNameProps): JSX.Element => {
  return (
    <>
      <Box className="flex items-center mb-2">
        {required ? (
          <Box className="flex justify-center items-center font-bold rounded-md text-white bg-remodela-error text-xs w-8 h-6 mr-1">
            必須
          </Box>
        ) : (
          <Box className="flex justify-center items-center font-bold rounded-md text-white bg-mediumGray text-xs w-8 h-6 mr-1">
            任意
          </Box>
        )}

        <Box className="font-bold text-sm">{itemName}</Box>
      </Box>
      {description && <Box className="text-sm">{description}</Box>}
    </>
  );
};
