import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import Big, { RoundingMode } from "big.js";

import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

type DiscountDialogProps = {
  open: boolean;
  handleDialogClose: () => void;
  total: number;
  subTotal: number;
};

export const DiscountDialog = ({ open, handleDialogClose, total, subTotal }: DiscountDialogProps): JSX.Element => {
  const [discountedTotal, setDiscountedTotal] = useState<number>(total);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const { editDiscountCategory, taxType } = useCategorizedEstimatesContext();

  // 値引き行追加
  const handleAddDiscountCategory = (): void => {
    editDiscountCategory(discountAmount);
    handleDialogClose();
  };

  // 値引額反映
  const handleInputChange = (value: string): void => {
    const discountedTotal = Number(value);
    if (isNaN(discountedTotal)) {
      return;
    }

    // 値引き後合計
    setDiscountedTotal(discountedTotal);

    if (taxType === "zero") {
      // 値引額 = 値引き後合計 - 値引き前小計
      const discountAmount = discountedTotal - subTotal;
      setDiscountAmount(discountAmount);
      return;
    }

    // 値引き後の小計に対して消費税の[切上げ/切捨て]が発生するので、
    // 値引き金額は消費税とは逆の[切上げ/切捨て]を行う必要がある
    let roundingMode: RoundingMode = 0;
    if (taxType === "roundDown") {
      roundingMode = Big.roundUp;
    } else if (taxType === "roundUp") {
      roundingMode = Big.roundDown;
    }

    const discountedSubTotal = Big(discountedTotal).div(1.1).round(0, roundingMode).toNumber();

    // 値引額 = 値引き後小計 - 値引き前小計
    const discountAmount = discountedSubTotal - subTotal;
    setDiscountAmount(discountAmount);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle className="flex items-center justify-between">
        <p className="text-lg">お値引きの設定</p>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container rowSpacing={2}>
          <Grid xs={5}>
            <p>値引き前合計</p>
          </Grid>
          <Grid xs={7}>
            <p>{`¥${total.toLocaleString()}`}</p>
          </Grid>
          <Grid xs={5}>
            <p>値引き額</p>
          </Grid>
          <Grid xs={7}>
            <p className="text-remodela-error">{`¥${discountAmount.toLocaleString()}`}</p>
          </Grid>
          <Grid xs={5}>
            <p className="leading-10">値引き後合計</p>
          </Grid>
          <Grid xs={7}>
            <TextField
              value={discountedTotal || ""}
              onChange={(e) => handleInputChange(e.target.value)}
              type="number"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">¥</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className="justify-center">
        <Button variant="contained" className="w-1/2 bg-remodela-green" onClick={handleAddDiscountCategory}>
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
};
