import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Timestamp } from "firebase/firestore";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";

import {
  ValidityPeriodType,
  useCategorizedEstimatesContext,
} from "features/estimates/context/categorizedEstimatesContext";

type ValidityPeriodDialogProps = {
  open: boolean;
  handleDialogClose: () => void;
  handleSave: () => void;
};
/**見積有効期限設定ダイアログ */
export const ValidityPeriodDialog = ({
  open,
  handleDialogClose,
  handleSave,
}: ValidityPeriodDialogProps): JSX.Element => {
  const {
    validityPeriod,
    validityPeriodChangeRadio,
    validityPeriodSetCalendar,
    validityPeriodSetDays,
  } = useCategorizedEstimatesContext();

  const validityPeriodType = validityPeriod.hasOwnProperty("days")
    ? "days"
    : "calendar";

  /**ラジオボタン切り替え */
  const handleChangeRadio = (value: ValidityPeriodType): void => {
    validityPeriodChangeRadio(value);
  };

  /**カレンダー日付変更 */
  const handleChangeDatePicker = (newValue: Date | null) => {
    if (!newValue) return;

    const isInvalidDate = Number.isNaN(newValue.getTime());
    if (!isInvalidDate) {
      validityPeriodSetCalendar(Timestamp.fromDate(newValue));
    }
  };

  /**日数入力 */
  const handleChangeDays = (value: string): void => {
    const days = Number(value);
    if (isNaN(days)) {
      return;
    }

    validityPeriodSetDays(days);
  };

  /**保存ボタン押下 */
  const handleSaveClick = (): void => {
    handleDialogClose();
    handleSave();
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle className="flex items-center justify-between">
        <p className="text-lg">見積有効期限の設定</p>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box className="flex items-center">
          <Box className="w-32">
            <RadioGroup
              value={validityPeriodType}
              onChange={(e) =>
                handleChangeRadio(e.target.value as ValidityPeriodType)
              }
            >
              <FormControlLabel
                value="calendar"
                control={<Radio className="text-remodela-green" />}
                label="日付を選択"
                className="m-0"
              />
              <FormControlLabel
                value="days"
                control={<Radio className="text-remodela-green" />}
                label="日数を入力"
                className="m-0"
              />
            </RadioGroup>
          </Box>

          <Box className="w-32 ml-4">
            {validityPeriodType === "calendar" && (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
              >
                <DatePicker
                  value={validityPeriod.calendar?.toDate()}
                  onChange={handleChangeDatePicker}
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </LocalizationProvider>
            )}

            {validityPeriodType === "days" && (
              <TextField
                value={validityPeriod.days || ""}
                size="small"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">日</InputAdornment>
                  ),
                }}
                onChange={(e) => handleChangeDays(e.target.value)}
              />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions className="justify-center">
        <Button
          variant="contained"
          className="w-1/2 bg-remodela-green"
          onClick={handleSaveClick}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};
