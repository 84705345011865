import { useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, IconButton, InputAdornment, Paper, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useStore } from "@nanostores/react";

import { BizConstractorStore, BizUserStore } from "store/nanostores/contractorInfo";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { CreateBizUser } from "@shared/types/functionParams";
import { createBizUSer } from "../api/createBizUser";
import { FirebaseError } from "firebase/app";

const schema = z.object({
  lastName: z.string().min(1, { message: "必須入力です" }),
  firstName: z.string().min(1, { message: "必須入力です" }),
  email: z.string().min(1, { message: "必須入力です" }).email({ message: "有効なメールアドレスを入力してください" }),
  password: z
    .string()
    .min(1, { message: "必須入力です" })
    .min(6, { message: "6文字以上で入力してください" })
    .regex(/^[0-9a-zA-Z]*$/, { message: "半角英数字のみ使用できます" }),
});

type FormValues = z.infer<typeof schema>;

const defaultValues: FormValues = {
  lastName: "",
  firstName: "",
  email: "",
  password: "",
};

export const UserAdd = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();
  const { bizContractorId } = useStore(BizConstractorStore.IDMap);
  const { lastName, firstName } = useStore(BizUserStore.bizUser);

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormValues>({ resolver: zodResolver(schema), defaultValues });

  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show);
  };

  const onSubmit = async (data: FormValues): Promise<void> => {
    setBackdrop(true);

    try {
      const createBizUserRequestParams: CreateBizUser.RequestParams = {
        bizContractorId,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        fromUserName: `${lastName} ${firstName}`,
      };

      await createBizUSer(createBizUserRequestParams);
      showSnackbar("ユーザーを追加しました。", "success");
    } catch (e) {
      if (e instanceof FirebaseError && e.code === "functions/already-exists") {
        showSnackbar("このメールアドレスは既に登録されています。", "error");
        return;
      }

      showSnackbar("ユーザーの追加に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <>
      <Paper className="p-4 w-full">
        <Box className="font-bold text-xl mb-4">ユーザー追加</Box>
        <p className="text-sm text-remodela-error mb-4">
          必要情報を入力して送信ボタンを押すと本人確認メールが届きます。
          <br />
          パスワードはメールに記載されませんので、対象者に安全な方法で伝達をお願いします。
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="mb-4">
            <ItemName required itemName="氏名" />
            <Box className="flex">
              <TextField
                {...register("lastName")}
                fullWidth
                size="small"
                placeholder="田中"
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ""}
              />
              <TextField
                {...register("firstName")}
                fullWidth
                size="small"
                placeholder="次郎"
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ""}
              />
            </Box>
          </Box>

          <Box className="mb-4">
            <ItemName required itemName="メールアドレス" />
            <TextField
              {...register("email")}
              type="mail"
              fullWidth
              size="small"
              placeholder="test@remodela.jp"
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
          </Box>

          <Box className="mb-4">
            <ItemName
              required
              itemName="パスワード"
              description="パスワードは6文字以上、半角英数字で入力してください。"
            />

            <TextField
              {...register("password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              size="small"
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box className="flex justify-center">
            <Button type="submit" variant="contained" className="bg-remodela-green font-bold rounded-lg w-40 h-12 mx-3">
              登録
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

interface ItemNameProps {
  itemName: string;
  required?: boolean;
  description?: string;
}
const ItemName = ({ itemName, required, description }: ItemNameProps): JSX.Element => {
  return (
    <>
      <Box className="flex items-center mb-2">
        <Box
          className={`flex justify-center items-center font-bold rounded-md text-white text-xs w-8 h-6 mr-1
          ${required ? "bg-remodela-error" : "bg-mediumGray"}`}
        >
          {required ? "必須" : "任意"}
        </Box>
        <Box className="font-bold text-sm">{itemName}</Box>
      </Box>

      {description && <Box className="text-sm">{description}</Box>}
    </>
  );
};
