import { Box } from "@mui/material";

import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";
import { CategoryCard } from "./MainContent/CategoryCard";
import { AddCategory } from "./MainContent/AddCategory";
import { DiscountCard } from "./MainContent/DiscountCard";
import { AddDiscount } from "./MainContent/AddDiscount";

export const MainContent = (): JSX.Element => {
  const { categorizedEstimates } = useCategorizedEstimatesContext();

  return (
    <Box className="m-2 mb-32">
      {categorizedEstimates.map((categorizedEstimate) => (
        <CategoryCard key={categorizedEstimate.categoryId} categorizedEstimate={categorizedEstimate} />
      ))}

      <AddCategory />

      <AddDiscount />

      <DiscountCard />
    </Box>
  );
};
