import { Status } from "@shared/types/entities/owner/Project";

const classNames = new Map<Status, string>([
  ["", ""],
  ["下書き中", ""],
  ["オファー中", ""],
  ["点検中", ""],
  ["受付中", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-white bg-status-受付中"],
  ["見積中", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-white bg-status-見積中"],
  ["オファー可能", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-white bg-status-提案中"],
  ["工事中", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-darkBlueGray bg-status-工事中"],
  ["検収待ち", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-darkBlueGray bg-status-検収待ち"],
  ["完了", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-white bg-status-完了"],
  ["取下げ", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-darkBlueGray bg-status-取下げ"],
  ["期限切れ", "text-xs w-16 text-center sm:text-sm sm:p-0 sm:w-auto rounded-lg text-white bg-status-期限切れ"],
]);

type Props = {
  status: Status;
};

export const StatusCard = ({ status }: Props): JSX.Element => {
  return <div className={classNames.get(status)}>{status === "オファー可能" ? "提案中" : status}</div>;
};
