import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { ownerDB } from "lib/firebase";
import { BizEstimate } from "@shared/types/entities/owner/BizEstimate";
import { toEntityFromDoc } from "utils/firebaseUtil";

export const getBizEstimateByCompanyIdProjectId = async (
  companyId: string,
  projectId: string
): Promise<BizEstimate[]> => {
  const q = query(
    collection(ownerDB, `companies/${companyId}/projects/${projectId}/bizEstimates`),
    orderBy("categoryNo", "asc"),
    orderBy("rowNo", "asc")
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => toEntityFromDoc<BizEstimate>(doc));
};
