import { collection, doc, getDoc, getDocs } from "firebase/firestore";

import { User } from "@shared/types/entities/owner/User";
import { ownerDB } from "lib/firebase";
import { toEntityFromDoc } from "utils/firebaseUtil";

export async function fetchUserByCompanyIdUserId(companyId: string, userId: string): Promise<User> {
  const docRef = doc(ownerDB, `companies/${companyId}/users/${userId}`);
  const docSnapshot = await getDoc(docRef);

  if (!docSnapshot.exists()) {
    return {} as User;
  }

  return toEntityFromDoc<User>(docSnapshot);
}

/**ユーザー一覧を取得 */
export async function fetchUsersByCompanyId(companyId: string): Promise<User[]> {
  const collectionQuery = collection(ownerDB, `companies/${companyId}/users`);
  const querySnapshot = await getDocs(collectionQuery);

  const users = querySnapshot.docs.map((queryDocSnapshot) => {
    return toEntityFromDoc<User>(queryDocSnapshot);
  });

  return users;
}
