import { useRef } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { JOB_TYPES } from "constants/constants";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

type AddCategoryDialogProps = {
  open: boolean;
  handleDialogClose: () => void;
};
export const AddCategoryDialog = ({
  open,
  handleDialogClose,
}: AddCategoryDialogProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addCategory } = useCategorizedEstimatesContext();

  const handleButtonClick = (): void => {
    const value = inputRef?.current?.value;
    if (!value) {
      return;
    }

    addCategory(value);
    handleDialogClose();
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle className="flex items-center justify-between">
        <p className="text-lg">大項目の追加</p>
        <IconButton onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Autocomplete
          freeSolo
          options={JOB_TYPES}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="クロス張替工事"
              inputRef={inputRef}
            />
          )}
        />
      </DialogContent>

      <DialogActions className="justify-center">
        <Button
          variant="contained"
          className="w-1/2 my-2 bg-remodela-green"
          onClick={handleButtonClick}
        >
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
};
