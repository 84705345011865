import { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import { useAuth } from "context/authContext";
import { Buildings } from "../features/buildings/pages/Buildings";
import { CreateProject } from "../features/createProject/pages/CreateProject";
import { Estimates } from "../features/estimates/pages/Estimates";
import { LoginForm } from "../features/auth/pages/LoginForm";
import { NotFound } from "../features/misc/pages/NotFound";
import { ProjectDetail } from "features/projectDetail/pages/ProjectDetail";
import { RegisterPage } from "../features/auth/pages/RegisterPage";
import { ResetPassword } from "features/auth/pages/ResetPassword";
import { Settings } from "features/settings/pages/Settings";
import { InviteExpired } from "features/misc/pages/InviteExpired";
import { SpotWorker } from "features/spotWorker/pages/SpotWorker";

const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [pathname]);

  return null;
};

const PrivateAuthGuard = (): JSX.Element => {
  const { currentUser } = useAuth();
  return currentUser ? <Outlet /> : <Navigate to="/login" replace />;
};

const PublicAuthGuard = (): JSX.Element => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/" replace /> : <Outlet />;
};

const Loading = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Box className="flex items-center h-screen justify-center">
            <CircularProgress size="5rem" className="text-remodela-green" />
          </Box>
        }
      />
    </Routes>
  );
};

export const AppRoutes = () => {
  const { authChecked, ownerLoginChecked } = useAuth();

  if (!authChecked || !ownerLoginChecked) {
    return <Loading />;
  }

  return (
    <>
      <ScrollToTop />

      <Routes>
        <Route element={<PrivateAuthGuard />}>
          <Route path="/" element={<Buildings />} />
          <Route path="/create-project" element={<CreateProject />} />
          <Route path="/spot-worker" element={<SpotWorker />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/companies/:companyId/projects/:projectId" element={<ProjectDetail />} />
          <Route path="/companies/:companyId/projects/:projectId/estimates" element={<Estimates />} />
        </Route>
        <Route element={<PublicAuthGuard />}>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/invite-expired" element={<InviteExpired />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
