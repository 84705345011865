import { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "context/authContext";
import { SnackbarProvider } from "context/snackbarContext";
import { BackdropProvider } from "context/backdropContext";
import { ErrorBoundary } from "react-error-boundary";
import { Box, Button } from "@mui/material";

type AppProviderProps = {
  children: ReactNode;
};

const ErrorFallback = (): JSX.Element => {
  return (
    <Box className="text-red-500 w-screen h-screen flex flex-col justify-center items-center" role="alert">
      <h2 className="text-lg font-semibold">エラーが発生しました。</h2>
      <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
        画面更新
      </Button>
    </Box>
  );
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <BackdropProvider>
          <SnackbarProvider>
            <AuthProvider>
              <BrowserRouter>{children}</BrowserRouter>
            </AuthProvider>
          </SnackbarProvider>
        </BackdropProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
