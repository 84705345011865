import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { CreateBizContractorAndUser } from "@shared/types/functionParams";

type RequestParams = CreateBizContractorAndUser.RequestParams;
type ResponseParams = CreateBizContractorAndUser.ResponseParams;

export async function createBizContractorAndUser(requestParams: RequestParams): Promise<ResponseParams> {
  const func = httpsCallable(bizFunctions, "onCallCreateBizContractorAndUser");
  const result = await func(requestParams);

  return result.data as ResponseParams;
}
