import {
  Box,
  Card,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";
import { OwnerCommentTooltip } from "./OwnerCommentTooltip/OwnerCommentTooltip";
import { CustomTableHeaderRow } from "./CustomTableHeaderRow/CustomTableHeaderRow";

export const DiscountCard = (): JSX.Element => {
  const {
    discountEstimate,
    deleteDiscountCategory,
    isAddOnEnabled,
    changeDiscountLandlordShareRate,
    getDiscountLandlordAmount,
    getDiscountTenantAmount,
  } = useCategorizedEstimatesContext();

  const getTableRowClassName = (): string => {
    if (discountEstimate?.ownerComments && discountEstimate?.ownerComments.length > 0) {
      return "bg-remodela-warmOrange bg-opacity-10 border-b-2 border-b-remodela-warmOrange";
    }

    return "";
  };

  if (!discountEstimate) {
    return <></>;
  }

  return (
    <>
      <Card elevation={3} className="p-3 my-6 bg-remodela-error/[.1]">
        <Box className="mb-4 flex justify-between items-center">
          <Box className="flex items-center text-lg font-bold text-remodela-error">
            <ErrorIcon />
            <Box className="ml-1">{discountEstimate.categoryName}</Box>
          </Box>

          {!discountEstimate.ownerFixed && (
            <Box>
              <IconButton
                onClick={() => {
                  deleteDiscountCategory();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box className="w-full overflow-auto">
          <TableContainer component={Paper} className="min-w-[1400px]">
            <Table aria-label="customized table">
              <TableHead>
                <CustomTableHeaderRow />
              </TableHead>
              <TableBody>
                <TableRow className={getTableRowClassName()}>
                  {/* ハンドル */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[50px] w-[50px]" />

                  {/* 現調写真 */}
                  <TableCell className="relative text-center text-sm h-16 py-1 px-1 min-w-[65px] w-[65px]" />

                  {/* 項目 */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[290px] w-[290px]">
                    {discountEstimate.itemName}
                  </TableCell>

                  {/* 数量 */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[70px] w-[70px]">
                    {discountEstimate.quantity}
                  </TableCell>

                  {/* 単位 */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[70px] w-[70px]">
                    {discountEstimate.unit}
                  </TableCell>

                  {/* 単価 */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[80px] w-[80px]">
                    {`¥${discountEstimate.unitPrice.toLocaleString()}`}
                  </TableCell>

                  {/* 金額 */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[100px] w-[100px]">
                    {`¥${discountEstimate.amount.toLocaleString()}`}
                  </TableCell>

                  {/* 賃貸人負担 */}
                  {isAddOnEnabled && (
                    <TableCell padding="none" className="text-center text-sm h-16 py-1 px-1 min-w-[100px] w-[100px]">
                      <Box className="flex items-center justify-center">
                        <TextField
                          defaultValue={discountEstimate.shareRate.landlord}
                          variant="outlined"
                          onChange={(e) => changeDiscountLandlordShareRate(e.target.value)}
                          size="small"
                          className="w-[40px] h-7"
                          InputProps={{
                            sx: {
                              "& .MuiInputBase-input": {
                                padding: "5px",
                                textAlign: "right",
                              },
                              backgroundColor: "white",
                              fontSize: "12px",
                            },
                          }}
                        />
                        <Box>%</Box>
                      </Box>
                      <Box className="text-sm break-all">{`(¥${getDiscountLandlordAmount().toLocaleString()})`}</Box>
                    </TableCell>
                  )}

                  {/* 賃借人負担 */}
                  {isAddOnEnabled && (
                    <TableCell padding="none" className="text-center text-sm h-16 py-1 px-1 min-w-[100px] w-[100px]">
                      <Box className="text-sm break-all h-7 flex items-center justify-center">{`${discountEstimate.shareRate.tenant}%`}</Box>
                      <Box className="text-sm break-all">{`(¥${getDiscountTenantAmount().toLocaleString()})`}</Box>
                    </TableCell>
                  )}

                  {/* 紹介手数料 */}
                  {isAddOnEnabled && (
                    <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[100px] w-[100px]" />
                  )}

                  {/* 備考 */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[275px] w-[275px]">
                    {discountEstimate.note}
                  </TableCell>

                  {/* コメント */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[50px] w-[50px]">
                    {discountEstimate.ownerComments && discountEstimate.ownerComments.length > 0 && (
                      <OwnerCommentTooltip ownerComments={discountEstimate.ownerComments} />
                    )}
                  </TableCell>

                  {/* 削除 */}
                  <TableCell className="text-center text-sm h-16 py-1 px-1 min-w-[50px] w-[50px]" />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
    </>
  );
};
