import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useForm } from "react-hook-form";
import { useStore } from "@nanostores/react";

import { JOB_TYPES, JobType, Prefs } from "constants/constants";
import { fetchBizContractorByContractorId } from "repositories/owner/BizContractor";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { BizContractor } from "@shared/types/entities/owner/BizContractor";
import { UpdateBizContractor } from "@shared/types/functionParams";
import { updateBizContractor } from "../api/updateBizContractor";
import { useSnackbar } from "context/snackbarContext";
import { useBackdrop } from "context/backdropContext";

type ItemNameProps = {
  itemName: string;
  required?: boolean;
  description?: string;
};
const ItemName = ({ itemName, required, description }: ItemNameProps): JSX.Element => {
  return (
    <>
      <Box className="flex items-center mb-2">
        <div
          className={`flex justify-center items-center font-bold rounded-md text-white text-xs w-8 h-6 mr-1
          ${required ? "bg-remodela-error" : "bg-mediumGray"}`}
        >
          {required ? "必須" : "任意"}
        </div>
        <div className="font-bold text-sm">{itemName}</div>
      </Box>
      {description && <div className="text-sm">{description}</div>}
    </>
  );
};

type FormData = {
  contractorName: string;
  representativeLastName: string;
  representativeFirstName: string;
  prefecture: string;
  city: string;
  address: string;
  tel: string;
  constructionLicenseId: string;
  invoiceNumber: number;

  isFindingNewClients: boolean;
  needFinalInspection: boolean;
};

type DigitalStampPreview = {
  base64: string;
  fileName: string;
  contentType: string;
};
type PriceListPreview = {
  base64: string;
  fileName: string;
  contentType: string;
};
export const ContractorInfo = (): JSX.Element => {
  const [bizContractor, setBizContractor] = useState<BizContractor>();
  const [digitalStampPreview, setDigitalStampPreview] = useState<DigitalStampPreview>();
  const [priceListPreview, setPriceListPreview] = useState<PriceListPreview>();
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const priceListFileInputRef = useRef<HTMLInputElement>(null);

  const { showSnackbar } = useSnackbar();
  const { setBackdrop } = useBackdrop();
  const { bizContractorId } = useStore(BizConstractorStore.IDMap);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    (async () => {
      const bizContractor = await fetchBizContractorByContractorId(bizContractorId);
      setBizContractor(bizContractor);
      setSelectedJobTypes(bizContractor.jobTypes);
    })();
  }, [bizContractorId]);

  if (!bizContractor) {
    return <></>;
  }

  /**職種選択 */
  const handleClickSelectJobType = (jobType: JobType): void => {
    if (selectedJobTypes.includes(jobType)) {
      const filteredJobTypes = selectedJobTypes.filter((selectedJobType) => selectedJobType !== jobType);
      setSelectedJobTypes(filteredJobTypes);
      return;
    }

    if (jobType === "内装工事全般") {
      setSelectedJobTypes([jobType]);
    } else {
      // [内装工事全般]以外が選ばれた場合は[内装工事全般]を除く
      const filteredJobTypes = selectedJobTypes.filter((selectedJobType) => selectedJobType !== "内装工事全般");
      setSelectedJobTypes([...filteredJobTypes, jobType]);
    }
  };

  /**電子印鑑関連 */
  const digitalStamp = {
    isDisplayPreview: !!digitalStampPreview,
    isDisplaySavedDigitalStamp: !!bizContractor.digitalStampURL && !digitalStampPreview,
    handleFileSelectButtonClick: (): void => {
      fileInputRef.current?.click();
    },
    handleDeleteDigitalStampFile: (): void => {
      setDigitalStampPreview(undefined);
    },
    handleFileChange: (event: ChangeEvent<HTMLInputElement>): void => {
      if (!event.target.files) return;

      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const imageUrl = reader.result as string;
          setDigitalStampPreview({
            base64: imageUrl,
            fileName: file.name,
            contentType: file.type,
          });
        };
      }
    },
  };

  const priceListPDF = {
    /**料金表プレビューが存在するかどうか */
    isDisplayPreview: !!priceListPreview,
    /**料金表プレビューが存在しない、かつ登録済み料金表ファイルがあるかどうか */
    isDisplaySavedPriceList: !!bizContractor.priceList?.URL && !priceListPreview,
    handleFileSelectButtonClick: (): void => {
      priceListFileInputRef.current?.click();
    },
    handleDeleteFile: (): void => {
      setPriceListPreview(undefined);
    },
    handleFileChange: (event: ChangeEvent<HTMLInputElement>): void => {
      if (!event.target.files) return;

      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const validTypes = ["application/pdf", "image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        showSnackbar("不正なファイル形式です。", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        setPriceListPreview({
          base64: imageUrl,
          fileName: file.name,
          contentType: file.type,
        });
      };
    },
  };

  const onSubmit = async (formData: FormData): Promise<void> => {
    setBackdrop(true);
    try {
      const updateBizContractorRequestParams: UpdateBizContractor.RequestParams = {
        bizContractorId,
        /**企業情報(必須) */
        contractorName: formData.contractorName,
        representativeLastName: formData.representativeLastName,
        representativeFirstName: formData.representativeFirstName,
        prefecture: formData.prefecture,
        city: formData.city,
        address: formData.address,
        tel: formData.tel,
        isFindingNewClients: formData.isFindingNewClients,
        jobTypes: selectedJobTypes,
        needFinalInspection: formData.needFinalInspection,

        /**企業情報(任意) */
        priceListPreview: priceListPreview,
        constructionLicenseId: formData.constructionLicenseId,
        invoiceId: formData.invoiceNumber ? `T${formData.invoiceNumber}` : "",
        digitalStampPreview: digitalStampPreview,
      };
      await updateBizContractor(updateBizContractorRequestParams);

      showSnackbar("更新が完了しました。", "success");
    } catch (e) {
      console.log(e);
      showSnackbar("更新エラーが発生しました。", "error");
    }
    setBackdrop(false);
  };

  return (
    <>
      <Paper className="p-4 w-full">
        <Box className="font-bold text-xl mb-4">企業情報</Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box className="mb-4">
              <ItemName required itemName="企業名（屋号）" description="企業名または屋号をご入力ください。" />
              <TextField
                size="small"
                fullWidth
                defaultValue={bizContractor.name}
                inputProps={register("contractorName", {
                  required: "必須入力です",
                })}
                error={!!errors.contractorName}
                helperText={errors.contractorName?.message}
              />
            </Box>
            <Box className="mb-4">
              <ItemName required itemName="代表者名" />
              <div className="flex">
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={bizContractor.representativeLastName}
                  {...register("representativeLastName", {
                    required: "必須入力です",
                  })}
                  error={!!errors.representativeLastName}
                  helperText={errors.representativeLastName?.message}
                />
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={bizContractor.representativeFirstName}
                  {...register("representativeFirstName", {
                    required: "必須入力です",
                  })}
                  error={!!errors.representativeFirstName}
                  helperText={errors.representativeFirstName?.message}
                />
              </div>
            </Box>
            <Box className="mb-4">
              <ItemName required itemName="会社所在地" />
              <Box className="mb-4">
                <div className="font-bold text-sm">都道府県</div>
                <TextField
                  size="small"
                  select
                  defaultValue={bizContractor.prefecture}
                  className="w-1/2"
                  inputProps={register("prefecture", {
                    required: "必須入力です",
                  })}
                  error={!!errors.prefecture}
                  helperText={errors.prefecture?.message}
                >
                  {Prefs.PREFECTURES.map((prefecture) => (
                    <MenuItem key={prefecture} value={prefecture}>
                      {prefecture}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box className="mb-4">
                <div className="font-bold text-sm">市区町村</div>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="千代田区"
                  defaultValue={bizContractor.city}
                  inputProps={register("city", {
                    required: "必須入力です",
                  })}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                />
              </Box>

              <Box className="mb-4">
                <div className="font-bold text-sm">番地・建物名</div>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="丸の内1-1 リモデラビル2F"
                  defaultValue={bizContractor.address}
                  inputProps={register("address", {
                    required: "必須入力です",
                  })}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              </Box>
            </Box>
            <Box className="mb-4">
              <ItemName required itemName="電話番号" />
              <TextField
                size="small"
                placeholder="0667664210"
                className="w-1/2"
                defaultValue={bizContractor.tel}
                inputProps={register("tel", {
                  pattern: /^0\d{9,10}$/,
                  required: "必須入力です",
                })}
                error={!!errors.tel}
                helperText={(() => {
                  if (errors.tel?.type === "required") {
                    return errors.tel?.message;
                  }
                  if (errors.tel?.type === "pattern") {
                    return "電話番号を入力してください";
                  }
                  return "";
                })()}
              />
            </Box>
            <Box className="mb-4">
              <ItemName required itemName="職種" />
              <Box className="flex flex-wrap text-xs">
                {JOB_TYPES.map((jobType) => {
                  return (
                    <div
                      key={jobType}
                      className={`px-2 py-1 mx-2 my-1 rounded-lg cursor-pointer border-[1px] border-remodela-green ${
                        selectedJobTypes.includes(jobType) ? "bg-remodela-green text-white" : "text-remodela-green"
                      }`}
                      onClick={() => handleClickSelectJobType(jobType)}
                    >
                      {jobType}
                    </div>
                  );
                })}
              </Box>
            </Box>
            <Box className="mb-4">
              <ItemName required itemName="新規取引先" />
              <Controller
                name="isFindingNewClients"
                control={control}
                defaultValue={bizContractor.isFindingNewClients}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        className="text-remodela-green"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="新規取引先を探したい"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              />
              <p className="text-sm text-pink">
                ※チェックを入れると、不動産会社から取引開始のオファーが届くようになります
              </p>
            </Box>
            <Box className="mb-4">
              <ItemName required itemName="退去立会" />
              <Controller
                name="needFinalInspection"
                control={control}
                defaultValue={bizContractor.needFinalInspection}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        className="text-remodela-green"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="退去立会も対応できる"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Box className="mb-4">
              <ItemName itemName="料金表" />
              <Box>
                <input
                  type="file"
                  accept="application/pdf,image/*"
                  ref={priceListFileInputRef}
                  onChange={priceListPDF.handleFileChange}
                  className="hidden"
                />

                <Box className="flex items-center">
                  {priceListPDF.isDisplaySavedPriceList && (
                    <Box className="relative h-60 w-60 mr-3">
                      {bizContractor.priceList!.type === "img" ? (
                        <img
                          src={bizContractor.priceList!.URL}
                          alt="プレビュー"
                          className="h-full w-full object-contain"
                        />
                      ) : (
                        <embed
                          type="application/pdf"
                          src={bizContractor.priceList!.URL}
                          className="h-full w-full object-contain"
                        />
                      )}
                    </Box>
                  )}
                  {priceListPDF.isDisplayPreview && (
                    <Box className="relative h-60 w-60 mr-3">
                      {priceListPreview!.contentType.includes("image") ? (
                        <img src={priceListPreview!.base64} alt="プレビュー" className="h-full w-full object-contain" />
                      ) : (
                        <embed
                          type="application/pdf"
                          src={priceListPreview!.base64}
                          className="h-full w-full object-contain"
                        />
                      )}
                      <IconButton
                        className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                        onClick={priceListPDF.handleDeleteFile}
                      >
                        <DeleteIcon className="w-4 h-4" />
                      </IconButton>
                    </Box>
                  )}
                  <Button
                    variant="outlined"
                    className="text-remodela-green border-remodela-green rounded-xl font-bold h-8"
                    onClick={priceListPDF.handleFileSelectButtonClick}
                  >
                    ファイルを選択
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box className="mb-4">
              <ItemName itemName="建設業許可番号" />
              <TextField
                size="small"
                className="w-1/2"
                defaultValue={bizContractor.constructionLicenseId}
                inputProps={register("constructionLicenseId")}
              />
            </Box>
            <Box className="mb-4">
              <ItemName itemName="登録番号" description="インボイス制度の適格請求書登録番号をご入力ください。" />
              <TextField
                size="small"
                placeholder="1234567890123"
                className="w-1/2"
                InputProps={{
                  startAdornment: <InputAdornment position="start">T</InputAdornment>,
                }}
                defaultValue={bizContractor.invoiceId.replace("T", "")}
                inputProps={register("invoiceNumber", {
                  pattern: /[0-9]/,
                  maxLength: 13,
                  minLength: 13,
                })}
                error={!!errors.invoiceNumber}
                helperText={(() => {
                  if (errors.invoiceNumber?.type === "maxLength" || errors.invoiceNumber?.type === "minLength") {
                    return "13桁を入力してください";
                  }
                  if (errors.invoiceNumber?.type === "pattern") {
                    return "半角数字を入力してください";
                  }
                  return "";
                })()}
              />
            </Box>
            <Box className="mb-4">
              <ItemName itemName="電子印鑑" />
              <Box>
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={digitalStamp.handleFileChange}
                  className="hidden"
                />

                <Box className="flex items-center">
                  {digitalStamp.isDisplaySavedDigitalStamp && (
                    <Box className="relative h-20 w-20 mr-3">
                      <img
                        src={bizContractor.digitalStampURL}
                        alt="電子印鑑"
                        className="h-full w-full object-contain"
                      />
                    </Box>
                  )}
                  {digitalStamp.isDisplayPreview && (
                    <Box className="relative h-20 w-20 mr-3">
                      <img
                        src={digitalStampPreview?.base64}
                        alt="プレビュー"
                        className="h-full w-full object-contain"
                      />
                      <IconButton
                        className="absolute top-[-8px] right-[-8px] bg-mediumGray text-white w-5 h-5"
                        onClick={digitalStamp.handleDeleteDigitalStampFile}
                      >
                        <DeleteIcon className="w-4 h-4" />
                      </IconButton>
                    </Box>
                  )}
                  <Button
                    variant="outlined"
                    className="text-remodela-green border-remodela-green rounded-xl font-bold h-8"
                    onClick={digitalStamp.handleFileSelectButtonClick}
                  >
                    ファイルを選択
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="flex">
            <Button
              type="submit"
              variant="contained"
              className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12 m-auto"
            >
              更新する
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};
