import { DocumentSnapshot, QueryDocumentSnapshot, Timestamp } from "firebase/firestore";

export type FireTimestamp = Timestamp;

export function toEntityFromDoc<T extends { id?: string }>(snapshot: DocumentSnapshot | QueryDocumentSnapshot): T {
  if (!snapshot.exists()) {
    return {} as T;
  }

  const entity: T = snapshot.data() as T;
  entity.id = snapshot.id;

  return entity;
}
