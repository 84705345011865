import { TableCell, TableRow } from "@mui/material";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

export const tableColumn = {
  dragHandler: { name: "", width: 50, isRequiredAddOn: false },
  gentyoImage: { name: "現調写真", width: 65, isRequiredAddOn: false },
  itemName: { name: "項目", width: 290, isRequiredAddOn: false },
  quantity: { name: "数量", width: 70, isRequiredAddOn: false },
  unit: { name: "単位", width: 70, isRequiredAddOn: false },
  unitPrice: { name: "単価", width: 80, isRequiredAddOn: false },
  amount: { name: "金額", width: 100, isRequiredAddOn: false },

  landlordShareRate: { name: "賃貸人負担", width: 100, isRequiredAddOn: true },
  tenantShareRate: { name: "賃借人負担", width: 100, isRequiredAddOn: true },
  referralFeeRate: { name: "紹介手数料", width: 100, isRequiredAddOn: true },

  note: { name: "備考", width: 275, isRequiredAddOn: false },
  comment: { name: "", width: 50, isRequiredAddOn: false },
  delete: { name: "", width: 50, isRequiredAddOn: false },
};

export const CustomTableHeaderRow = (): JSX.Element => {
  const { isAddOnEnabled } = useCategorizedEstimatesContext();

  return (
    <TableRow>
      {Object.entries(tableColumn).map(([key, value]) =>
        // 「利益・負担割合管理アドオンが無効」かつ「利益・負担割合管理アドオン必須項目」の場合は表示しない
        !isAddOnEnabled && value.isRequiredAddOn ? null : (
          <TableCell
            key={key}
            padding="none"
            className={`py-1 bg-lightGray text-darkGray text-center text-sm min-w-[${value.width}px] w-[${value.width}px]`}
          >
            {value.name}
          </TableCell>
        )
      )}
    </TableRow>
  );
};
