import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { UpdateProjectByDeleteImageURL } from "@shared/types/functionParams";

export async function updateProjectByDeleteImageURL(
  requestParams: UpdateProjectByDeleteImageURL.RequestParams
): Promise<void> {
  const func = httpsCallable(bizFunctions, "onCallUpdateProjectByDeleteImageURL");
  await func(requestParams);
}
