import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Divider, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

export const Header = (): JSX.Element => {
  const { companyId, projectId } = useParams();
  const { hasEstimates, save, project } = useCategorizedEstimatesContext();
  const navigate = useNavigate();
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  const handleSaveProjectAndEstimates = async (): Promise<void> => {
    setBackdrop(true);

    try {
      await save();

      if (project.status === "工事中") {
        showSnackbar(`見積変更を${project.companyName}様に報告しました。`, "success");
      } else {
        showSnackbar("見積を登録しました。", "success");
      }

      navigate(`/companies/${companyId}/projects/${projectId}`);
    } catch (e) {
      showSnackbar("見積の登録に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <>
      <Box className="flex items-center justify-between p-4">
        <Box className="flex items-center">
          <IconButton
            onClick={() => {
              navigate(`/companies/${companyId}/projects/${projectId}`);
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <p className="text-xl px-3 font-medium tracking-wide">{hasEstimates ? "見積編集" : "見積作成"}</p>
        </Box>

        <Button
          variant="contained"
          className="font-bold bg-remodela-green w-40 ml-4"
          onClick={handleSaveProjectAndEstimates}
        >
          保存する
        </Button>
      </Box>

      <Divider />
    </>
  );
};
