import { Box, Divider } from "@mui/material";
import { TotalAmount } from "./SubHeader/TotalAmount";
import { EstimateValidityPeriod } from "./SubHeader/EstimateValidityPeriod";

export const SubHeader = (): JSX.Element => {
  return (
    <Box className="sticky top-0 z-10 bg-white">
      <Box className="flex items-center justify-between py-4 px-6">
        <TotalAmount />
        <EstimateValidityPeriod />
      </Box>
      <Divider />
    </Box>
  );
};
