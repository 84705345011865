import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { CreateProject } from "@shared/types/functionParams";

export type RequestParams = CreateProject.RequestParams;

export async function createProject(requestParams: RequestParams): Promise<void> {
  const func = httpsCallable(bizFunctions, "onCallCreateProject");
  await func(requestParams);
}
