import { Timestamp } from "firebase/firestore";
import { useStore } from "@nanostores/react";
import { useState, createContext, useContext, ReactNode, useEffect } from "react";
import Big from "big.js";

import { saveBizEstimates } from "../api/saveBizEstimates";
import { updateBizEstimatesNotification } from "../api/updateBizEstimatesNotification";
import { createBizInvoiceExcel } from "../api/createBizInvoiceExcel";
import { createBizEstimateExcel } from "../api/createBizEstimateExcel";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { BizEstimate, GentyoImage, OwnerComment } from "@shared/types/entities/owner/BizEstimate";
import { uploadGentyoImageToStorage } from "../useCases/storage/gentyoImages";
import { BizEstimateValidityPeriod, Project, TaxType } from "@shared/types/entities/owner/Project";
import { TAX_RATE } from "@shared/constants";
import { FireTimestamp } from "utils/firebaseUtil";
import { isProfitManagementAddOnEnabled } from "../../../api/isProfitManagementAddOnEnabled";

const defaultShareRate = {
  landlord: 100,
  tenant: 0,
};

interface Context {
  categorizedEstimates: CategorizedEstimate[];
  discountEstimate?: BizEstimate;
  validityPeriod: BizEstimateValidityPeriod;
  taxType: TaxType;
  project: Project;
  hasEstimates: boolean;
  /**利益・負担割合管理アドオンが有効か */
  isAddOnEnabled: boolean;

  /**消費税設定の変更(PC版のみ) */
  changeTaxType: (taxType: TaxType) => void;

  /**項目内容の変更 */
  changeItemName: (categoryId: string, rowNo: number, value: string) => void;
  /**数量の変更 */
  changeQuantity: (categoryId: string, rowNo: number, value: string) => void;
  /**単位の変更 */
  changeUnit: (categoryId: string, rowNo: number, value: string) => void;
  /**単価の変更 */
  changeUnitPrice: (categoryId: string, rowNo: number, value: string) => void;
  /**備考の変更 */
  changeNote: (categoryId: string, rowNo: number, value: string) => void;

  /**行の削除 */
  deleteRow(categoryId: string, rowNo: number): void;
  /**行の追加 */
  addRow(categoryId: string): void;
  /**行の入れ替え(PC版のみ) */
  changeRow: (categoryId: string, changedBizEstimateRows: BizEstimateRow[]) => void;

  /**現調写真の追加 */
  addGentyoImages: (categoryId: string, rowNo: number, pendingGentyoImages: PendingGentyoImage[]) => void;
  /**現調写真の削除 */
  deleteGentyoImage: (categoryId: string, rowNo: number, URL: string) => void;
  /**現調写真の1枚目のURL(pending写真) */
  getFirstGentyoImageURL: (row: BizEstimateRow) => string | undefined;
  /**現調写真の枚数(pending写真) */
  getGentyoImageCount: (row: BizEstimateRow) => number;
  /**現調写真(pending写真) */
  getGentyoImages: (row: BizEstimateRow) => PendingGentyoImage[];

  /**カテゴリー(大項目)の追加 */
  addCategory: (categoryName: string) => void;
  /**カテゴリー(大項目)の削除 */
  deleteCategory: (categoryId: string) => void;
  /**カテゴリー名(大項目)の変更  */
  setCategoryName: (categoryName: string, categoryId: string) => void;

  /**小計 */
  getSubTotal: () => number;
  /**消費税 */
  getTax: () => number;
  /**合計金額(小計 + 消費税) */
  getTotal: () => number;

  /**お値引きカテゴリーの[追加]か[更新] */
  editDiscountCategory: (discountAmoount: number) => void;
  /**お値引きカテゴリーの削除 */
  deleteDiscountCategory: () => void;

  /**対象のカテゴリーで1つでも施主承認があるか */
  existsOwnerFixedByCategoryId: (categoryId: string) => boolean;

  /**見積有効期限のラジオボタン切り替え */
  validityPeriodChangeRadio: (validityPeriodType: ValidityPeriodType) => void;
  /**有効期限カレンダー日付適用 */
  validityPeriodSetCalendar: (calendar: FireTimestamp) => void;
  /**有効期限日数適用 */
  validityPeriodSetDays: (days: number) => void;

  // 賃貸人
  /**賃貸人負担割合の変更 */
  changeLandlordShareRate: (categoryId: string, rowNo: number, value: string) => void;
  /**賃貸人負担割合の変更(お値引き) */
  changeDiscountLandlordShareRate: (value: string) => void;
  /**賃貸人負担金額 */
  getLandlordAmount: (categoryId: string, rowNo: number) => number;
  /**賃貸人負担金額(お値引き) */
  getDiscountLandlordAmount: () => number;
  /**賃貸人負担合計金額 */
  getLandlordTotal: () => number;

  // 賃借人
  /**賃借人負担金額 */
  getTenantAmount: (categoryId: string, rowNo: number) => number;
  /**賃借人負担金額(お値引き) */
  getDiscountTenantAmount: () => number;
  /**賃借人負担合計金額 */
  getTenantTotal: () => number;

  /**紹介手数料率の変更 */
  changeReferralFeeRate: (categoryId: string, rowNo: number, value: string) => void;
  /**紹介手数料金額 */
  getReferralFeeAmount: (categoryId: string, rowNo: number) => number;
  /**紹介手数料の合計金額 */
  getReferralFeeTotal: () => number;

  /**見積の保存 */
  save: () => Promise<void>;
}

export type ValidityPeriodType = "calendar" | "days";

/**保留中現調写真データ */
export interface PendingGentyoImage {
  file?: File;
  fileName: string;
  URL: string;
  isRegistered: boolean;
}

export interface BizEstimateRow {
  id: string;
  rowNo: number;
  gentyoImages?: GentyoImage[];
  itemName?: string;
  quantity?: number;
  unit?: string;
  unitPrice?: number;
  amount?: number;
  note?: string;
  ownerComments?: OwnerComment[];
  ownerFixed?: boolean;
  pendingGentyoImages?: PendingGentyoImage[]; // データの一時保管領域(画面表示させるためにURLで持つ必要がある)

  /**負担割合 */
  shareRate: {
    /**賃貸人負担割合 */
    landlord: number;
    /**賃借人負担割合 */
    tenant: number;
  };

  /**紹介手数料 */
  referralFeeRate: number;
}

export interface CategorizedEstimate {
  categoryId: string;
  categoryNo: number;
  categoryName: string;
  fixed: boolean;
  jobTypeId?: string;
  bizEstimateRows: BizEstimateRow[];
}

/**お値引き行の初期データ */
function getDefaultDiscountEstimate(discountAmoount: number): BizEstimate {
  return {
    categoryNo: 9999,
    categoryName: "お値引き",
    jobTypeId: "",
    rowNo: 1,
    gentyoImages: [],
    itemName: "お値引き",
    quantity: 1,
    unit: "式",
    unitPrice: discountAmoount,
    amount: discountAmoount,
    note: "",
    ownerComments: [],
    ownerFixed: false,
    shareRate: defaultShareRate,
    referralFeeRate: 0,
  };
}

/**見積行の初期データ */
function getDefaultBizEstimateRow(rowNo: number = 1): BizEstimateRow {
  return {
    id: crypto.randomUUID(),
    rowNo,
    shareRate: defaultShareRate,
    referralFeeRate: 0,
  };
}

/**大項目(カテゴリー)の初期データ */
function getDefaultCategorizedEstimate(categoryName: string = "クロス張替工事"): CategorizedEstimate {
  return {
    categoryId: crypto.randomUUID(),
    categoryNo: 1,
    categoryName: categoryName,
    fixed: false,
    bizEstimateRows: [getDefaultBizEstimateRow()],
  };
}

/**見積有効期限の初期データ */
function getInitCalendar(): BizEstimateValidityPeriod {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  return { calendar: Timestamp.fromDate(date) };
}

const CategorizedEstimatesContext = createContext<Context>({} as Context);

export const useCategorizedEstimatesContext = (): Context => {
  return useContext(CategorizedEstimatesContext);
};

interface CategorizedEstimatesProviderProps {
  children: ReactNode;
  bizEstimates: BizEstimate[];
  project: Project;
}
export const CategorizedEstimatesProvider = ({
  children,
  bizEstimates,
  project,
}: CategorizedEstimatesProviderProps) => {
  const [taxType, setTaxType] = useState<TaxType>("roundDown"); // 消費税設定
  const [validityPeriod, setValidityPeriod] = useState<BizEstimateValidityPeriod>(getInitCalendar()); // 見積有効期限設定
  const [categorizedEstimates, setCategorizedEstimates] = useState<CategorizedEstimate[]>([
    getDefaultCategorizedEstimate(),
  ]); // カテゴリー毎の見積データ
  const [discountEstimate, setDiscountEstimate] = useState<BizEstimate>(); //お値引き見積データ
  const [isAddOnEnabled, setIsAddOnEnabled] = useState<boolean>(false);

  const { userId } = useStore(BizConstractorStore.IDMap);
  const hasEstimates = bizEstimates.length > 0;

  useEffect(() => {
    const fetchProfitManagementAddOnEnabled = async (): Promise<void> => {
      const result = await isProfitManagementAddOnEnabled(project.companyId);
      setIsAddOnEnabled(result.enabled);
    };

    // 税率を適用
    if (project.bizTaxType) {
      setTaxType(project.bizTaxType);
    }

    // 見積有効期限設定を適用
    if (project.bizEstimateValidityPeriod) {
      setValidityPeriod(project.bizEstimateValidityPeriod);
    }

    // 見積データをuseStateに適用
    if (bizEstimates.length > 0) {
      let categorizedEstimates: CategorizedEstimate[] = [];

      // カテゴリー毎にestimateを振り分ける
      for (let categoryNo = 1; ; categoryNo++) {
        const filteredBizEstimates = bizEstimates.filter((bizEstimate) => bizEstimate.categoryNo === categoryNo);
        if (filteredBizEstimates.length === 0) {
          break;
        }

        const bizEstimateRows = filteredBizEstimates.map((bizEstimate) => {
          let bizEstimateRow: BizEstimateRow = {
            id: crypto.randomUUID(),
            rowNo: bizEstimate.rowNo,
            gentyoImages: bizEstimate.gentyoImages,
            itemName: bizEstimate.itemName,
            quantity: bizEstimate.quantity,
            unit: bizEstimate.unit,
            unitPrice: bizEstimate.unitPrice,
            amount: bizEstimate.amount,
            note: bizEstimate.note,
            ownerComments: bizEstimate.ownerComments,
            ownerFixed: bizEstimate.ownerFixed,
            shareRate: bizEstimate.shareRate,
            referralFeeRate: bizEstimate.referralFeeRate,
          };

          if (bizEstimate.gentyoImages) {
            bizEstimateRow.pendingGentyoImages = bizEstimate.gentyoImages.map((gentyoImage) => ({
              file: undefined,
              fileName: gentyoImage.fileName,
              URL: gentyoImage.URL,
              isRegistered: true,
            }));
          }

          return bizEstimateRow;
        });

        const categorizedEstimate: CategorizedEstimate = {
          categoryId: crypto.randomUUID(),
          categoryNo: filteredBizEstimates[0].categoryNo,
          categoryName: filteredBizEstimates[0].categoryName,
          fixed: bizEstimateRows.every((bizEstimateRow) => bizEstimateRow.ownerFixed === true),
          bizEstimateRows,
        };

        categorizedEstimates = [...categorizedEstimates, categorizedEstimate];
      }
      setCategorizedEstimates(categorizedEstimates);

      // お値引きestimateだけ別表示するため処理を分ける
      const discountEstimate = bizEstimates.find((bizEstimate) => bizEstimate.categoryNo === 9999);

      if (discountEstimate) {
        setDiscountEstimate(discountEstimate);
      }
    }

    fetchProfitManagementAddOnEnabled();
  }, [bizEstimates, project]);

  /**消費税設定の変更(PC版のみ) */
  const changeTaxType = (taxType: TaxType): void => {
    setTaxType(taxType);
  };

  // 項目名の変更
  const changeItemName = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }
        return { ...row, itemName: value };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 数量の変更
  const changeQuantity = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        const quantity = Number(value);
        if (isNaN(quantity)) {
          return { ...row, quantity: undefined, amount: undefined };
        }

        // 金額計算(数量*単価) 小数点以下を切り捨て
        // 精度問題で誤差が出るので少数計算ライブラリを使う
        const amount = Big(quantity)
          .times(row.unitPrice || 0)
          .round(0, Big.roundDown)
          .toNumber();

        return { ...row, quantity, amount };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 単位の変更
  const changeUnit = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }
        return { ...row, unit: value };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 単価の変更
  const changeUnitPrice = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        const unitPrice = Number(value);
        if (isNaN(unitPrice)) {
          return { ...row, unitPrice: undefined, amount: undefined };
        }

        // 金額計算(数量*単価) 小数点以下を切り捨て
        // 精度問題で誤差が出るので少数計算ライブラリを使う
        const amount = Big(row.quantity || 0)
          .times(unitPrice)
          .round(0, Big.roundDown)
          .toNumber();

        return { ...row, unitPrice, amount };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  /**賃貸人 負担率の変更 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const changeLandlordShareRate = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row): BizEstimateRow => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        // 賃貸人 負担率
        const landlordShareRate = Number(value);
        if (isNaN(landlordShareRate)) {
          return { ...row, shareRate: defaultShareRate };
        }

        // 0~100の範囲外の数値
        if (landlordShareRate < 0 || landlordShareRate > 100) {
          return { ...row, shareRate: defaultShareRate };
        }

        return {
          ...row,
          shareRate: {
            landlord: landlordShareRate,
            tenant: new Big(100).minus(landlordShareRate).toNumber(),
          },
        };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  /**賃貸人 負担金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getLandlordAmount = (categoryId: string, rowNo: number): number => {
    const categorizedEstimate = categorizedEstimates.find((estimate) => estimate.categoryId === categoryId)!;
    const bizEstimateRow = categorizedEstimate.bizEstimateRows.find((row) => row.rowNo === rowNo)!;

    const amount = bizEstimateRow.amount;
    if (!amount) {
      return 0;
    }

    // 賃貸人の負担は常に切上げる(基本的に端数が出た場合に賃貸人側が負担するため)
    const landlordShareRatio = Big(bizEstimateRow.shareRate.landlord).div(100);
    return Big(amount).times(landlordShareRatio).round(0, Big.roundUp).toNumber();
  };

  /**賃借人負担金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getTenantAmount = (categoryId: string, rowNo: number): number => {
    const categorizedEstimate = categorizedEstimates.find((estimate) => estimate.categoryId === categoryId)!;
    const bizEstimateRow = categorizedEstimate.bizEstimateRows.find((row) => row.rowNo === rowNo)!;

    const amount = bizEstimateRow.amount;
    if (!amount) {
      return 0;
    }

    return amount - getLandlordAmount(categoryId, rowNo);
  };

  /**紹介手数料率の変更 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const changeReferralFeeRate = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row): BizEstimateRow => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        // 紹介手数料率
        const referralFeeRate = Number(value);
        if (isNaN(referralFeeRate)) {
          return {
            ...row,
            referralFeeRate: 0,
          };
        }

        // 0~100の範囲外の数値
        if (referralFeeRate < 0 || referralFeeRate > 100) {
          return { ...row, referralFeeRate: 0 };
        }

        return {
          ...row,
          referralFeeRate,
        };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  /**紹介手数料金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getReferralFeeAmount = (categoryId: string, rowNo: number): number => {
    const categorizedEstimate = categorizedEstimates.find((estimate) => estimate.categoryId === categoryId)!;
    const bizEstimateRow = categorizedEstimate.bizEstimateRows.find((row) => row.rowNo === rowNo)!;

    const amount = bizEstimateRow.amount;
    if (!amount) {
      return 0;
    }

    const referralFeeRatio = Big(bizEstimateRow.referralFeeRate).div(100);
    return Big(amount).times(referralFeeRatio).round(0, Big.roundDown).toNumber();
  };

  // 備考の変更
  const changeNote = (categoryId: string, rowNo: number, value: string): void => {
    const updatetdCategorizedEstimates = categorizedEstimates.map((estimate) => {
      if (estimate.categoryId !== categoryId) {
        return estimate;
      }

      // 更新後のrowsを作成
      const bizEstimateRows = estimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }
        return { ...row, note: value };
      });

      return {
        ...estimate,
        bizEstimateRows,
      };
    });

    setCategorizedEstimates(updatetdCategorizedEstimates);
  };

  // 行の削除
  const deleteRow = (categoryId: string, rowNo: number): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      // 行を削除
      const deletedRows = categorizedEstimate.bizEstimateRows.filter(
        (bizEstimateRow) => bizEstimateRow.rowNo !== rowNo
      );

      // indexの振り直し
      const updatedRows = deletedRows.map((bizEstimateRow, index) => ({
        ...bizEstimateRow,
        rowNo: index + 1,
      }));

      return {
        ...categorizedEstimate,
        bizEstimateRows: updatedRows,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 行の追加
  const addRow = (categoryId: string): void => {
    const addedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      if (categorizedEstimate.bizEstimateRows.length === 0) {
        categorizedEstimate.bizEstimateRows = [getDefaultBizEstimateRow()];
        return categorizedEstimate;
      }

      const maxRowNo = Math.max(...categorizedEstimate.bizEstimateRows.map((row) => row.rowNo));

      categorizedEstimate.bizEstimateRows = [
        ...categorizedEstimate.bizEstimateRows,
        getDefaultBizEstimateRow(maxRowNo + 1),
      ];

      return categorizedEstimate;
    });

    setCategorizedEstimates(addedCategorizedEstimates);
  };

  // 行の入れ替え(PC版のみ)
  const changeRow = (categoryId: string, changedBizEstimateRows: BizEstimateRow[]): void => {
    // rowNoの書き換え
    changedBizEstimateRows.forEach((changedBizEstimateRow, index) => {
      changedBizEstimateRow.rowNo = index + 1;
    });

    // 行の入れ替え
    const changedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      categorizedEstimate.bizEstimateRows = changedBizEstimateRows;
      return categorizedEstimate;
    });

    setCategorizedEstimates(changedCategorizedEstimates);
  };

  // 現調写真の追加(pending写真)
  const addGentyoImages = (categoryId: string, rowNo: number, pendingGentyoImages: PendingGentyoImage[]): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      const updatedRows = categorizedEstimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        const updatedGentyoImages = row.pendingGentyoImages
          ? [...row.pendingGentyoImages, ...pendingGentyoImages]
          : [...pendingGentyoImages];

        return {
          ...row,
          pendingGentyoImages: updatedGentyoImages,
        };
      });

      return {
        ...categorizedEstimate,
        bizEstimateRows: updatedRows,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 現調写真の削除(pending写真)
  const deleteGentyoImage = (categoryId: string, rowNo: number, URL: string): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      const updatedRows = categorizedEstimate.bizEstimateRows.map((row) => {
        if (row.rowNo !== rowNo) {
          return row;
        }

        const updatedGentyoImages = row.pendingGentyoImages?.filter((iamge) => iamge.URL !== URL);

        return {
          ...row,
          pendingGentyoImages: updatedGentyoImages,
        };
      });

      return {
        ...categorizedEstimate,
        bizEstimateRows: updatedRows,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 現調写真の1枚目のURL(pending写真)
  const getFirstGentyoImageURL = (row: BizEstimateRow): string | undefined => {
    return row.pendingGentyoImages?.[0]?.URL;
  };

  // 現調写真の枚数(pending写真)
  const getGentyoImageCount = (row: BizEstimateRow): number => {
    if (!row.pendingGentyoImages) {
      return 0;
    }

    return row.pendingGentyoImages.length;
  };

  // 現調写真(pending写真)
  const getGentyoImages = (row: BizEstimateRow): PendingGentyoImage[] => {
    return row.pendingGentyoImages || [];
  };

  // カテゴリー(大項目)の追加
  const addCategory = (categoryName: string): void => {
    if (categorizedEstimates.length === 0) {
      setCategorizedEstimates([getDefaultCategorizedEstimate(categoryName)]);
      return;
    }

    const maxCategoryNo = Math.max(
      ...categorizedEstimates.map((categorizedEstimate) => categorizedEstimate.categoryNo)
    );

    setCategorizedEstimates([
      ...categorizedEstimates,
      {
        ...getDefaultCategorizedEstimate(categoryName),
        categoryNo: maxCategoryNo + 1,
      },
    ]);
  };

  // カテゴリー(大項目)の削除
  const deleteCategory = (categoryId: string): void => {
    const deletedCategorizedEstimates = categorizedEstimates.filter(
      (categorizedEstimate) => categorizedEstimate.categoryId !== categoryId
    );

    const incrementedCategorizedEstimates = deletedCategorizedEstimates.map((categorizedEstimate, index) => {
      categorizedEstimate.categoryNo = index + 1;
      return categorizedEstimate;
    });

    setCategorizedEstimates(incrementedCategorizedEstimates);
  };

  // カテゴリー名(大項目)の変更
  const setCategoryName = (categoryName: string, categoryId: string): void => {
    const updatedCategorizedEstimates = categorizedEstimates.map((categorizedEstimate) => {
      if (categorizedEstimate.categoryId !== categoryId) {
        return categorizedEstimate;
      }

      return {
        ...categorizedEstimate,
        categoryName,
      };
    });

    setCategorizedEstimates(updatedCategorizedEstimates);
  };

  // 小計
  const getSubTotal = (): number => {
    let subTotal = 0;

    categorizedEstimates.forEach((categorizedEstimate) => {
      categorizedEstimate.bizEstimateRows.forEach((bizEstimateRow) => {
        subTotal += bizEstimateRow.amount || 0;
      });
    });

    if (discountEstimate) {
      subTotal += discountEstimate.amount!;
    }

    return subTotal;
  };

  // 消費税
  const getTax = (): number => {
    if (taxType === "zero") {
      return 0;
    }

    const subTotal = getSubTotal();
    const tax = Big(subTotal).times(TAX_RATE);

    const roundingMode = taxType === "roundUp" ? Big.roundUp : Big.roundDown;
    return tax.round(0, roundingMode).toNumber();
  };

  // 合計金額(小計 + 消費税)
  const getTotal = (): number => {
    return getSubTotal() + getTax();
  };

  /**賃貸人 負担合計金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getLandlordTotal = (): number => {
    // 1. 賃貸人負担金額の小計を算出
    let landlordSubTotal = getDiscountLandlordAmount();
    for (const categorizedEstimate of categorizedEstimates) {
      for (const bizEstimateRow of categorizedEstimate.bizEstimateRows) {
        landlordSubTotal += getLandlordAmount(categorizedEstimate.categoryId, bizEstimateRow.rowNo);
      }
    }

    if (taxType === "zero") {
      return landlordSubTotal;
    }

    // 2. 1.に消費税を加える
    // 賃貸人の消費税は常に切上げる(基本的に端数が出た場合に賃貸人側が負担するため)
    const landlordTax = Big(landlordSubTotal).times(TAX_RATE).round(0, Big.roundUp).toNumber();
    const tax = getTax();

    // 賃貸人の小計 = 全体の小計の場合、賃貸人側の消費税を強制的に切り上げにする為、全体の消費税を上回る可能性がある。
    // その場合、全体の消費税を返す
    if (landlordTax > tax) {
      return landlordSubTotal + tax;
    }

    return landlordSubTotal + landlordTax;
  };

  /**賃借人負担合計金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getTenantTotal = (): number => {
    return getTotal() - getLandlordTotal();
  };

  /**紹介手数料合計金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getReferralFeeTotal = (): number => {
    let total = 0;
    for (const categorizedEstimate of categorizedEstimates) {
      for (const bizEstimateRow of categorizedEstimate.bizEstimateRows) {
        total += getReferralFeeAmount(categorizedEstimate.categoryId, bizEstimateRow.rowNo);
      }
    }

    return total;
  };

  // お値引きのカテゴリー（追加/更新)
  const editDiscountCategory = (discountAmoount: number): void => {
    if (!discountEstimate) {
      const defaultDiscountEstimate = getDefaultDiscountEstimate(discountAmoount);
      setDiscountEstimate(defaultDiscountEstimate);
      return;
    }

    const newDiscountAmount = discountEstimate.unitPrice + discountAmoount;

    setDiscountEstimate({
      ...discountEstimate,
      unitPrice: newDiscountAmount,
      amount: newDiscountAmount,
    });
  };

  // お値引きのカテゴリーの削除
  const deleteDiscountCategory = (): void => {
    setDiscountEstimate(undefined);
  };

  /**お値引きの賃貸人負担割合変更 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const changeDiscountLandlordShareRate = (value: string): void => {
    if (!discountEstimate) {
      return;
    }

    const landlordShareRate = Number(value);
    if (isNaN(landlordShareRate)) {
      setDiscountEstimate({
        ...discountEstimate,
        shareRate: defaultShareRate,
      });
      return;
    }

    // 0~100の範囲外の数値
    if (landlordShareRate < 0 || landlordShareRate > 100) {
      setDiscountEstimate({
        ...discountEstimate,
        shareRate: defaultShareRate,
      });
      return;
    }

    setDiscountEstimate({
      ...discountEstimate,
      shareRate: {
        landlord: landlordShareRate,
        tenant: new Big(100).minus(landlordShareRate).toNumber(),
      },
    });
  };

  /**お値引きの賃貸人負担金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getDiscountLandlordAmount = (): number => {
    if (!discountEstimate) {
      return 0;
    }

    const amount = discountEstimate.amount;

    // 賃貸人のお値引きは端数は常に切捨てる(基本的に値引きに端数が出た場合に賃借人側を多く値引きするため)
    const landlordShareRatio = Big(discountEstimate.shareRate.landlord).div(100);
    return Big(amount).times(landlordShareRatio).round(0, Big.roundDown).toNumber();
  };

  /**お値引きの賃借人負担金額 (PCかつ利益・負担割合管理アドオンが有効な時のみ) */
  const getDiscountTenantAmount = (): number => {
    if (!discountEstimate) {
      return 0;
    }

    return discountEstimate.amount - getDiscountLandlordAmount();
  };

  // 対象のカテゴリーで1つでも施主承認があるか
  const existsOwnerFixedByCategoryId = (categoryId: string): boolean => {
    const categorizedEstimate = categorizedEstimates.find((estimate) => estimate.categoryId === categoryId);

    if (!categorizedEstimate) {
      return false;
    }

    for (const bizEstimateRow of categorizedEstimate.bizEstimateRows) {
      if (bizEstimateRow.ownerFixed) {
        return true;
      }
    }

    return false;
  };

  // 見積有効期限のラジオボタン切り替え
  const validityPeriodChangeRadio = (validityPeriodType: ValidityPeriodType): void => {
    if (validityPeriodType === "calendar") {
      project.bizEstimateValidityPeriod?.calendar
        ? setValidityPeriod(project.bizEstimateValidityPeriod)
        : setValidityPeriod(getInitCalendar());
    }

    if (validityPeriodType === "days") {
      project.bizEstimateValidityPeriod?.days
        ? setValidityPeriod(project.bizEstimateValidityPeriod)
        : setValidityPeriod({ days: undefined });
    }
  };

  // 有効期限カレンダー日付適用
  const validityPeriodSetCalendar = (calendar: FireTimestamp) => {
    setValidityPeriod({ calendar });
  };

  // 有効期限日数適用
  const validityPeriodSetDays = (days: number): void => {
    setValidityPeriod({ days });
  };

  // 見積もりの保存
  const save = async (): Promise<void> => {
    const companyId = project.companyId;
    const projectId = project.id!;

    const processGentyoImages = async (bizEstimateRow: BizEstimateRow): Promise<GentyoImage[]> => {
      const gentyoImages: GentyoImage[] = [];
      if (!bizEstimateRow.pendingGentyoImages) {
        return gentyoImages;
      }

      const tasks = bizEstimateRow.pendingGentyoImages.map(async (pendingGentyoImage) => {
        let URL = pendingGentyoImage.URL;
        // firestoreにまだ登録されていない写真
        if (!pendingGentyoImage.isRegistered) {
          URL = await uploadGentyoImageToStorage(
            companyId,
            projectId,
            pendingGentyoImage.file!,
            pendingGentyoImage.fileName
          );
        }

        const gentyoImage: GentyoImage = {
          fileName: pendingGentyoImage.fileName,
          URL,
        };

        return gentyoImage;
      });

      const results = await Promise.all(tasks);
      return results.filter((result) => result !== null) as GentyoImage[];
    };

    const promises: Promise<BizEstimate>[] = [];
    for (const categorizedEstimate of categorizedEstimates) {
      for (const bizEstimateRow of categorizedEstimate.bizEstimateRows) {
        const promise = processGentyoImages(bizEstimateRow).then((gentyoImages) => {
          const bizEstimate: BizEstimate = {
            categoryNo: categorizedEstimate.categoryNo,
            categoryName: categorizedEstimate.categoryName,
            jobTypeId: categorizedEstimate.jobTypeId || "",
            rowNo: bizEstimateRow.rowNo,
            gentyoImages,
            itemName: bizEstimateRow.itemName || "",
            quantity: bizEstimateRow.quantity || 0,
            unit: bizEstimateRow.unit || "",
            unitPrice: bizEstimateRow.unitPrice || 0,
            amount: bizEstimateRow.amount || 0,
            ownerComments: bizEstimateRow.ownerComments || [],
            ownerFixed: bizEstimateRow.ownerFixed || false,
            shareRate: bizEstimateRow.shareRate,
            referralFeeRate: bizEstimateRow.referralFeeRate,
          };

          if (bizEstimateRow.note) {
            bizEstimate.note = bizEstimateRow.note;
          }

          return bizEstimate;
        });

        promises.push(promise);
      }
    }

    const bizEstimates: BizEstimate[] = await Promise.all(promises);

    if (discountEstimate) {
      bizEstimates.push(discountEstimate);
    }

    // bizEstimate,project保存
    await saveBizEstimates({
      bizEstimates,
      taxType,
      validityPeriod,
      companyId,
      projectId,
      bizUserId: userId,
    });

    // もしステータスが提案中か工事中の場合、施主に見積が変更された旨のメールを送信
    updateBizEstimatesNotification(companyId, projectId);

    createBizInvoiceExcel(companyId, projectId);
    createBizEstimateExcel(companyId, projectId);
  };

  return (
    <CategorizedEstimatesContext.Provider
      value={{
        categorizedEstimates,
        discountEstimate,
        validityPeriod,
        taxType,
        project,
        hasEstimates,
        isAddOnEnabled,

        changeTaxType,

        changeItemName,
        changeQuantity,
        changeUnit,
        changeUnitPrice,
        changeNote,

        deleteRow,
        addRow,
        changeRow,

        addGentyoImages,
        getFirstGentyoImageURL,
        getGentyoImageCount,
        getGentyoImages,
        deleteGentyoImage,

        addCategory,
        deleteCategory,
        setCategoryName,

        getSubTotal,
        getTax,
        getTotal,
        getLandlordTotal,
        getTenantTotal,

        editDiscountCategory,
        deleteDiscountCategory,

        existsOwnerFixedByCategoryId,

        validityPeriodChangeRadio,
        validityPeriodSetCalendar,
        validityPeriodSetDays,

        changeLandlordShareRate,
        getLandlordAmount,
        getTenantAmount,
        changeReferralFeeRate,
        getReferralFeeAmount,
        changeDiscountLandlordShareRate,
        getDiscountLandlordAmount,
        getDiscountTenantAmount,
        getReferralFeeTotal,

        save,
      }}
    >
      {children}
    </CategorizedEstimatesContext.Provider>
  );
};
