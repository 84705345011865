import React, { createContext, useContext, useEffect, useState } from "react";

import {
  onAuthStateChanged,
  sendEmailVerification,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
  User,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { bizAuth } from "../lib/firebase";
import { ownerAuth } from "../lib/firebase";
import { BizConstractorStore, BizUserStore, initAllStore, OwnerIdTokenStore } from "../store/nanostores/contractorInfo";
import { getCustomeToken } from "features/auth/api/getCustomeToken";
import { fetchBizUserByBizContractorIdBizUserId } from "repositories/owner/BizUser";

export type LoginInfo = {
  email: string;
  password: string;
};

type AuthContextType = {
  currentUser: User | null;
  authChecked: boolean;
  ownerLoginChecked: boolean;
  login: (loginInfo: LoginInfo) => Promise<string | undefined>;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

const setNanostores = async (user: User): Promise<void> => {
  const idTokenResult = await user.getIdTokenResult(true);
  const bizContractorId = idTokenResult.claims.bizContractorId || "";
  const userId = idTokenResult.claims.userId || "";
  const bizUser = await fetchBizUserByBizContractorIdBizUserId(bizContractorId, userId);

  BizConstractorStore.setContractorInfo({ bizContractorId, userId });
  BizUserStore.setBizUser(bizUser);
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [ownerLoginChecked, setOwnerLoginChecked] = useState<boolean>(true);

  useEffect(() => {
    const ownerUnsubscribe = onAuthStateChanged(ownerAuth, (user: User | null) => {
      if (user) {
        user.getIdToken().then((idToken: string) => {
          OwnerIdTokenStore.set(idToken);
        });
      }
    });

    const bizUnsubscribe = onAuthStateChanged(bizAuth, (user: User | null) => {
      if (user?.emailVerified) {
        setCurrentUser(user);

        if (ownerLoginChecked) {
          (async () => {
            try {
              await setNanostores(user);
            } catch {
              await logout();
            }
            setAuthChecked(true);
          })();
        }
      } else {
        setCurrentUser(null);
        initAllStore();
        setAuthChecked(true);
      }
    });

    return () => {
      ownerUnsubscribe();
      bizUnsubscribe();
    };
  }, [ownerLoginChecked]);

  const login = async (loginInfo: LoginInfo): Promise<string | undefined> => {
    setOwnerLoginChecked(false);

    try {
      // Bizログイン
      const { user } = await signInWithEmailAndPassword(bizAuth, loginInfo.email, loginInfo.password);

      if (!user.emailVerified) {
        await sendEmailVerification(user);
        await logout();
        return "本人確認が完了しておりません。\nメール再送信しましたのでURLをクリックして有効にしてください。";
      }

      // Ownerログイン
      const idTokenResult = await user.getIdTokenResult();
      const idToken = idTokenResult.token;
      const bizContractorId = idTokenResult.claims.bizContractorId as string;

      const ownerCustomToken = await getCustomeToken({
        idToken,
        bizContractorId,
      });

      await signInWithCustomToken(ownerAuth, ownerCustomToken);
    } catch (e) {
      console.log(e);
      let errorMessage = "ログインに失敗しました。";
      if (e instanceof FirebaseError) {
        if (["auth/wrong-password", "auth/user-not-found"].includes(e.code)) {
          errorMessage = "メールアドレスまたはパスワードが違います";
        }
      }

      await logout();
      return errorMessage;
    } finally {
      setOwnerLoginChecked(true);
    }
  };

  const logout = async (): Promise<void> => {
    await signOut(bizAuth);
    await signOut(ownerAuth);
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        authChecked,
        ownerLoginChecked,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
