import { Box, Divider } from "@mui/material";
import { Project } from "@shared/types/entities/owner/Project";

interface Props {
  project: Project;
}

export const ProjectHeader = ({ project }: Props): JSX.Element => {
  return (
    <>
      <Box className="p-2">
        <Box className="flex flex-col justify-center">
          <Box className="flex items-center sm:mb-2">
            <Box className="text-lg sm:text-2xl font-bold">{`${project.projectName} / ${project.roomNo}`}</Box>
          </Box>

          <Box className="text-sm">{`${project.state}${project.address}`}</Box>
        </Box>
      </Box>

      <Divider light />
    </>
  );
};
