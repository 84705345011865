import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

interface AddNewRowProps {
  categoryId: string;
}
export const AddNewRow = ({ categoryId }: AddNewRowProps): JSX.Element => {
  const { addRow, existsOwnerFixedByCategoryId } = useCategorizedEstimatesContext();

  // 一つでも施主fixしていたら表示させない
  if (existsOwnerFixedByCategoryId(categoryId)) {
    return <></>;
  }

  return (
    <Box className="flex justify-end">
      <Box className="flex items-center cursor-pointer" onClick={() => addRow(categoryId)}>
        <AddIcon className="text-3xl" />
        <p className="font-bold text-sm">新しい行を追加する</p>
      </Box>
    </Box>
  );
};
