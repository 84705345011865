import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { UpdateProjectByFinalInspection } from "@shared/types/functionParams";

export async function updateProjectByFinalInspection(
  requestParams: UpdateProjectByFinalInspection.RequestParams
): Promise<void> {
  const func = httpsCallable(bizFunctions, "onCallUpdateProjectByFinalInspection");
  await func(requestParams);
}
