import { useParams } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { CategorizedEstimatesProvider } from "../context/categorizedEstimatesContext";

import { useProject } from "../hooks/useProject";
import { useEstimates } from "../hooks/useEstimates";
import { EstimateEditorPC } from "../components/PC";
import { EstimateEditorSP } from "../components/SP";

export const Estimates = (): JSX.Element => {
  const { companyId, projectId } = useParams();
  const project = useProject(companyId!, projectId!);
  const bizEstimates = useEstimates(companyId!, projectId!);

  const isPC = useMediaQuery("(min-width:640px)");

  if (!project) {
    return <></>;
  }

  return (
    <>
      <CategorizedEstimatesProvider bizEstimates={bizEstimates} project={project}>
        {isPC ? <EstimateEditorPC /> : <EstimateEditorSP />}
      </CategorizedEstimatesProvider>
    </>
  );
};
