import { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Project } from "@shared/types/entities/owner/Project";
import { getFormattedDateTime } from "utils";
import { getReviews } from "repositories/owner/Review";
import { Evaluation } from "@shared/types/entities/owner/Review";
import { fetchUserByCompanyIdUserId } from "repositories/owner/User";
import { FireTimestamp } from "utils/firebaseUtil";

type ReviewsHistoryProps = {
  project: Project;
};

type CustomReview = {
  createdAt: FireTimestamp;
  userName: string;
  comment: string;
  evaluation: Evaluation;
};

export const ReviewsHistory = ({ project }: ReviewsHistoryProps): JSX.Element => {
  const [customReviews, setCustomReviews] = useState<CustomReview[]>();

  useEffect(() => {
    let isCancelled = false;

    const fetchReviews = async () => {
      const reviews = await getReviews(project.companyId, project.id!);
      if (reviews.length === 0) {
        return;
      }

      let customReviews: CustomReview[] = [];
      for (const review of reviews) {
        const user = await fetchUserByCompanyIdUserId(review.companyId, review.createdUid!);
        customReviews = [
          ...customReviews,
          {
            createdAt: review.createdAt!,
            comment: review.comment,
            evaluation: review.evaluation,
            userName: `${user.staffLastName} ${user.staffFirstName}`,
          },
        ];
      }

      if (isCancelled) return;

      setCustomReviews(customReviews);
    };

    fetchReviews();

    return () => {
      isCancelled = true;
    };
  }, [project]);

  if (!customReviews?.length) {
    return <></>;
  }

  return (
    <>
      <Paper className="p-2 sm:p-4">
        <Box className="font-bold">点検履歴</Box>
        {customReviews?.map((customReview, index) => {
          return (
            <div key={index} className="py-2">
              <div className="flex items-center">
                <CircleOutlinedIcon fontSize="small" className="text-lightGray" />
                <div className="font-bold text-sm">{customReview.userName}</div>
                <div className="font-bold text-mediumGray text-xs ml-2">
                  {getFormattedDateTime(customReview.createdAt)}
                </div>
              </div>

              <Box className="mx-2">
                <div className="text-sm py-1">施工の仕上がり：{customReview.evaluation}</div>

                {customReview.comment ? (
                  <div className="text-sm py-1">
                    コメント
                    <div className="p-2 whitespace-pre-line rounded-lg border border-mediumGray">
                      {customReview.comment}
                    </div>
                  </div>
                ) : undefined}
              </Box>
            </div>
          );
        })}
      </Paper>
    </>
  );
};
