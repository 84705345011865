import { collection, onSnapshot, orderBy, query, QuerySnapshot, Unsubscribe } from "firebase/firestore";
import { ownerDB } from "lib/firebase";

export function getOnSnapshotBizEstimates(
  companyId: string,
  projectId: string,
  callback: (querySnapshot: QuerySnapshot) => void
): Unsubscribe {
  const q = query(
    collection(ownerDB, `companies/${companyId}/projects/${projectId}/bizEstimates`),
    orderBy("categoryNo", "asc"),
    orderBy("rowNo", "asc")
  );

  return onSnapshot(q, (querySnapshot) => {
    callback(querySnapshot);
  });
}
