import { ChangeEvent } from "react";

import { Box, Divider, MenuItem, TextField } from "@mui/material";
import { TaxType } from "@shared/types/entities/owner/Project";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

/**合計, 小計, 消費税 */
export const TotalAmount = (): JSX.Element => {
  const {
    isAddOnEnabled,
    taxType,
    changeTaxType,
    getTotal,
    getSubTotal,
    getTax,
    getLandlordTotal,
    getTenantTotal,
    getReferralFeeTotal,
  } = useCategorizedEstimatesContext();

  const handleChangeTaxType = (event: ChangeEvent<HTMLInputElement>) => {
    changeTaxType(event.target.value as TaxType);
  };

  return (
    <Box>
      <Box className="flex items-center">
        <Box className="text-2xl text-pink font-bold pr-1">{`¥${getTotal().toLocaleString()}`}</Box>
        <Box className="text-sm">
          (税抜: ¥{getSubTotal().toLocaleString()} / 消費税: ¥{getTax().toLocaleString()})
        </Box>
        <TextField select size="small" value={taxType} className="w-44 mx-2" onChange={handleChangeTaxType}>
          <MenuItem value={"roundDown"}>消費税10%切捨て</MenuItem>
          <MenuItem value={"roundUp"}>消費税10%切上げ</MenuItem>
          <MenuItem value={"zero"}>消費税0%</MenuItem>
        </TextField>
      </Box>
      {isAddOnEnabled && (
        <Box className="flex ml-2">
          <Box>
            <Box className="flex items-center text-sm">
              <Box className="mr-2">賃貸人負担:</Box>
              <Box className="text-base text-pink font-bold">{`¥${getLandlordTotal().toLocaleString()}`}</Box>
            </Box>
            <Box className="flex items-center text-sm">
              <Box className="mr-2">賃借人負担:</Box>
              <Box className="text-base text-pink font-bold">{`¥${getTenantTotal().toLocaleString()}`}</Box>
            </Box>
          </Box>

          <Divider orientation="vertical" flexItem className="mx-2" />

          <Box>
            <Box className="flex items-center text-sm">
              <Box className="mr-2">紹介手数料:</Box>
              <Box>{`¥${getReferralFeeTotal().toLocaleString()}`}</Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
