import { doc, getDoc } from "firebase/firestore";
import { BizContractor } from "@shared/types/entities/owner/BizContractor";
import { ownerDB } from "lib/firebase";
import { toEntityFromDoc } from "utils/firebaseUtil";

export async function fetchBizContractorByContractorId(contractorId: string): Promise<BizContractor> {
  const docRef = doc(ownerDB, `bizContractors/${contractorId}`);
  const docSnapshot = await getDoc(docRef);

  return toEntityFromDoc<BizContractor>(docSnapshot);
}
