import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const GCP_REGION = "asia-northeast1";

const ownerConfig = {
  apiKey: process.env.REACT_APP_OWNER_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_OWNER_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_OWNER_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_OWNER_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_OWNER_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_OWNER_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_OWNER_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_OWNER_FIREBASE_MEASUREMENT_ID,
};

const bizConfig = {
  apiKey: process.env.REACT_APP_BIZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_BIZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_BIZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BIZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_BIZ_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_BIZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_BIZ_FIREBASE_MEASUREMENT_ID,
};

const ownerApp = initializeApp(ownerConfig, "OWNER");
export const ownerAuth = getAuth(ownerApp);
export const ownerDB = getFirestore(ownerApp);
export const ownerStorage = getStorage(ownerApp);
export const ownerFunctions = getFunctions(ownerApp, GCP_REGION);

const bizApp = initializeApp(bizConfig, "BIZ");
export const bizAuth = getAuth(bizApp);
export const bizFunctions = getFunctions(bizApp, GCP_REGION);
