import { Box, Button, Paper } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export const NoEstimateContent = (): JSX.Element => {
  const { companyId, projectId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Paper className="p-4">
        <Box className="font-bold">見積内容</Box>

        <Box className="flex flex-col justify-center items-center h-48 border border-mediumGray bg-lightGray rounded-lg">
          <Box className="text-sm mb-2">見積はまだ作成されていません。</Box>

          <Button
            onClick={() => {
              navigate(`/companies/${companyId}/projects/${projectId}/estimates`);
            }}
            variant="contained"
            className="bg-remodela-green rounded-xl font-bold"
          >
            見積を作成する
          </Button>
        </Box>
      </Paper>
    </>
  );
};
