import { useState } from "react";
import { Button } from "@mui/material";
import { AddCategoryDialog } from "./dialogs/AddCategoryDialog";

/**「大項目を追加」ボタン */
export const AddCategoryButton = (): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        variant="contained"
        className="w-full my-2 bg-remodela-green"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        大項目を追加
      </Button>

      <AddCategoryDialog
        open={isDialogOpen}
        handleDialogClose={() => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
};
