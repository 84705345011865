import { Box, Button, Paper, TextField } from "@mui/material";
import { type LoginInfo, useAuth } from "context/authContext";
import { useSnackbar } from "context/snackbarContext";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PublicLayout } from "components/Layout/PublicLayout";

export const LoginForm = (): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInfo>();

  const handleLogin: SubmitHandler<LoginInfo> = async (data): Promise<void> => {
    const errorMessage = await login(data);

    if (errorMessage) {
      showSnackbar(errorMessage, "error");
    }
  };

  const handleNavigate = {
    toRegisterForm: (): void => {
      navigate("/register");
    },
    toResetPassword: (): void => {
      navigate("/resetpassword");
    },
    toTop: (): void => {
      navigate("/");
    },
  };

  return (
    <PublicLayout title="ログインページ">
      <Box className="h-screen flex flex-col items-center justify-center">
        <Box>
          <div className="w-[200px] h-8 relative cursor-pointer" onClick={handleNavigate.toTop}>
            <img
              src={`${process.env.PUBLIC_URL}/images/remodelaBiz.png`}
              alt="リモデラBiz"
              className="w-full h-full object-cover absolute top-0 left-0"
            />
          </div>
        </Box>

        <Box className="flex flex-col justify-center w-4/5 sm:w-2/5">
          <Paper className="flex flex-col items-center  mt-8 py-10">
            <Box className="flex flex-col items-center pb-10">
              <div className="font-bold text-xl pb-2">ログイン</div>
              <div className="w-10 h-1 bg-remodela-green"></div>
            </Box>

            <form onSubmit={handleSubmit(handleLogin)} className="flex flex-col items-center w-full">
              <div className="flex flex-col items-center w-11/12 sm:w-1/2 mb-4">
                <TextField
                  placeholder="メールアドレス"
                  type="mail"
                  size="small"
                  fullWidth
                  {...register("email", {
                    required: true,
                    pattern: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                  })}
                />
                {errors.email && (
                  <div className="text-xs text-remodela-error">※正しいメールアドレスを入力してください</div>
                )}
              </div>

              <div className="flex flex-col items-center w-11/12 sm:w-1/2 mb-4">
                <TextField
                  placeholder="パスワード"
                  type="password"
                  size="small"
                  fullWidth
                  {...register("password", { required: true })}
                />
                {errors.password && <div className="text-xs text-remodela-error">※入力してください</div>}
              </div>

              <div className="mt-5">
                <Button type="submit" variant="contained" className="px-14 py-3 font-bold bg-remodela-green rounded-md">
                  ログイン
                </Button>
              </div>
            </form>

            <Box className="mt-4">
              <div className="cursor-pointer text-remodela-green font-bold" onClick={handleNavigate.toRegisterForm}>
                新規会員登録
              </div>
            </Box>
          </Paper>
          <div
            className="text-remodela-green pt-2 underline underline-offset-1 cursor-pointer text-sm text-right"
            onClick={handleNavigate.toResetPassword}
          >
            パスワードを忘れた方はこちら
          </div>
        </Box>
      </Box>
    </PublicLayout>
  );
};
