import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Fab } from "@mui/material";

import { ValidityPeriodDialog } from "./dialogs/ValidityPeriodDialog";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { useCategorizedEstimatesContext } from "features/estimates/context/categorizedEstimatesContext";

export const SaveButton = (): JSX.Element => {
  const [isValidityPeriodDialogOpen, setIsValidityPeriodDialogOpen] =
    useState<boolean>(false);
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();
  const { companyId, projectId } = useParams();
  const navigate = useNavigate();

  const { save, project } = useCategorizedEstimatesContext();

  const handleSave = async (): Promise<void> => {
    setBackdrop(true);

    try {
      await save();

      if (project.status === "工事中") {
        showSnackbar(
          `見積変更を${project.companyName}様に報告しました。`,
          "success"
        );
      } else {
        showSnackbar("見積を保存しました。", "success");
      }

      navigate(`/companies/${companyId}/projects/${projectId}`);
    } catch (e) {
      showSnackbar("見積の登録に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <>
      <Fab
        className="fixed bottom-4 right-4 bg-remodela-green text-white"
        onClick={() => {
          setIsValidityPeriodDialogOpen(true);
        }}
      >
        保存
      </Fab>

      <ValidityPeriodDialog
        open={isValidityPeriodDialogOpen}
        handleDialogClose={() => {
          setIsValidityPeriodDialogOpen(false);
        }}
        handleSave={handleSave}
      />
    </>
  );
};
