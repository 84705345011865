import { Box } from "@mui/material";
import { Project } from "@shared/types/entities/owner/Project";

const classNames = new Map<Project["constructionType"], string>([
  ["修繕", "flex justify-center items-center w-14 rounded-lg bg-[#A9E9F6] text-xs sm:text-sm"],
  ["原復", "flex justify-center items-center w-14 rounded-lg bg-[#FFE093] text-xs sm:text-sm"],
]);

interface Props {
  constructionType: Project["constructionType"];
}

export const ConstructionTypeCard = ({ constructionType }: Props): JSX.Element => {
  return <Box className={classNames.get(constructionType)}>{constructionType}</Box>;
};
