import { useState } from "react";
import { Box, IconButton, Link, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { getFormattedDate, getFormattedDateTime } from "utils";
import { Project } from "repositories/owner/Project";
import { FinalInspectionUpdateDialog } from "./FinalInspectionUpdateDialog";

interface FinalInspectionContentProps {
  project: Project;
}
export const FinalInspectionContent = ({ project }: FinalInspectionContentProps): JSX.Element => {
  const [isFinalInspectionUpdateDialogOpen, setIsFinalInspectionUpdateDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Box className="min-w-[50%]">
        <Box className="flex items-center">
          <Box>依頼する</Box>
          <IconButton onClick={() => setIsFinalInspectionUpdateDialogOpen(true)}>
            <EditIcon />
          </IconButton>
        </Box>

        <Box className="mt-2">
          <Box>
            {`立会日時: ${
              project.finalInspection.date ? getFormattedDateTime(project.finalInspection.date) : "未設定"
            }`}
          </Box>
          <Box>
            {`契約終了日: ${
              project.finalInspection.leaseEndDate ? getFormattedDate(project.finalInspection.leaseEndDate) : ""
            }`}
          </Box>
          <Box>{`入居者氏名: ${project.finalInspection.residentName || ""}`}</Box>
          <Box>{`電話番号: ${project.finalInspection.phoneNumber || ""}`}</Box>
        </Box>

        {project.witnessDocuments &&
          project.witnessDocuments.length > 0 &&
          project.witnessDocuments.map((witnessDocument, index) => {
            return (
              <Box key={index} className="mb-1">
                <Tooltip title="ダウンロード" placement="right">
                  <Link href={witnessDocument.url} target="_blank" className="text-remodela-green" variant="body2">
                    {witnessDocument.name}
                  </Link>
                </Tooltip>
              </Box>
            );
          })}
      </Box>

      <FinalInspectionUpdateDialog
        companyId={project.companyId}
        projectId={project.id!}
        finalInspection={project.finalInspection}
        open={isFinalInspectionUpdateDialogOpen}
        onClose={() => setIsFinalInspectionUpdateDialogOpen(false)}
      />
    </>
  );
};
