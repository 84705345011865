import axios from "axios";
import { PMBuilding } from "@shared/types/entities/pm/Building";

export type ResponsePMBuilding = {
  id: string;
  postalCode: string;
  address: PMBuilding["address"];
  companyId: string;
  type: PMBuilding["type"];
  name: string;
  hasElevator: boolean;
  hasAutoLock: boolean;
  howToUnlockAutoLock: string;
};

export type ResponsePMUnit = {
  id: string;
  name: string;
};

export async function getPMBuildings(ownerIdToken: string, companyId: string): Promise<ResponsePMBuilding[]> {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_PM_API_URL,
    headers: {
      Authorization: `Bearer ${ownerIdToken}`,
    },
  });

  const res = await axiosInstance.get(`/biz/companies/${companyId}/buildings`);
  return res.data.buildings;
}

export async function getPMUnits(
  ownerIdToken: string,
  companyId: string,
  buildingId: string
): Promise<ResponsePMUnit[]> {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_PM_API_URL,
    headers: {
      Authorization: `Bearer ${ownerIdToken}`,
    },
  });

  const res = await axiosInstance.get(`/biz/companies/${companyId}/buildings/${buildingId}/units`);
  return res.data.units;
}
