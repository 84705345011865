import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { PublicLayout } from "components/Layout/PublicLayout";

export const InviteExpired = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <PublicLayout title="有効期限切れです">
      <Box className="h-screen flex flex-col items-center justify-center">
        <Box>
          <Box className="w-[200px] h-8 relative">
            <img
              src={`${process.env.PUBLIC_URL}/images/remodelaBiz.png`}
              alt="リモデラBiz"
              className="w-full h-full object-cover absolute top-0 left-0"
            />
          </Box>
        </Box>
        <Box className="flex flex-col items-center max-w-2xl">
          <Box className="text-2xl font-bold py-8 tracking-widest">有効期限切れです</Box>
          <img src={`${process.env.PUBLIC_URL}/images/pageNotFound.png`} alt="ページが見つかりません" />
          <Box className="text-center py-4">招待URLの有効期限が切れています。招待者にご連絡ください。</Box>
          <Button
            variant="contained"
            className="bg-remodela-green font-bold text-xs rounded-lg w-40 h-12 mx-3"
            onClick={() => navigate("/")}
          >
            トップに戻る
          </Button>
        </Box>
      </Box>
    </PublicLayout>
  );
};
