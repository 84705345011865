import { getDownloadURL, ref } from "firebase/storage";
import { ownerStorage } from "lib/firebase";
import { Contract } from "@shared/utils";

export function downloadFile(url: string, fileName: string): void {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  link.remove();
}

/**発注書のダウンロードURL取得 */
export async function fetchOrderDownloadURL(
  companyId: string,
  projectId: string,
  orderFileName: string
): Promise<string | null> {
  try {
    const filePath = Contract.savePathInStorage.getOrderPath(companyId, projectId, orderFileName);
    const storageDownloadURL = await fetchStorageDownloadURL(filePath);
    return await fetchBlobURL(storageDownloadURL);
  } catch (e) {
    return null;
  }
}

/**見積書のダウンロードURL取得 */
export async function fetchEstimateDownloadURL(companyId: string, projectId: string): Promise<string | null> {
  try {
    const filePath = Contract.savePathInStorage.getEstimatePath(companyId, projectId);
    const storageDownloadURL = await fetchStorageDownloadURL(filePath);
    return await fetchBlobURL(storageDownloadURL);
  } catch (e) {
    return null;
  }
}

/**見積書(利益・負担割合管理)のダウンロードURL取得 */
export async function fetchProfitManagementEstimateDownloadURL(
  companyId: string,
  projectId: string
): Promise<string | null> {
  try {
    const filePath = Contract.savePathInStorage.getProfitManagementEstimatePath(companyId, projectId);
    const storageDownloadURL = await fetchStorageDownloadURL(filePath);
    return await fetchBlobURL(storageDownloadURL);
  } catch (e) {
    return null;
  }
}

/**請求書のダウンロードURL取得 */
export async function fetchInvoiceDownloadURL(companyId: string, projectId: string): Promise<string | null> {
  try {
    const filePath = Contract.savePathInStorage.getInvoicePath(companyId, projectId);
    const storageDownloadURL = await fetchStorageDownloadURL(filePath);
    return await fetchBlobURL(storageDownloadURL);
  } catch (e) {
    return null;
  }
}

export async function fetchBlobURL(storageDownloadURL: string): Promise<string> {
  const response = await fetch(storageDownloadURL);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

async function fetchStorageDownloadURL(dirPath: string): Promise<string> {
  const storageRef = ref(ownerStorage, dirPath);
  return await getDownloadURL(storageRef);
}
