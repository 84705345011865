import { Box, Button, Paper } from "@mui/material";
import { useStore } from "@nanostores/react";
import { BizUserStore } from "store/nanostores/contractorInfo";
import { useSnackbar } from "context/snackbarContext";
import { sendPasswordResetEmail } from "firebase/auth";
import { bizAuth } from "lib/firebase";

export const PasswordChange = (): JSX.Element => {
  const { email } = useStore(BizUserStore.bizUser);
  const { showSnackbar } = useSnackbar();

  const handleForgotPassword = async (): Promise<void> => {
    if (!email) {
      return;
    }

    try {
      await sendPasswordResetEmail(bizAuth, email);
      showSnackbar("送信しました。", "success");
    } catch (e) {
      showSnackbar("送信に失敗しました。", "error");
    }
  };

  return (
    <>
      <Paper className="p-4 w-full">
        <Box className="font-bold text-xl mb-4">パスワード変更</Box>

        <div>{email}</div>

        <div className="text-sm py-2">※上記のメールアドレスにパスワード変更URLを送信します。</div>

        <Button
          type="submit"
          variant="contained"
          className="px-14 py-3 font-bold bg-remodela-green rounded-md"
          onClick={handleForgotPassword}
        >
          送信
        </Button>
      </Paper>
    </>
  );
};
