import { httpsCallable } from "firebase/functions";
import { bizFunctions } from "lib/firebase";
import { CreateAPIOwner } from "@shared/types/functionParams";

export async function createSpotWorkerApiOwnerId(
  requestParams: CreateAPIOwner.RequestParams
): Promise<CreateAPIOwner.ResponseParams> {
  const func = httpsCallable(bizFunctions, "onCallCreateSpotWorkerApiOwner");
  const result = await func(requestParams);

  return result.data as CreateAPIOwner.ResponseParams;
}
